import styled, { keyframes } from 'styled-components';
import { system } from 'styled-system';
import type { ResponsiveValue, Theme, ThemeValue } from 'styled-system';

import { Box } from '../../layout';

import { getLighterColorShade } from './utils';

type SpinnerSize = ResponsiveValue<ThemeValue<'space', Theme>>;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerBox = styled(Box)(
  system({
    color: { property: 'borderColor', scale: 'color' },
    size: {
      properties: ['height', 'width'],
      scale: 'space',
      transform: (value, scale) => {
        if (!scale) return value;
        const size = scale[value];
        return `${size}px`;
      },
    },
  })
);

export const Spinner = styled(SpinnerBox).attrs<{
  size?: SpinnerSize;
}>(({ color: $color = 'grey.900', size: $size = '18px' }) => ({
  borderColor: getLighterColorShade($color),
  borderBottomColor: $color,
  height: $size,
  width: $size,
}))`
  animation: ${spin} 0.5s linear infinite;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border-style: solid;
  border-width: 2px;
  display: inline-block;
`;
