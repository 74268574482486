export default function OutlineIssue(props) {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={50} cy={50} r={46} stroke="#FFF1EC" strokeWidth={8} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.981 32c1.923 0 3.482 1.53 3.482 3.418V50c0 1.888-1.559 3.418-3.482 3.418-1.922 0-3.481-1.53-3.481-3.418V35.418C46.5 33.53 48.059 32 49.981 32zM46.5 64.582c0-1.887 1.559-3.417 3.481-3.417h.038c1.922 0 3.481 1.53 3.481 3.417C53.5 66.47 51.941 68 50.019 68h-.038c-1.922 0-3.481-1.53-3.481-3.418z"
        fill="#FF9066"
      />
    </svg>
  );
}
