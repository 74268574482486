export default function OutlineFail(props) {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={50} cy={50} r={46} stroke="#FFDBDE" strokeWidth={8} />
      <path
        d="M38.728 38.728a2.486 2.486 0 013.517 0L50 46.484l7.755-7.756a2.486 2.486 0 113.517 3.517L53.516 50l7.756 7.755a2.486 2.486 0 11-3.517 3.517L50 53.516l-7.755 7.756a2.486 2.486 0 11-3.517-3.517L46.484 50l-7.756-7.755a2.486 2.486 0 010-3.517z"
        fill="#FF4A5A"
      />
    </svg>
  );
}
