import type { InternalAxiosRequestConfig } from 'axios';

import { AccessTokenStorage } from './AccessTokenStorage';

export type OnAuthRequestInterceptorConfig = { useAuthentication: boolean };

export const NAME = 'onAuthRequestInterceptor';

/**
 * Axios interceptor to handle requests that need an access token.
 *
 * It handles adding the access token to the `Authorization` header.
 * * If no access token exists attempt to fetch one before proceeding with the request
 * * If an access token exists adds it to the request headers
 * * If an access token exists that is expired it will attempt to refresh the token
 *   before proceeding with the request
 */
export async function onAuthRequest(config: InternalAxiosRequestConfig) {
  if (
    AccessTokenStorage.accessToken === null ||
    (AccessTokenStorage.accessToken && AccessTokenStorage.isExpired)
  ) {
    await AccessTokenStorage.refresh();
  }
  if (AccessTokenStorage.accessToken) {
    config.headers.Authorization = AccessTokenStorage.accessToken;
  }
  return config;
}
