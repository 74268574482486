import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { isFlagActive } from '@/src/components/FeatureFlag/isFlagActive';
import { useUserContext } from '@/src/components/UserProvider';
import { DASHBOARD_ROUTE, ADMIN_DASHBOARD_ROUTE } from '@/src/constants/routes';
import { isAdmin } from '@/src/domains/registration/auth/helpers';

/**
 *
 * @param {String} name - name of the feature (should be listed in featureFlagNames.js under src/config)
 * @param {String} source - route we want to match the current path
 */
export function useFlagRedirect(name, source) {
  const { user } = useUserContext();
  const [canOpenPage, setCanOpenPage] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const { pathname } = router;

    if (!isFlagActive(name) && pathname === source) {
      const destination = isAdmin(user) ? ADMIN_DASHBOARD_ROUTE : DASHBOARD_ROUTE;
      Router.replace(destination);
    } else {
      setCanOpenPage(true);
    }
  }, [name, source, user, router]);
  return {
    canOpenPage,
  };
}
