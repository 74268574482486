import Cookies from 'js-cookie';
import getConfig from 'next/config';

import type { featureFlags } from '@/next/featureFlagNames';
import { featureFlagNames } from '@/next/featureFlagNames';
import {
  FEATURE_FLAG_COOKIE_PREFIX,
  getFlagCookieName,
} from '@/src/domains/remoteControlPanel/tools/feature-flags/utils';
import { debug } from '@/src/helpers/general';

const { publicRuntimeConfig } = getConfig();

let forcedSSRFlags: Record<string, string> = {};

/**
 *
 * IMPORTANT! do not use this function outside of dev mode, otherwise we might pollute
 * the cookies and serve the wrong content to other users.
 */
export const setForcedSSRFlagsInDevMode = (cookies: Record<string, string> = {}) => {
  if (!cookies) {
    return;
  }

  const filteredCookies = Object.keys(cookies).reduce((acc, key) => {
    if (!key.startsWith(FEATURE_FLAG_COOKIE_PREFIX)) {
      return acc;
    }

    acc = { ...acc, [key]: cookies[key] };
    return acc;
  }, {});

  forcedSSRFlags = filteredCookies;
};

const featuresFlagNamesSet = new Set(featureFlagNames);

type FeatureName = keyof typeof featureFlags;

/**
 * @deprecated Use the new Full-Stack Feature Flag system instead (https://www.notion.so/remotecom/Full-stack-Feature-Flags-4594be20558a4abf8e38f2a59c3b4c27)
 * @param {String} featureName - name of the feature (should be listed in featureFlagNames.js under src/config)
 * @returns {Boolean} wether feature flag is active or not
 */
export function isFlagActive(featureName: FeatureName): boolean {
  if (!featuresFlagNamesSet.has(featureName)) {
    debug(`Warning: Invalid feature flag: ${featureName}.`);
    return false;
  }

  const isServer = typeof window === 'undefined';
  const isDev = !['production', 'sandbox'].includes(publicRuntimeConfig.ENVIRONMENT);

  let cookieValue = null;

  if (isDev && isServer) {
    cookieValue = forcedSSRFlags[getFlagCookieName(featureName)];
  }

  if (isDev && !isServer) {
    cookieValue = Cookies.get(getFlagCookieName(featureName));
  }

  if (cookieValue) {
    return cookieValue === 'true';
  }

  return !!publicRuntimeConfig[featureName];
}
