import type { Socket as SocketType, SocketConnectOption } from 'phoenix';
import type { ReactNode } from 'react';
import React, { createContext, useEffect, useState } from 'react';

type ExceptionType = Error & {
  reason?: string;
  message?: string;
};

type CustomSocketConnectOption = Partial<SocketConnectOption> & {
  longPollFallbackMs?: number | null;
};

export const WebSocketContext = createContext<{
  socket: SocketType | null;
  onError?: (exception: ExceptionType) => void;
}>({ socket: null, onError: undefined });

export const WebSocketProvider = ({
  isUserAuthenticated,
  onError,
  options,
  wsBaseUrl,
  children,
}: {
  isUserAuthenticated: boolean;
  onError?: (exception: ExceptionType) => void;
  wsBaseUrl: string;
  options?: CustomSocketConnectOption;
  children: ReactNode;
}) => {
  const [socket, setSocket] = useState<SocketType | null>(null);

  useEffect(() => {
    async function wsSetup() {
      if (!isUserAuthenticated) {
        return;
      }

      /**
       * prevent creating a new socket connection if one already exists.
       */
      if (!socket) {
        const { Socket } = await import('phoenix');
        // We do not send token in query params for security reasons, for more details, see:
        // https://linear.app/remote/issue/RMT-605/🔐authentication-mechanism-for-websockets#comment-a549f6ce
        const ws = new Socket(`${wsBaseUrl}/socket`, options as CustomSocketConnectOption);
        ws.connect();
        setSocket(ws);
      }

      return () => {
        if (socket) {
          socket.disconnect();
          setSocket(null);
        }
      };
    }
    // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
    wsSetup();
  }, [socket, isUserAuthenticated, wsBaseUrl, options]);

  return (
    <WebSocketContext.Provider value={{ socket, onError }}>{children}</WebSocketContext.Provider>
  );
};
