import { USER_TYPE, employeePersona } from '@/src/domains/registration/auth/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';

const employerDefaultAuthorizations = {
  [Resources.employer.people]: ['navigate', 'create', 'read', 'update', 'delete'],
  [Resources.employer.people_documents]: ['create', 'read', 'update', 'delete'],
  [Resources.employer.people_contracts]: ['navigate', 'read', 'create', 'update', 'delete'],
  [Resources.employer.people_remote_entities]: ['read'],
  [Resources.employer.legal_entities]: ['read', 'create', 'update'],
  [Resources.employer.people_sensitive_details]: ['read'],
  [Resources.employer.hiring]: ['create', 'read', 'update', 'delete'],
  [Resources.employer.company_details]: ['navigate', 'create', 'read', 'update', 'delete'],
  [Resources.employer.payment_requests]: ['create', 'read', 'update', 'delete'],
  [Resources.employer.payroll]: ['read', 'update'],
  [Resources.employer.billing]: ['read', 'update'],
  [Resources.employer.time_off_management]: ['read', 'update'],
  [Resources.employer.time_tracking]: ['read', 'update'],
  [Resources.employer.expense_management]: ['read', 'update'],
  [Resources.employer.incentives_management]: ['read', 'update', 'create', 'delete'],
  [Resources.employer.documents_management]: ['read', 'update', 'create', 'delete'],
  [Resources.employer.custom_field_definitions]: ['read', 'update', 'create', 'delete'],
  [Resources.employer.custom_field_values]: ['read', 'update', 'create'],
  [Resources.employer.tce_calculator]: ['read'],
  [Resources.employer.cost_calculator]: ['read'],
  [Resources.employer.marketplace]: ['read'],
  [Resources.employer.compensation_benchmarking]: ['read'],
  [Resources.employer.integrations]: ['read', 'update', 'create', 'delete'],
  [Resources.employer.company_bank_details]: ['read'],
  [Resources.employer.team_plans]: ['read'],
  [Resources.employer.global_payroll]: ['read'],
  [Resources.employer.anonymous_feedback_management]: ['read'],
  [Resources.employer.kdb_facts]: ['read'],
  [Resources.employer.cost_approval_requests]: ['navigate', 'read', 'update'],
  [Resources.employer.payroll_outputs]: ['read'],
  [Resources.employer.contractor_invoices]: ['read'],
  [Resources.employer.contractor_rate]: ['read'],
  [Resources.employer.billable_contractor_activities]: ['navigate', 'read'],
  [Resources.employer.scheduled_contractor_invoices]: ['navigate', 'read', 'update', 'create'],
  [Resources.employer.people_employment]: ['read'],
  [Resources.employer.contractor_tax_forms]: ['navigate', 'read', 'update'],
  [Resources.benefits]: ['navigate', 'read'],
  [Resources.employer.benefits]: ['navigate', 'read'],
};

const employerAuthorizations = {
  ...employerDefaultAuthorizations,
  [Resources.employer.team_members]: ['read', 'update', 'create', 'delete'],
  [Resources.employer.payment_methods]: ['navigate', 'read', 'update', 'create', 'delete'],
  [Resources.employer.automatic_invoice_payments]: ['create', 'read', 'update', 'delete'],
};

export default {
  [USER_TYPE.EMPLOYER]: employerAuthorizations,
  [USER_TYPE.SERVICE_PROVIDER]: {
    [Resources.people]: ['navigate', 'read'],
    [Resources.payroll]: ['read'],
  },
  // `userRole.EMPLOYEE` breaks down into the following employee
  // roles:
  employeePersona: {
    [employeePersona.EMPLOYEE]: {
      [Resources.personal_information]: ['read'],
      [Resources.employee_personal_details]: ['read', 'update'],
      [Resources.documents]: ['read'],
      [Resources.payslips]: ['read'],
      [Resources.contract_eligibility]: ['read'],
      // Allow transitioned full-time employees to view invoices that they created while
      // being contractors.
      [Resources.invoices]: ['read', 'update', 'delete'],
      [Resources.administrative_details]: ['read', 'update'],
      [Resources.job_pay]: ['read', 'update'],
      [Resources.bank_accounts]: ['read', 'create', 'update', 'delete'],
      [Resources.time_off]: ['create', 'read', 'update', 'delete'],
      [Resources.time_tracking]: ['create', 'read', 'update', 'delete'],
      [Resources.benefits]: ['navigate'],
      [Resources.expenses]: ['create', 'read', 'update', 'delete'],
      [Resources.incentives]: ['read'],
      [Resources.employment_requests]: ['navigate', 'create', 'read'],
      [Resources.health_checks]: ['create', 'read'],
      [Resources.handbooks]: ['read'],
    },
    [employeePersona.CONTRACTOR]: {
      [Resources.administrative_details]: ['read', 'update'],
      [Resources.benefits]: ['navigate'],
      [Resources.contractor_business_information]: ['read', 'update'],
      [Resources.personal_information]: ['read'],
      [Resources.employee_personal_details]: ['read', 'update'],
      [Resources.documents]: ['read'],
      [Resources.time_tracking]: ['create', 'read', 'update', 'delete'],
      // Allow transitioned contractors to view payslips that they received while
      // being full-time employees.
      [Resources.payslips]: ['read'],
      [Resources.invoices]: ['create', 'read', 'update', 'delete'],
      [Resources.recurring_invoices]: ['create', 'read', 'update', 'delete'],
      [Resources.scheduled_invoices]: ['read', 'update'],
      [Resources.bank_accounts]: ['read', 'create', 'update', 'delete'],
      [Resources.time_off]: ['create', 'read', 'update', 'delete'],
      // Currently need for 'Contractor expenses', https://linear.app/remote/project/expenses-for-contractors-d4b6c960d002/overview
      // functionalities will be behind feature flag, comment can be removed once project is completed
      [Resources.expenses]: ['create', 'read', 'update', 'delete'],
    },
    [employeePersona.FREELANCER]: {
      [Resources.contractor_clients]: [
        'read',
        'create',
        // To-do: need to discuss if these permissions are going to be available and what their
        // effects will be. See more: https://linear.app/remote/issue/CM-863
        // 'update',
        // 'delete'
      ],
    },
  },
};
