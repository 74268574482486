export default function FilledSuccess(props) {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={50} cy={50} r={50} fill="#624DE3" fillOpacity={0.8} />
      <circle cx={50} cy={50} r={37} fill="#fff" fillOpacity={0.08} />
      <path
        d="M39.56 47.966a3.37 3.37 0 00-4.606 0 3.001 3.001 0 000 4.39l10.987 10.47L67.48 42.3a3.002 3.002 0 000-4.39 3.37 3.37 0 00-4.606 0L45.942 54.05l-6.383-6.083z"
        fill="#fff"
      />
    </svg>
  );
}
