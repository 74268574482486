import getConfig from 'next/config';

const {
  publicRuntimeConfig: { API_BASE_URL_CLIENT, MARKETING_BASE_URL, REMOTE_JOBS_BASE_URL },
} = getConfig();

// General routes
export const DASHBOARD_ROUTE = '/dashboard';
export const SIGN_IN_ROUTE = `/sign-in`;
export const ACCOUNT_CHOOSER_PAGE = `/account-chooser`;
export const SSO_SIGN_IN_PAGE = `/sso/sign-in`;
export const SSO_REDIRECT_PAGE = `/sign-in/sso-redirect`;
export const SESSION_REDIRECT_NO_AUTH_PAGE = `/sign-in/session-redirect-no-auth`;
export const SIGN_UP_ROUTE = `/sign-up`;
export const ACCOUNT_LOCKED_ROUTE = `/account-locked`;
export const SIGN_UP_STEP_ROUTE = `/sign-up/[registrationFlow]`;
export const RECOVER_PASSWORD_ROUTE = '/recover-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const SETUP_2FA_ROUTE = '/sign-in/setup-2fa';
export const SETUP_2FA_ROUTE_NO_AUTH = '/sign-in/setup-2fa-no-auth';
export const VERIFY_2FA_ROUTE = '/sign-in/verify-2fa';
export const UNLOCK_ACCOUNT_EXPIRED = '/sign-in/unlock-account-expired';
export const ACCOUNT_SETTINGS_ROUTE = `${DASHBOARD_ROUTE}/account-settings`;
export const KNOWLEDGE_ROUTE = '/knowledge';
export const KNOWLEDGE_CREATE_NEW_FACT = `${KNOWLEDGE_ROUTE}/facts/new`;
export const KNOWLEDGE_NEW_FACT_MODEL_EDITOR = `${KNOWLEDGE_CREATE_NEW_FACT}/model-edit`;
export const KNOWLEDGE_FACT_DETAILS_VIEW = `${KNOWLEDGE_ROUTE}/facts/[factId]`;
export const KNOWLEDGE_FACT_EDIT = `${KNOWLEDGE_FACT_DETAILS_VIEW}/edit`;
export const KNOWLEDGE_FACT_DETAILS_VALUES_VIEW = `${KNOWLEDGE_FACT_DETAILS_VIEW}/values`;
export const KNOWLEDGE_FACT_DETAILS_VALUES_NEW_VALUE = `${KNOWLEDGE_FACT_DETAILS_VIEW}/values/new`;
export const KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VIEW = `${KNOWLEDGE_FACT_DETAILS_VALUES_VIEW}/[valueSlug]`;
export const KNOWLEDGE_FACT_DETAILS_FACT_VALUE_TIMELINE_VIEW = `${KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VIEW}/timeline`;
export const KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VIEW_EDIT = `${KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VIEW}/new`;
export const KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VERSION_UPDATE = `${KNOWLEDGE_FACT_DETAILS_FACT_VALUE_INDIVIDUAL_VIEW}/versions/[versionSlug]/edit`;
export const MANAGE_2FA_ROUTE = `${DASHBOARD_ROUTE}/manage-2fa`;
export const PERSONAL_PROFILE_ROUTE = `${DASHBOARD_ROUTE}/personal-details`;
export const TAX_COMPLIANCE_ROUTE = `${DASHBOARD_ROUTE}/tax-compliance`;

export const COMPANY_SETTINGS_ROUTE = `${DASHBOARD_ROUTE}/company-settings`;
export const COMPANY_INFORMATION_ROUTE = `${COMPANY_SETTINGS_ROUTE}/company-information`;
export const COMPANY_LEGAL_ENTITIES_ROUTE = `${COMPANY_SETTINGS_ROUTE}/legal-entities`;
export const COMPANY_CUSTOM_FIELDS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/custom-fields`;
export const COMPANY_CUSTOM_LEAVE_POLICIES_ROUTE = `${COMPANY_SETTINGS_ROUTE}/custom-leave-policies`;
export const COMPANY_TIME_TRACKING_ROUTE = `${COMPANY_SETTINGS_ROUTE}/time-tracking`;
export const COMPANY_TEAM_PLANS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/team-plans`;
export const COMPANY_CONTRACTOR_SUBSCRIPTIONS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/contractor-subscriptions`;
export const COMPANY_ACTIVE_PRODUCTS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/active-products`;
export const COMPANY_INACTIVE_PRODUCTS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/inactive-products`;
export const COMPANY_PAYMENTS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/payments`;
export const COMPANY_SSO_ROUTE = `${COMPANY_SETTINGS_ROUTE}/sso`;
export const COMPANY_TALENT_PLANS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/talent-plans`;
export const COMPANY_BIO_ROUTE = `${COMPANY_SETTINGS_ROUTE}/bio`;
export const COMPANY_ADMIN_USERS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/admin-users`;
export const COMPANY_ROLES_PERMISSIONS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/roles-permissions`;
export const LEGAL_ENTITIES_PROFILE_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]`;
export const LEGAL_ENTITIES_ONBOARDING_TASKS_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/onboarding-tasks`;
export const LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/add-signatory`;
export const LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/setup-pay-schedule`;
export const LEGAL_ENTITIES_SETUP_STATE_TAX_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/setup-state-tax-details`;
export const LEGAL_ENTITIES_SIGN_ALL_FORMS_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/sign-all-forms`;
export const LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/upload-documents`;
export const LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/[slug]/employee-upload-documents`;
export const EMPLOYER_CREATE_LEGAL_ENTITY_ROUTE = `${DASHBOARD_ROUTE}/legal-entities/add`;
export const COMPANY_SSO_SETUP_ROUTE = `${DASHBOARD_ROUTE}/company/sso-setup`;
export const COMPANY_SSO_MANAGE_ROUTE = `${DASHBOARD_ROUTE}/company/sso-manage`;
export const COMPANY_COMPLETE_ONBOARDING = `${DASHBOARD_ROUTE}/complete-onboarding`;
export const CUSTOMER_USERS_PERMISSIONS_ROUTE = `${DASHBOARD_ROUTE}/users-permissions`;
export const PAYMENT_METHODS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/payment-methods`;
export const PAYMENT_METHOD_ACH_NEW_ACCOUNT_DETAILS_ROUTE = `${PAYMENT_METHODS_ROUTE}/ach/new/account-details`;
export const PAYMENT_METHOD_CARDS_UPDATE_ROUTE = `${PAYMENT_METHODS_ROUTE}/cards/[slug]/update`;
export const TODOS_ROUTE = `${DASHBOARD_ROUTE}/todos`;
export const DOCUMENTS_ROUTE = `${DASHBOARD_ROUTE}/documents`;
export const DOCUMENTS_1099_NEC_FORMS_ROUTE = `${DASHBOARD_ROUTE}/documents/1099-nec-forms`;
export const DOCUMENTS_FILES_ROUTE = `${DASHBOARD_ROUTE}/documents/files`;
export const DOCUMENTS_TAX_MANAGEMENT_ROUTE = `${DASHBOARD_ROUTE}/documents/tax-management`;
export const DOCUMENTS_EDIT_EMPLOYER_TEMPLATE_ROUTE = `${DASHBOARD_ROUTE}/documents/template/[slug]`;
export const TIME_OFF_ROUTE = `${DASHBOARD_ROUTE}/time-off`;
export const TIME_OFF_REQUESTS_ROUTE = `${DASHBOARD_ROUTE}/time-off/requests`;
export const POLICY_FORM_ROUTE = `${TIME_OFF_ROUTE}/policy`;
export const TIME_TRACKING = `${DASHBOARD_ROUTE}/time-tracking`;
export const PROJECTS_ROUTE = `${DASHBOARD_ROUTE}/projects`;
export const INVOICES_ROUTE = `${DASHBOARD_ROUTE}/invoices`;
export const INVOICES_NEW_ROUTE = `${INVOICES_ROUTE}/new`;
export const INVOICES_UPLOAD_ROUTE = `${INVOICES_ROUTE}/upload`;
export const INVOICES_EDIT_ROUTE = `${INVOICES_ROUTE}/edit/[slug]`;
export const CONTRACTOR_INVOICE_ROUTE = `${INVOICES_ROUTE}/[contractorInvoiceSlug]`;
export const CONTRACTOR_PLAN_CHANGE_ROUTE = `${DASHBOARD_ROUTE}/contractor-plan-change/[employmentSlug]`;
export const PAYMENT_REQUESTS_ROUTE = `${DASHBOARD_ROUTE}/payment-requests`;
export const PAYMENT_REQUESTS_VIEW_INVOICE_ROUTE = `${PAYMENT_REQUESTS_ROUTE}/invoices/[invoiceSlug]`;
export const ADD_CONTRACTOR_PREFUNDING_ROUTE = `${DASHBOARD_ROUTE}/payment-requests/prefunding/add`;
export const PAYMENT_REQUESTS_TRANSACTIONS_ROUTE = `${DASHBOARD_ROUTE}/payment-requests/transactions`;
export const PAYMENT_REQUESTS_SCHEDULES_ROUTE = `${DASHBOARD_ROUTE}/payment-requests/schedules`;
export const SCHEDULED_INVOICES_BULK_PROGRESS = `${PAYMENT_REQUESTS_ROUTE}/scheduled-invoices/bulk/progress/[batchId]`;
export const SCHEDULED_INVOICES_BULK_VALIDATE_CSV = `${PAYMENT_REQUESTS_ROUTE}/scheduled-invoices/bulk/validate-csv`;
export const SCHEDULED_INVOICES_BULK_PREVIEW_RESULTS = `${PAYMENT_REQUESTS_ROUTE}/scheduled-invoices/bulk/preview`;
export const SCHEDULED_INVOICES_BULK_GENERATE_CSV = `${PAYMENT_REQUESTS_ROUTE}/scheduled-invoices/bulk/generate-csv`;
export const WITHDRAWAL_METHODS_ROUTE = `${DASHBOARD_ROUTE}/withdrawal-methods`;
export const DEPOSIT_METHODS_ROUTE = `${DASHBOARD_ROUTE}/deposit-methods`;
export const DEPOSITS_ROUTE = `${DASHBOARD_ROUTE}/deposits`;
export const STRIPE_CONNECT_ONBOARDING = `${DASHBOARD_ROUTE}/withdrawal-methods/stripe-connect/onboarding`;
export const STRIPE_CONNECT_RESET_ONBOARDING = `${DASHBOARD_ROUTE}/withdrawal-methods/stripe-connect/reset-onboarding`;
export const CONTRACTOR_ADD_BANK_ACCOUNT_ROUTE = `${WITHDRAWAL_METHODS_ROUTE}/bank-accounts/new`;
export const CONTRACTOR_EDIT_BANK_ACCOUNT_ROUTE = `${WITHDRAWAL_METHODS_ROUTE}/bank-accounts/[slug]/edit`;
export const CONTRACTOR_ADD_NIUM_RECIPIENT_ACCOUNT_ROUTE = `${WITHDRAWAL_METHODS_ROUTE}/nium/new`;
export const CONTRACTOR_EDIT_NIUM_RECIPIENT_ACCOUNT_ROUTE = `${WITHDRAWAL_METHODS_ROUTE}/nium/[slug]/edit`;
export const EMPLOYEE_ADD_BANK_ACCOUNT_ROUTE = `${DEPOSIT_METHODS_ROUTE}/bank-accounts/new`;
export const EMPLOYEE_EDIT_BANK_ACCOUNT_ROUTE = `${DEPOSIT_METHODS_ROUTE}/bank-accounts/[slug]/edit`;
export const HIRING_ROUTE = `${DASHBOARD_ROUTE}/people/hiring`;
export const PRE_HIRE_RESULTS_ROUTE = `${DASHBOARD_ROUTE}/people/pre-hire/[employmentSlug]`;
export const BILLING_ROUTE = `${DASHBOARD_ROUTE}/billing`;
export const BILLING_INVOICE_DETAILS = `${BILLING_ROUTE}/[slug]`;
export const BILLING_DETAILS_ROUTE = `${BILLING_ROUTE}/outstanding-payments/[slug]`;
export const BILLING_OUTSTANDING_REFUND_DETAILS_ROUTE = `${BILLING_ROUTE}/outstanding-refunds/[slug]`;
export const REMOTE_INVOICE_DETAILS_ROUTE = `${BILLING_DETAILS_ROUTE}/invoice/[invoiceSlug]`;
export const REMOTE_RECEIPT_DETAILS_ROUTE = `${BILLING_DETAILS_ROUTE}/receipt/[receiptSlug]`;
export const BENEFITS_ROUTE = `${DASHBOARD_ROUTE}/benefits`;
export const BENEFITS_SAFETY_WING_ROUTE = `${BENEFITS_ROUTE}/safety-wing`;
export const BENEFITS_ALLIANZ_ROUTE = `${BENEFITS_ROUTE}/allianz`;
export const BENEFITS_KOTA_ROUTE = `${BENEFITS_ROUTE}/kota/[legalEntitySlug]`;
export const BENEFITS_RENEWALS_ROUTE = `${BENEFITS_ROUTE}/renewals`;
export const BENEFITS_KOTA_CONSENT_ROUTE = `${BENEFITS_KOTA_ROUTE}/consent`;
export const BENEFITS_SIMPLY_INSURED_ROUTE = `${BENEFITS_ROUTE}/simply-insured/[legalEntitySlug]`;
export const BENEFITS_SIMPLY_INSURED_EMBED_ROUTE = `${BENEFITS_ROUTE}/simply-insured/[legalEntitySlug]/embed`;
export const BENEFITS_SIMPLY_INSURED_SYNC_ROUTE = `${BENEFITS_ROUTE}/simply-insured/[legalEntitySlug]/sync`;
export const BENEFITS_SIMPLY_INSURED_CONSENT_ROUTE = `${BENEFITS_SIMPLY_INSURED_ROUTE}/consent`;
export const EXPENSES_ROUTE = `${DASHBOARD_ROUTE}/expenses`;
export const INCENTIVES_ROUTE = `${DASHBOARD_ROUTE}/incentives`;
export const PAYSLIPS_ROUTE = `${DASHBOARD_ROUTE}/payslips`;
export const PAYSLIP_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/payslips/[payrollOutputSlug]`;
export const CLIENTS_ROUTE = `${DASHBOARD_ROUTE}/clients`;
export const CONTRACTS_ROUTE = `${DASHBOARD_ROUTE}/contracts`;
export const CONTRACT_ROUTE = `${DASHBOARD_ROUTE}/contracts/[contractDocumentSlug]`;
export const CONTRACT_ROUTE_V2 = `${DASHBOARD_ROUTE}/contracts-viewer/[contractDocumentSlug]`;
export const COMPANY_VERIFICATION_ROUTE = `${DASHBOARD_ROUTE}/company-verification`;
export const SETUP_BILLING_ROUTE = `${DASHBOARD_ROUTE}/billing/contacts`;
export const COMPANY_ONBOARDING_TASKS_ROUTE = `${DASHBOARD_ROUTE}/tasks`;
export const HANDBOOK_ROUTE = `${DASHBOARD_ROUTE}/handbook`;
export const HANDBOOK_DETAIL_ROUTE = `${DASHBOARD_ROUTE}/handbook/[id]`;

// Employer support monitor pages
export const SUPPORT_MONITOR_ROUTE = `${DASHBOARD_ROUTE}/support-monitor`;
export const SUPPORT_MONITOR_TICKET_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/support-monitor/[ticketId]`;

// Regarding the naming, see comment at the corresponding file under "pages".
export const KYB_ONBOARDING_ROUTE = `${DASHBOARD_ROUTE}/remote-payments/[entityType]/[entitySlug]`;
export const PRICING_PLAN_ROUTE = `${DASHBOARD_ROUTE}/company-settings/pricing-plans`;
export const SUBSCRIPTION_INFO_ROUTE = `${DASHBOARD_ROUTE}/company-settings/pricing-plans/[planSlug]`;
export const REPORTS_ROUTE = `${DASHBOARD_ROUTE}/reports`;
export const REPORTS_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/reports/[slug]`;
// Performance / Career Growth
export const DASHBOARD_PERFORMANCE_ROUTE = `${DASHBOARD_ROUTE}/performance`;
export const DASHBOARD_PERFORMANCE_CREATE_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/create-cycle`;
export const DASHBOARD_PERFORMANCE_EDIT_CYCLE_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/cycle/[cycleSlug]/edit`;
export const DASHBOARD_PERFORMANCE_VIEW_CYCLE_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/cycle/[cycleSlug]`;
export const DASHBOARD_PERFORMANCE_VIEW_CALIBRATION_ROUTE = `${DASHBOARD_PERFORMANCE_VIEW_CYCLE_ROUTE}/calibration/[calibrationEventSlug]`;
export const DASHBOARD_PERFORMANCE_VIEW_CALIBRATION_GROUP_ROUTE = `${DASHBOARD_PERFORMANCE_VIEW_CYCLE_ROUTE}/calibration/[calibrationEventSlug]/group/[calibrationGroupSlug]`;
// Manager's Performance pages
export const TEAM_PERFORMANCE_ROUTE = `${DASHBOARD_ROUTE}/team-performance`;
export const TEAM_PERFORMANCE_CYCLE_ROUTE = `${TEAM_PERFORMANCE_ROUTE}/cycle/[cycleSlug]`;
export const TEAM_PERFORMANCE_ASSESSMENT_FORM_ROUTE = `${TEAM_PERFORMANCE_CYCLE_ROUTE}/snapshot/[snapshotSlug]/instance/[snapshotInstanceSlug]/assessment/[assessmentSlug]`;
export const TEAM_PERFORMANCE_CALIBRATION_GROUP_ROUTE = `${TEAM_PERFORMANCE_CYCLE_ROUTE}/calibration/[calibrationEventSlug]/group/[calibrationGroupSlug]`;

// Remote Talent
export const TALENT_BASE_ROUTE = `${DASHBOARD_ROUTE}/talent`;

// Remote Equity
export const REMOTE_EQUITY_ROUTE = `${DASHBOARD_ROUTE}/remote-equity`;

// Company routes
export const TALENT_COMPANY_ROUTE = `${TALENT_BASE_ROUTE}/company`;
export const EDIT_TALENT_COMPANY_PROFILE_ROUTE = `${TALENT_COMPANY_ROUTE}/profile/edit`;
// Job routes
export const JOB_BASE_ROUTE = `${TALENT_BASE_ROUTE}/jobs`;
export const ADD_NEW_JOB_ROUTE = `${JOB_BASE_ROUTE}/new`;
export const EDIT_JOB_ROUTE = `${JOB_BASE_ROUTE}/[jobSlug]/edit`;
export const VIEW_JOB_ROUTE = `${JOB_BASE_ROUTE}/[jobSlug]/view`;
export const JOB_PROSPECTS_ROUTE = `${JOB_BASE_ROUTE}/[jobSlug]/candidates`;
// Checkout
export const JOB_CHECKOUT_ROUTE = `${JOB_BASE_ROUTE}/[jobSlug]/checkout`;
export const TALENT_SUBSCRIPTION_PLANS_ROUTE = `${TALENT_BASE_ROUTE}/subscription/plans`;

export const DASHBOARD_COST_CALCULATOR_ROUTE = `${DASHBOARD_ROUTE}/tce-calculator`;
export const FEEDBACK_ROUTE = `${DASHBOARD_ROUTE}/company-feedback`;
export const FEEDBACK_THREAD_ROUTE = `${FEEDBACK_ROUTE}/[slug]`;
export const COST_APPROVAL_REQUESTS_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/cost-approval-requests/[slug]`;

export const CONFIRM_ANNUAL_PLAN_ROUTE = `${DASHBOARD_ROUTE}/confirm-annual-plan/[planSlug]`;
export const CONFIRM_PRICING_PLAN_ROUTE = `${DASHBOARD_ROUTE}/confirm-pricing-plan/[planSlug]`;
export const CONFIRM_TEAM_PLAN_ROUTE = `${DASHBOARD_ROUTE}/confirm-team-plan/[slug]`;
export const CONFIRM_TIERED_PLAN_ROUTE = `${DASHBOARD_ROUTE}/confirm-tiered-plan/[companyProductTierSlug]`;

export const EMPLOYMENT_REQUESTS_ROUTE = `${DASHBOARD_ROUTE}/employment-requests`;
export const NEW_EMPLOYMENT_REQUEST_ROUTE = `${EMPLOYMENT_REQUESTS_ROUTE}/new/[requestType]/[employmentSlug]`;

export const INTEGRATIONS_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations`;
export const INTEGRATIONS_NEW_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/new`;
export const INTEGRATIONS_NAME_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/[name]`;
export const INTEGRATIONS_QUICKBOOKS_MAPPING_PREFERENCES_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/quickbooks/mapping-preferences`;
export const INTEGRATIONS_QUICKBOOKS_INACTIVE_CONTRACTORS_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/quickbooks/inactive-contractors`;
export const INTEGRATIONS_NETSUITE_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/netsuite`;
export const INTEGRATIONS_NETSUITE_MAPPING_PREFERENCES_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/netsuite/mapping-preferences`;
export const INTEGRATIONS_NETSUITE_SUBSIDIARY_PREFERENCES_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/netsuite/subsidiary-preferences`;
export const INTEGRATIONS_QUICKBOOKS_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/quickbooks`;
export const INTEGRATIONS_XERO_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/xero`;
export const INTEGRATIONS_BAMBOOHR_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/bamboohr`;
export const INTEGRATIONS_HIBOB_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/hibob`;
export const INTEGRATIONS_GREENHOUSE_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/greenhouse`;
export const INTEGRATIONS_WORKDAY_ROUTE = `${DASHBOARD_ROUTE}/company-settings/integrations/workday`;

export const MARKETPLACE_ROUTE = `${DASHBOARD_ROUTE}/marketplace`;

export const PROCESSORS_ROUTE = `${DASHBOARD_ROUTE}/processors`;

export const EMPLOYEE_PAY_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/pay-details/[contractSlug]`;

// Payroll [All employers]
export const PAYROLL_LANDING_ROUTE = `${DASHBOARD_ROUTE}/payroll`;
export const PAYROLL_RUN_DETAILS_ROUTE = `${PAYROLL_LANDING_ROUTE}/[payrollSlug]/details`;
export const PAYROLL_RUN_PAYOUTS_ROUTE = `${PAYROLL_LANDING_ROUTE}/[payrollSlug]/payouts`;

export const PAYROLL_EMPLOYEE_PAYMENTS_ROUTE = `${DASHBOARD_ROUTE}/payroll/employee-payments`;

export const PAYROLL_SETTINGS_ROUTE = `${COMPANY_SETTINGS_ROUTE}/payroll`;

// Employer pay elements
export const EMPLOYER_PAY_ELEMENTS_BASE_ROUTE = `${DASHBOARD_ROUTE}/pay-elements`;
export const EMPLOYER_PAY_ELEMENTS_CREATE_ROUTE = `${EMPLOYER_PAY_ELEMENTS_BASE_ROUTE}/create`;
export const EMPLOYER_PAY_ELEMENTS_CREATE_INLINE_ROUTE = `${EMPLOYER_PAY_ELEMENTS_CREATE_ROUTE}/inline`;
export const EMPLOYER_PAY_ELEMENTS_EDIT_ROUTE = `${EMPLOYER_PAY_ELEMENTS_BASE_ROUTE}/edit/[slug]`;
export const EMPLOYER_PAY_ELEMENTS_BULK_UPLOAD_ROUTE = `${EMPLOYER_PAY_ELEMENTS_BASE_ROUTE}/bulk-upload`;

export const COMPENSATION_BENCHMARKING_ROUTE = `${DASHBOARD_ROUTE}/compensation-benchmarking`;
export const TALENT_INSIGHTS_ROUTE = `${DASHBOARD_ROUTE}/talent-insights`;

// shared
export const FILE_PREVIEW_ROUTE = `${DASHBOARD_ROUTE}/file-preview`;

// User routes
export const TEAM_ROUTE = `${DASHBOARD_ROUTE}/people`;
export const USER_PROFILE_ROUTE = `${TEAM_ROUTE}/[employmentSlug]`;
export const USER_PROFILE_OFFBOARDING_ROUTE = `${TEAM_ROUTE}/[employmentSlug]/offboarding`;
export const USER_PROFILE_RIGHT_TO_WORK_ROUTE = `${TEAM_ROUTE}/[employmentSlug]/right-to-work`;

// add new employee routes
export const ADD_NEW_HIRE_ROUTE = `${DASHBOARD_ROUTE}/people/add`;

export const NEW_EMPLOYMENT_TYPE_ROUTE = `${DASHBOARD_ROUTE}/people/new/[employmentType]`;
export const NEW_BULK_EMPLOYMENT_TYPE_ROUTE = `${NEW_EMPLOYMENT_TYPE_ROUTE}/bulkupload`;
export const NEW_BULK_EMPLOYMENT_PRODUCT_TYPE_ROUTE = `${NEW_BULK_EMPLOYMENT_TYPE_ROUTE}/[productType]`;
export const NEW_EMPLOYMENT_TYPE_GENERATE_CSV_ROUTE = `${DASHBOARD_ROUTE}/people/new/[employmentType]/generate-csv`;
export const NEW_EMPLOYMENT_TYPE_SLUG_ROUTE = `${DASHBOARD_ROUTE}/people/new/[employmentType]/[employmentSlug]`;
export const EDIT_EMPLOYMENT_TYPE_SLUG_ROUTE = `${DASHBOARD_ROUTE}/people/edit/[employmentType]/[employmentSlug]`;
export const NEW_EMPLOYMENT_TYPE_SLUG_ROUTE_BULK = `${DASHBOARD_ROUTE}/people/new/[employmentType]/bulk`;
export const NEW_EMPLOYMENT_TYPE_SLUG_CONTRACT_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/people/new/[employmentType]/[employmentSlug]/contract-details`;
export const PRE_HIRE_EMPLOYMENT_ROUTE = `${DASHBOARD_ROUTE}/people/new/full_time/pre-hire-check`;
export const PRE_HIRE_EMPLOYMENT_SLUG_ROUTE = `${DASHBOARD_ROUTE}/people/new/full_time/pre-hire-check/[employmentSlug]`;
export const PRE_HIRE_EMPLOYMENT_SLUG_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/people/new/full_time/pre-hire-check/[employmentSlug]/details`;

export const LINK_ACCOUNTS_ROUTE = `${DASHBOARD_ROUTE}/people/link-accounts`;

export const ADD_REPORTING_LINES_ROUTE = `${DASHBOARD_ROUTE}/people/add-reporting-lines`;
export const TEAM_ORG_CHART_ROUTE = `${DASHBOARD_ROUTE}/people/org-chart`;
export const TEAM_WORLD_MAP_ROUTE = `${DASHBOARD_ROUTE}/people/world-map`;

export const TEAM_PLAN_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/team-plans/[slug]`;
export const ROLES_PERMISSIONS_CREATE = `${DASHBOARD_ROUTE}/roles-permissions/create`;
export const ROLES_PERMISSIONS_EDIT = `${DASHBOARD_ROUTE}/roles-permissions/[slug]`;

export const OFFBOARDING_ROUTE = `${DASHBOARD_ROUTE}/offboarding`;

export const CAREER_JOURNAL_ROUTE = `${DASHBOARD_ROUTE}/career-journal`;
export const CAREER_JOURNAL_PERFORMANCE_CYCLE_ROUTE = `${CAREER_JOURNAL_ROUTE}/performance-cycle/[cycleSlug]`;
export const CAREER_JOURNAL_PERFORMANCE_CYCLE_FORM_ROUTE = `${CAREER_JOURNAL_PERFORMANCE_CYCLE_ROUTE}/snapshot/[snapshotSlug]/instance/[snapshotInstanceSlug]/assessment/[assessmentSlug]`;

// Admin routes
export const ADMIN_DASHBOARD_ROUTE = '/rivendell';
export const ADMIN_COUNTRIES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/countries`;
export const ADMIN_COUNTRY_ROUTE = `${ADMIN_COUNTRIES_ROUTE}/details/[slug]`;
export const ADMIN_COUNTRY_FORM_EDITOR_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/countries/form-editor`;
export const ADMIN_COUNTRY_FORM_EDITOR_BY_SLUG = `${ADMIN_DASHBOARD_ROUTE}/countries/[countryCode]/forms/[formType]/[productType]/[slug]`;
export const ADMIN_COMPANIES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/companies`;
export const ADMIN_COMPANIES_BULK_EOR_UPLOADER_ROUTE = `${ADMIN_COMPANIES_ROUTE}/[slug]/people/full_time/bulk`;
export const ADMIN_COMPANIES_PROFILE_ROUTE = `${ADMIN_COMPANIES_ROUTE}/[slug]`;
export const ADMIN_LEGAL_ENTITIES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/legal-entities`;
export const ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]`;
export const ADMIN_LEGAL_ENTITIES_ONBOARDING_TASKS_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/onboarding-tasks`;
export const ADMIN_LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/add-signatory`;
export const ADMIN_LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/setup-pay-schedule`;
export const ADMIN_LEGAL_ENTITIES_SETUP_STATE_TAX_DETAILS_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/setup-state-tax-details`;
export const ADMIN_LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/upload-documents`;
export const ADMIN_LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/[slug]/employee-upload-documents`;
export const ADMIN_LEGAL_ENTITIES_TEMPLATE_ROUTE = `${ADMIN_LEGAL_ENTITIES_ROUTE}/templates/[slug]`;
export const ADMIN_PEOPLE_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/people`;
export const ADMIN_ONBOARDING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/onboarding`;
export const ADMIN_USERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/users`;
export const ADMIN_USER_DETAILS_ROUTE = `${ADMIN_USERS_ROUTE}/[userSlug]`;
export const ADMIN_BILLING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/billing`;
export const ADMIN_INTERCOMPANY_TRANSACTIONS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/inter-company-invoices/transactions`;
export const ADMIN_INTERCOMPANY_TRANSACTION_DETAILS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/inter-company-invoices/transactions/[slug]`;
export const ADMIN_INTERCOMPANY_INVOICES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/inter-company-invoices`;
export const ADMIN_INTERCOMPANY_INVOICE_DETAILS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/inter-company-invoices/[slug]`;
export const ADMIN_INTERCOMPANY_INVOICE_VAT_GROUPS = `${ADMIN_DASHBOARD_ROUTE}/inter-company-invoices/vat-groups`;
export const ADMIN_INVOICES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/invoices`;
export const ADMIN_INVOICE_DETAILS_ROUTE = `${ADMIN_INVOICES_ROUTE}/[slug]`;
export const ADMIN_PAYMENT_EVENTS_ROUTE = `${ADMIN_BILLING_ROUTE}/events`;
export const ADMIN_PAYROLL_RUNS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payrolls`;
export const ADMIN_PAYROLL_CALENDARS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payroll-calendars`;
export const ADMIN_PAYROLL_CALENDAR_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payroll-calendars/[slug]`;
export const ADMIN_EDIT_PAYROLL_CALENDAR_ROUTE = `${ADMIN_PAYROLL_CALENDAR_ROUTE}/edit`;
export const ADMIN_CREATE_PAYROLL_CALENDAR_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payroll-calendars/new`;
export const ADMIN_PAYROLL_OUTPUTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payroll-outputs`;
export const ADMIN_PAYROLL_RUN_ROUTE = `${ADMIN_PAYROLL_RUNS_ROUTE}/[slug]`;
export const ADMIN_PAYROLL_RUN_PAYMENTS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/payments`;
export const ADMIN_PAYROLL_RUN_EMPLOYEES_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/employees`;
export const ADMIN_PAYROLL_RUN_RECONCILIATION_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/reconciliation`;
export const ADMIN_PAYROLL_RUN_ADJUSTMENTS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/adjustments`;
export const ADMIN_PAYROLL_RUN_DATA_CHANGES_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/data-changes`;
export const ADMIN_PAYROLL_RUN_EXPENSES_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/expenses`;
export const ADMIN_PAYROLL_RUN_INCENTIVES_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/incentives`;
export const ADMIN_PAYROLL_RUN_PAYSLIPS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/payslips`;
export const ADMIN_PAYROLL_RUN_TIMEOFF_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/time-off`;
export const ADMIN_PAYROLL_RUN_PAYROLL_OUTPUTS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/outputs`;
export const ADMIN_PAYROLL_RUN_FILES_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/files`;
export const ADMIN_PAYROLL_RUN_FILES_REVIEW_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/files-review`;
export const ADMIN_PAYROLL_RUN_CHECKS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/checks`;
export const ADMIN_PAYROLL_RUN_PAY_ELEMENTS_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/pay-elements`;
export const ADMIN_PAYROLL_RUN_GROSS_TO_NET_REPORT_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/gross-to-net-reports/[gtn-slug]`;
export const ADMIN_PAYROLL_RUN_BILLING_BREAKDOWN_ROUTE = `${ADMIN_PAYROLL_RUN_ROUTE}/billing-breakdown`;
export const ADMIN_PAYROLL_REPORTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payroll-reports`;
export const ADMIN_PAY_ELEMENTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/pay-elements`;
export const ADMIN_PAY_ELEMENTS_CREATE_ROUTE = `${ADMIN_PAY_ELEMENTS_ROUTE}/create`;
export const ADMIN_PAY_ELEMENTS_CREATE_INLINE_ROUTE = `${ADMIN_PAY_ELEMENTS_CREATE_ROUTE}/inline`;
export const ADMIN_PAY_ELEMENTS_EDIT_ROUTE = `${ADMIN_PAY_ELEMENTS_ROUTE}/edit/[slug]`;
export const ADMIN_BILLING_DETAILS_ROUTE = `${ADMIN_BILLING_ROUTE}/[slug]`;
export const ADMIN_PAYSLIPS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payslips`;
export const ADMIN_PAYSLIP_DETAILS_ROUTE = `${ADMIN_PAYSLIPS_ROUTE}/[payslipSlug]`;
export const ADMIN_CONTRACTORS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/contractors`;
export const ADMIN_CREATE_PAYSLIP_ROUTE = `${ADMIN_PAYSLIPS_ROUTE}/create`;
export const ADMIN_ACCOUNTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/users-roles`;
export const ADMIN_USER_UPLOAD_DOCUMENT_ROUTE = `${ADMIN_PEOPLE_ROUTE}/[userSlug]/upload-document`;
export const ADMIN_CREATE_USER_ROUTE = `${ADMIN_PEOPLE_ROUTE}/create`;
export const ADMIN_CONVERT_USER_ROUTE = `${ADMIN_PEOPLE_ROUTE}/convert`;
export const ADMIN_BENEFITS_SETUP_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/benefits/setup`;
export const ADMIN_BENEFITS_SETUP_BULK_ROUTE = `${ADMIN_BENEFITS_SETUP_ROUTE}/bulk/[type]`;
export const ADMIN_BENEFITS_COSTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/benefits/management`;
export const ADMIN_BENEFITS_COSTS_BULK_ROUTE = `${ADMIN_BENEFITS_COSTS_ROUTE}/bulk/[type]`;
export const ADMIN_BENEFITS_EDITOR_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/benefits-editor`;
export const ADMIN_BENEFITS_OFFERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/benefits/offers`;
export const ADMIN_BENEFITS_OFFERS_BULK_ROUTE = `${ADMIN_BENEFITS_OFFERS_ROUTE}/bulk/[type]`;
export const ADMIN_AI_COMPANY_INSIGHTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/ai-company-insights`;
export const ADMIN_CONTRACTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/contracts`;
export const ADMIN_CONTRACTS_AUTOMATIONS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/contracts/automations/[type]`;
export const ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE = `${ADMIN_CONTRACTS_ROUTE}/amendments/bulk`;
export const ADMIN_CONTRACT_AMENDMENTS_SELECT_TEMPLATE_ROUTE = `${ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE}/select-template`;
export const ADMIN_CONTRACT_AMENDMENTS_GENERATE_CSV_ROUTE = `${ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE}/generate-csv`;
export const ADMIN_CONTRACT_AMENDMENTS_UPLOAD_CSV_ROUTE = `${ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE}/upload-csv`;
export const ADMIN_CONTRACT_AMENDMENTS_REVIEW_ROUTE = `${ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE}/review`;
export const ADMIN_CONTRACT_AMENDMENTS_SUBMIT_ROUTE = `${ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE}/submit`;
export const ADMIN_EDIT_CONTRACT_TEMPLATE_ROUTE_V2 = `${ADMIN_DASHBOARD_ROUTE}/contracts-editor/template/[slug]`;
export const ADMIN_EDIT_CONTRACT_DRAFT_ROUTE_BASE = `${ADMIN_DASHBOARD_ROUTE}/contracts-editor/contract`;
export const ADMIN_EDIT_CONTRACT_DRAFT_ROUTE = `${ADMIN_EDIT_CONTRACT_DRAFT_ROUTE_BASE}/[slug]`;
export const ADMIN_TIME_OFF_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/time-off`;
export const ADMIN_TIME_TRACKING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/time-tracking`;
export const ADMIN_EXPENSES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/expenses`;
export const ADMIN_EXPENSES_PROCESSING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/expenses/processing`;
export const ADMIN_INCENTIVES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/incentives`;
export const ADMIN_INCENTIVES_PROCESSING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/incentives/processing`;
export const ADMIN_ADJUSTMENTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/adjustments`;
export const ADMIN_DATA_CHANGES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-changes`;
export const ADMIN_EMPLOYMENT_FEES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/employment-fees`;
export const ADMIN_PRICING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/pricing`;
export const ADMIN_OFFPAYSLIP_COSTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/off-payslip-costs`;
export const ADMIN_CREATE_OFF_PAYSLIP_COST_ROUTE = `${ADMIN_OFFPAYSLIP_COSTS_ROUTE}/create`;
export const ADMIN_OFF_PAYSLIP_COST_DETAILS_ROUTE = `${ADMIN_OFFPAYSLIP_COSTS_ROUTE}/[slug]`;
export const ADMIN_CUSTOM_NOTIFICATIONS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/custom-notifications`;
export const ADMIN_NEW_CUSTOM_NOTIFICATION_ROUTE = `${ADMIN_CUSTOM_NOTIFICATIONS_ROUTE}/new`;
export const ADMIN_COST_CALCULATOR_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/tce-calculator`;
export const ADMIN_COMPENSATION_BENCHMARK_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/compensation-benchmarking`;
export const ADMIN_TALENT_INSIGHTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/talent-insights`;
export const ADMIN_KDB_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/knowledge-database`;
export const ADMIN_KDB_COUNTRY_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/knowledge-database/country/[regionSlug]`;
export const ADMIN_KDB_FACT_ROUTE = `${ADMIN_KDB_ROUTE}/facts/[name]`;
export const ADMIN_KDB_FACT_SCHEMA_EDITOR_ROUTE = `${ADMIN_KDB_ROUTE}/facts/schema-editor`;
export const ADMIN_BULK_PAYROLL_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/payrolls/bulk`;
export const ADMIN_CREATE_LEGAL_ENTITY_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/companies/[slug]/legal-entities/new`;
export const ADMIN_BULK_LEGAL_ENTITIES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/companies/[slug]/legal-entities/[legalEntitySlug]/bulk`;
export const ADMIN_BULK_LEGAL_ENTITIES_ROUTE_V2 = `${ADMIN_DASHBOARD_ROUTE}/companies/[slug]/legal-entities/[legalEntitySlug]/bulkV2`;
export const ADMIN_HANDBOOK_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/handbook`;
export const ADMIN_PERSISTED_CONFIG_SETTINGS = `${ADMIN_DASHBOARD_ROUTE}/config-settings`;
export const ADMIN_PERSISTED_CONFIG_SETTINGS_DETAIL = `${ADMIN_DASHBOARD_ROUTE}/config-settings/[slug]`;
export const ADMIN_BETA_FEATURES_USERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/beta-features-users`;
export const ADMIN_AWESOME_DEMOS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/awesome-demos`;
export const ADMIN_NEW_AWESOME_DEMO_ACCOUNT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/awesome-demos/new`;
export const ADMIN_HANDBOOK_DETAIL_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/handbook/[id]`;
export const ADMIN_ACCOUNT_SETTINGS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/account-settings`;
export const ADMIN_MANAGE_2FA_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/manage-2fa`;
export const ADMIN_MOBILITY_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility`;
export const ADMIN_MOBILITY_RIGHT_TO_WORK_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility/right-to-work/[slug]`;
export const ADMIN_MOBILITY_PRE_HIRE_ASSESSMENT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility/pre-hire-assessment/[slug]`;
export const ADMIN_MOBILITY_RIGHT_TO_WORK_LIFECYCLE_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility/right-to-work/[slug]/lifecycle`;
export const ADMIN_MOBILITY_GENERATE_DOCUMENT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility/generate-document/[slug]`;
export const ADMIN_MOBILITY_GENERATE_DOCUMENT_REVIEW_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/mobility/generate-document/[slug]/review`;
export const ADMIN_FRAUD_CONTROLS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/fraud-controls`;
export const ADMIN_KYB_DASHBOARD_ROUTE = `${ADMIN_FRAUD_CONTROLS_ROUTE}/kyb-dashboard`;
export const ADMIN_BLOCK_EMAIL_DOMAINS_ROUTE = `${ADMIN_FRAUD_CONTROLS_ROUTE}/block-email-domains`;
export const ADMIN_SEVERANCE_RESERVES_POLICY_ROUTE = `${ADMIN_FRAUD_CONTROLS_ROUTE}/severance-reserves-policy`;
export const ADMIN_ROLES_PERMISSIONS_CREATE = `${ADMIN_DASHBOARD_ROUTE}/roles-permissions/create`;
export const ADMIN_ROLES_PERMISSIONS_EDIT = `${ADMIN_DASHBOARD_ROUTE}/roles-permissions/[slug]`;
export const ADMIN_TEAM_PLAN_DETAILS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/team-plans/[slug]`;
export const ADMIN_EMPLOYMENT_REQUESTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/employment-requests`;
export const ADMIN_BULK_PAY_ELEMENTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/pay-elements/bulk/[type]`;
export const ADMIN_SERVICE_COSTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/service-costs`;
export const ADMIN_SERVICE_COST_BY_SLUG_ROUTE = `${ADMIN_SERVICE_COSTS_ROUTE}/[slug]`;
export const ADMIN_ADD_SERVICE_COSTS_ROUTE = `${ADMIN_SERVICE_COSTS_ROUTE}/create`;
export const ADMIN_EDIT_SERVICE_COST_BY_SLUG_ROUTE = `${ADMIN_SERVICE_COSTS_ROUTE}/edit/[slug]`;
export const ADMIN_COST_APPROVAL_REQUESTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/cost-approval-requests`;
export const ADMIN_COST_APPROVAL_REQUESTS_DETAILS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/cost-approval-requests/[slug]`;
export const ADMIN_COST_APPROVAL_REQUESTS_CREATE_COST_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/cost-approval-requests/[slug]/cost`;
export const ADMIN_COST_APPROVAL_REQUESTS_CREATE_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/cost-approval-requests/create`;
export const ADMIN_COST_APPROVAL_REQUESTS_EDIT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/cost-approval-requests/[slug]/edit`;
export const ADMIN_OFFBOARDING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/offboarding`;
export const ADMIN_OFFBOARDING_EMPLOYEE_ROUTE = `${ADMIN_OFFBOARDING_ROUTE}/[userSlug]`;
export const ADMIN_NEW_EMPLOYMENT_TYPE_SLUG_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/people/new/[productType]/[slug]`;
export const ADMIN_EMPLOYEE_PAY_DETAILS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/pay-details/[contractSlug]`;
export const ADMIN_TEAM_PLANS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/team-plans`;
export const ADMIN_BULK_CONTRACT_AMENDMENT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/bulk-contract-amendments/[slug]`;
export const ADMIN_FREELANCERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/freelancers`;
export const ADMIN_TALENT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/talent`;
export const ADMIN_TALENT_COMPANY_PROFILE_ROUTE = `${ADMIN_TALENT_ROUTE}/company-profiles/[companySlug]`;
export const ADMIN_TALENT_COMPANY_PROFILE_EDIT_ROUTE = `${ADMIN_TALENT_ROUTE}/company-profiles/[companySlug]/edit`;
export const ADMIN_TALENT_JOB_DETAILS_ROUTE = `${ADMIN_TALENT_ROUTE}/jobs/[jobSlug]`;
export const ADMIN_TALENT_JOB_DETAILS_EDIT_ROUTE = `${ADMIN_TALENT_ROUTE}/jobs/[jobSlug]/edit`;
export const ADMIN_BILLABLES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/billables`;
export const ADMIN_CREATE_NEW_TEMPLATE_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports/templates/new`;
export const ADMIN_DATA_EXPORTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports`;
export const ADMIN_TEMPLATE_DATA_TRANSFORM_CONFIGURATION = `${ADMIN_DASHBOARD_ROUTE}/data-exports/templates/[slug]/data-transform-configuration`;
export const ADMIN_EDIT_TEMPLATE_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports/templates/[slug]/edit`;
export const ADMIN_TEMPLATE_PREVIEW_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports/templates/[slug]/preview`;
export const ADMIN_CREATE_NEW_DATA_EXPORT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports/exports/new`;
export const ADMIN_EDIT_DATA_EXPORT_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/data-exports/exports/[slug]/edit`;
export const ADMIN_BULK_UPLOADERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/bulk-uploaders`;

// Onboarding routes
export const ONBOARDING_ROUTE = '/onboarding';
export const ONBOARDING_CREATE_PASSWORD = `${ONBOARDING_ROUTE}/create-password`;
export const ONBOARDING_COMPANY_ROUTE = `${ONBOARDING_ROUTE}/company`;
export const ONBOARDING_INVITATION_ALREADY_ACCEPTED_ROUTE = `${ONBOARDING_ROUTE}/invitation-already-accepted`;
export const ONBOARDING_COMPANY_NAME_ROUTE = `${ONBOARDING_COMPANY_ROUTE}/name`;
export const ONBOARDING_COMPANY_SUCCESS_ROUTE = `${ONBOARDING_COMPANY_ROUTE}/success`;
export const ONBOARDING_COMPANY_INFO_ROUTE = `${ONBOARDING_COMPANY_ROUTE}/info`;
export const ONBOARDING_EMPLOYEE_ROUTE = `${ONBOARDING_ROUTE}/employee/[employmentSlug]`;
export const ONBOARDING_EMPLOYEE_TASKS_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/tasks`;
export const ONBOARDING_EMPLOYEE_PERSONAL_DETAILS_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/personal-details`;
export const ONBOARDING_EMPLOYEE_PERSONAL_DETAILS_INFORMATION_ROUTE = `${ONBOARDING_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/information`;
export const ONBOARDING_EMPLOYEE_HOME_ADDRESS_ROUTE = `${ONBOARDING_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/home-address`;
export const ONBOARDING_EMPLOYEE_EMERGENCY_CONTACT_ROUTE = `${ONBOARDING_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/emergency-contact`;
export const ONBOARDING_EMPLOYEE_ADMINISTRATIVE_DETAILS_INFORMATION_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/administrative-details/information`;
export const ONBOARDING_EMPLOYEE_EMPLOYMENT_ELIGIBILITY_INFORMATION_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/employment-eligibility`;
export const ONBOARDING_EMPLOYEE_PAYMENT_DETAILS_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/payment-details`;
export const ONBOARDING_EMPLOYEE_ADDITIONAL_DOCUMENTS = `${ONBOARDING_EMPLOYEE_ROUTE}/additional-documents`;
export const ONBOARDING_EMPLOYEE_VERIFY_IDENTITY_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/verify-identity`;
export const ONBOARDING_EMPLOYEE_BUSINESS_INFORMATION_ROUTE = `${ONBOARDING_EMPLOYEE_ROUTE}/business-information`;

// Onboarding freelancer routes
export const ONBOARDING_FREELANCER_ROUTE = `${ONBOARDING_ROUTE}/freelancer`;
export const ONBOARDING_FREELANCER_TASKS_ROUTE = `${ONBOARDING_FREELANCER_ROUTE}/tasks`;
export const ONBOARDING_FREELANCER_PERSONAL_DETAILS_ROUTE = `${ONBOARDING_FREELANCER_ROUTE}/personal-details`;
export const ONBOARDING_FREELANCER_PERSONAL_DETAILS_INFORMATION_ROUTE = `${ONBOARDING_FREELANCER_PERSONAL_DETAILS_ROUTE}/information`;
export const ONBOARDING_FREELANCER_ADMINISTRATIVE_DETAILS_INFORMATION_ROUTE = `${ONBOARDING_FREELANCER_ROUTE}/administrative-details/information`;
export const ONBOARDING_FREELANCER_EMPLOYMENT_ELIGIBILITY_INFORMATION_ROUTE = `${ONBOARDING_FREELANCER_ROUTE}/employment-eligibility`;
export const ONBOARDING_FREELANCER_HOME_ADDRESS_ROUTE = `${ONBOARDING_FREELANCER_PERSONAL_DETAILS_ROUTE}/home-address`;
export const ONBOARDING_FREELANCER_VERIFY_IDENTITY_ROUTE = `${ONBOARDING_FREELANCER_ROUTE}/verify-identity`;

// Pending tasks routes
export const PENDING_TASKS_ROUTE = '/pending-tasks';
// for employee/contractors
export const PENDING_TASKS_EMPLOYEE_ROUTE = `${PENDING_TASKS_ROUTE}/employee/[employmentSlug]`;
export const PENDING_TASKS_EMPLOYEE_TASKS_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/tasks`;
export const PENDING_TASKS_EMPLOYEE_PERSONAL_DETAILS_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/personal-details`;
export const PENDING_TASKS_EMPLOYEE_PERSONAL_DETAILS_INFORMATION_ROUTE = `${PENDING_TASKS_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/information`;
export const PENDING_TASKS_EMPLOYEE_HOME_ADDRESS_ROUTE = `${PENDING_TASKS_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/home-address`;
export const PENDING_TASKS_EMPLOYEE_EMERGENCY_CONTACT_ROUTE = `${PENDING_TASKS_EMPLOYEE_PERSONAL_DETAILS_ROUTE}/emergency-contact`;
export const PENDING_TASKS_EMPLOYEE_ADMINISTRATIVE_DETAILS_INFORMATION_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/administrative-details/information`;
export const PENDING_TASKS_EMPLOYEE_EMPLOYMENT_ELIGIBILITY_INFORMATION_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/employment-eligibility`;
export const PENDING_TASKS_EMPLOYEE_PAYMENT_DETAILS_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/payment-details`;
export const PENDING_TASKS_EMPLOYEE_EXPIRED_INVITE_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/expired-invite`;
export const PENDING_TASKS_EMPLOYEE_ADDITIONAL_DOCUMENTS = `${PENDING_TASKS_EMPLOYEE_ROUTE}/additional-documents`;
export const PENDING_TASKS_EMPLOYEE_VERIFY_IDENTITY_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/verify-identity`;
export const PENDING_TASKS_EMPLOYEE_BUSINESS_INFORMATION_ROUTE = `${PENDING_TASKS_EMPLOYEE_ROUTE}/business-information`;

// employer
export const CONFIRM_IMPLEMENTATION_ROUTE = `${DASHBOARD_ROUTE}/confirm-implementation-fee/[resourceSlug]`;
export const EMPLOYER_EXPENSES_REVIEW_ROUTE = `${EXPENSES_ROUTE}/review`;

// workflows
export const WORKFLOWS_HOME_ROUTE = `${COMPANY_SETTINGS_ROUTE}/workflows`;
export const WORKFLOW_BUILDER_ROUTE = `${COMPANY_SETTINGS_ROUTE}/workflows/[slug]`;
export const WORKFLOW_NEW_BUILDER_ROUTE = `${COMPANY_SETTINGS_ROUTE}/workflows/new`;

// OpenID Connect for integrations route
export const INTEGRATION_CONNECT_ACCOUNT_ROUTE = `${API_BASE_URL_CLIENT}/api/v1/integration/[integrationName]`;

// fill form routes
export const FILL_FORM_EMPLOYEE_ROUTE = `${DASHBOARD_ROUTE}/fill-form/[slug]`;
export const FILL_FORM_ONBOARDING_ROUTE = `${ONBOARDING_ROUTE}/fill-form/[slug]`;

// External Routes
export const REMOTE_PRODUCT_UPDATES_ROUTE = 'https://remote.com/blog/category/product-updates';
export const REMOTE_EQUITY_CAMPAIGN_ROUTE = `${MARKETING_BASE_URL}/global-hr/offer-stock-options?request_from_platform=true&utm_campaign=equity_launch&utm_source=login_page`;
export const JOBS_ROUTE = `${REMOTE_JOBS_BASE_URL}/jobs`;
export const JOBS_PRICING_ROUTE = `${JOBS_ROUTE}/pricing`;
export const HELP_CENTER_ROUTE = 'https://support.remote.com';
export const HELP_CENTER_ONBOARDING_EMPLOYEE = `${HELP_CENTER_ROUTE}/hc/en-us/articles/4411263188621-Employee-Onboarding-Overview-`;
export const HELP_CENTER_WHAT_IS_RESERVE_PAYMENT = `${HELP_CENTER_ROUTE}/hc/en-us/articles/12695731865229-What-is-a-reserve-payment`;
export const COMPANY_ADMIN_INFO_HELP_CENTER_ROUTE = `${HELP_CENTER_ROUTE}/hc/en-us/articles/4410885422349-Adding-users-to-your-company-on-Remote`;
export const DEFAULT_ROLES_HELP_CENTER = `${HELP_CENTER_ROUTE}/hc/en-us/articles/4413862365837-What-can-the-different-company-users-do-on-Remote`;
export const HELP_CENTER_EMPLOYEE_USING_REMOTE = `${HELP_CENTER_ROUTE}/hc/en-us/sections/4411146460173-Using-Remote`;
export const REMOTE_COUNTRY_EXPLORER_ROUTE = 'https://remote.com/country-explorer';
export const REMOTE_COUNTRY_HIRING_CONSIDERATIONS_ROUTE = `https://remote.com/country-hiring-considerations`;
export const REMOTE_FOR_CONTRACTORS_ROUTE =
  'https://support.remote.com/hc/en-us/categories/4410374874253-Contractor-Management';
export const CONTRACTOR_SCHEDULED_INVOICING_FREQUENCIES_HELP_CENTER_ROUTE = `${HELP_CENTER_ROUTE}/hc/en-us/articles/13291069350413-What-are-the-frequency-options-when-scheduling-a-contractor-s-invoice`;
export const CONTRACTOR_SCHEDULED_INVOICING = `${HELP_CENTER_ROUTE}/hc/en-us/articles/13291039232141-How-to-create-a-scheduled-contractor-invoice`;
export const CONTRACTOR_MANAGEMENT_PLUS_MARKETING_ROUTE = `${MARKETING_BASE_URL}/global-hr/contractor-management-plus`;
export const NON_REFUNDABLE_ANNUAL_FEES_HELP_CENTER_ROUTE = `${HELP_CENTER_ROUTE}/hc/en-us/articles/4406896976013-What-happens-to-the-unused-management-fee-if-the-contract-is-terminated-before-the-next-billing-cycle-`;
export const JURO_CONTRACT_ROUTE = 'https://app.juro.com/view/[id]';
export const REMOTE_ACCESSIBILITY_STATEMENT = `${MARKETING_BASE_URL}/accessibility-statement`;
export const REMOTE_TERMS_OF_SERVICE = `${MARKETING_BASE_URL}/terms-of-service`;
export const REMOTE_TERMS_OF_SERVICE_ARCHIVE = `${REMOTE_TERMS_OF_SERVICE}/archive`;
export const REMOTE_PRICING_ROUTE = `${MARKETING_BASE_URL}/pricing`;
export const REMOTE_CONTACT_US = `${MARKETING_BASE_URL}/contact-us`;
export const REMOTE_FOR_GOOD_PROMOTION = `${HELP_CENTER_ROUTE}/hc/en-us/sections/4407358157709-Remote-For-Good`;
export const REMOTE_FOR_STARTUPS_PROMOTION = `${HELP_CENTER_ROUTE}/hc/en-us/sections/4407290830733-Remote-For-Startups`;
export const REMOTE_FOR_REFUGEES_PROMOTION = `${HELP_CENTER_ROUTE}/hc/en-us/sections/4410682840333-Remote-For-Refugees`;
export const REMOTE_COMPRESS_LARGE_FILE_ROUTE = `${HELP_CENTER_ROUTE}/hc/en-us/articles/8186159901837`;
export const REMOTE_IR35 = `${HELP_CENTER_ROUTE}/hc/en-us/articles/17919344152717-How-does-IR35-work`;
export const REMOTE_HOW_TO_REQUEST_REMOTE_WORK_AUTH = `${HELP_CENTER_ROUTE}/hc/en-us/articles/21960403649293-How-to-request-a-Remote-work-or-Business-travel-authorization`;
export const REMOTE_EMPLOYER_REMOTE_WORK_AUTH = `${HELP_CENTER_ROUTE}/hc/en-us/articles/29712372222861-Authorization-for-remote-work-and-business-travel}`;
export const REMOTE_API_TERMS_OF_SERVICE = `${MARKETING_BASE_URL}/api-terms-of-use`;
export const REMOTE_STATUS = 'https://remote.com/status';
export const REMOTE_PREMIUM_PUBLIC_NOTION =
  'https://remotecom.notion.site/Remote-Premium-543ffd781a1048bcb5b6c2fa04e8f544';
export const REMOTE_PRIVACY_POLICY = `${MARKETING_BASE_URL}/privacy-policy`;
export const HELP_CENTER_ELIGIBILITY_GUIDE = `https://remote.com/country-hiring-considerations`; // needs to be updated when displaying the link
// getters

// Gets `as` - The path that will be rendered in the browser URL bar.
// Used for dynamic routes
export const getAsPath = (href, asOptions = {}) => {
  const params = Object.keys(asOptions);

  return params.reduce((acc, paramKey) => acc.replace(`[${paramKey}]`, asOptions[paramKey]), href);
};

export const pushTo = (push, href, asOptions = {}) => push(href, getAsPath(href, asOptions));
