import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { fonts } from './fonts';
import { themeV2 as theme } from './theme';

const colorsMain = Object.keys(theme.colors).filter(
  (name) => typeof theme.colors[name] === 'string'
); // exclude color palettes

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  :root {
    --typography-fontPrimary: ${fonts.body};
    --typography-fontMono: ${fonts.mono};
    --typography-fontBrand: ${fonts.brand};
    ${colorsMain.map((colorName) => `--colors-${colorName}: ${theme.colors[colorName]};`)}
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-family: var(--typography-fontPrimary);
    color: ${theme.colors.grey[900]}; 
    ${theme.typography.base}
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
  }
`;
