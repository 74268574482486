import type { ElementType, FC, MouseEvent } from 'react';
import { forwardRef } from 'react';

import { SROnly } from '../text';

import type { ButtonProps } from './Button';
import {
  StandaloneLinkIcon,
  LoadingAddon,
  StandaloneLink as StandaloneLinkStyled,
} from './Button.styled';
import type { PolymorphicComponent, PolymorphicComponentProps } from './types';

export interface StandaloneLinkProps extends ButtonProps {
  children: React.ReactNode;
  /** Defines the button color scheme */
  tone?: 'primary' | 'secondary' | 'destructive';
  disabled?: boolean;
}

const StandaloneLink: PolymorphicComponent<'button', StandaloneLinkProps> = forwardRef(
  <C extends ElementType = 'button'>(
    {
      children,
      tone = 'primary',
      as,
      size = 'md',
      isLoading = false,
      IconBefore,
      IconAfter,
      disabled,
      ...props
    }: PolymorphicComponentProps<C, StandaloneLinkProps>,
    ref?: PolymorphicComponentProps<C, StandaloneLinkProps>['ref']
  ) => {
    const elAttrs = props.href
      ? // We force cast `as` to any to avoid Typescript errors due to `styled-components` trying to infer the rest of the HTML attributes.
        { href: props.href, as: (as || 'a') as any }
      : { type: 'button', as };

    const disabledProps = {
      'aria-disabled': 'true',
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        // aria-disabled does not prevent interaction
        // as the disabled attr
        e.preventDefault();
      },
    };
    const loadingAttrs = isLoading ? disabledProps : {};
    const disabledAttrs = disabled ? disabledProps : {};

    return (
      <StandaloneLinkStyled
        ref={ref}
        $size={size}
        $tone={tone}
        {...elAttrs}
        {...props}
        {...loadingAttrs}
        {...disabledAttrs}
      >
        {IconBefore && <StandaloneLinkIcon as={IconBefore} $place="before" $size={size} />}
        {children}
        {isLoading && (
          <>
            <SROnly aria-live="assertive">Loading</SROnly>
            <LoadingAddon />
          </>
        )}
        {IconAfter && <StandaloneLinkIcon as={IconAfter} $place="after" $size={size} />}
      </StandaloneLinkStyled>
    );
  }
);

export const StandaloneLinkForStory = StandaloneLink as FC<StandaloneLinkProps>;

export default StandaloneLink;
