import Head from 'next/head';
import { createContext, useState, useEffect, useContext } from 'react';
import { useTheme } from 'styled-components';

const BrowserContext = createContext();

const VIEWPORT_DEFAULT = 'width=device-width';
const VIEWPORT_DESKTOP_FORCED = 'width=1024';

const BrowserContextProvider = ({ children }) => {
  const [screenSizes, setScreenSizes] = useState({
    md: null, // TODO support other screen sizes
  });
  const theme = useTheme();
  const [isDesktopViewportForced, setIsDesktopViewportForced] = useState(false);

  useEffect(() => {
    const mediaScreenMD = window.matchMedia(`(max-width: ${theme.breakpointValues.medium}px)`);
    setScreenSizes({ md: mediaScreenMD.matches });

    if (mediaScreenMD.matches) {
      // Get persisted state from sessionStorage
      if (sessionStorage.getItem('isDesktopViewportForced') === 'true') {
        setIsDesktopViewportForced(true);
      }
    }

    const mediaScreenMDListener = (event) => {
      setScreenSizes({ md: event.matches });
    };

    mediaScreenMD.addEventListener?.('change', mediaScreenMDListener);

    return () => {
      mediaScreenMD.removeEventListener?.('change', mediaScreenMDListener);
    };
  }, [theme.breakpointValues.medium]);

  function toggleViewportDesktopForced() {
    if (!isDesktopViewportForced) {
      setIsDesktopViewportForced(true);
      sessionStorage.setItem('isDesktopViewportForced', 'true');
    } else {
      setIsDesktopViewportForced(false);
      sessionStorage.setItem('isDesktopViewportForced', 'false');
    }
  }

  return (
    <BrowserContext.Provider
      value={{
        isScreenMD: screenSizes.md,
        isDesktopViewportForced,
        toggleViewportDesktopForced,
      }}
    >
      <>
        <Head>
          <meta
            name="viewport"
            content={isDesktopViewportForced ? VIEWPORT_DESKTOP_FORCED : VIEWPORT_DEFAULT}
          />
        </Head>
        {children}
      </>
    </BrowserContext.Provider>
  );
};

function useBrowserContext() {
  const context = useContext(BrowserContext);

  return context;
}

export { BrowserContext, BrowserContextProvider, useBrowserContext };
