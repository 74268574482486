import Cookies from 'js-cookie';

const COOKIE_NAME = 'RAP_DEBUGGER';

export const toggleDebugger = () => {
  if (Cookies.get(COOKIE_NAME) === 'true') {
    Cookies.remove(COOKIE_NAME);
    return;
  }
  Cookies.set(COOKIE_NAME, 'true');
};

export const isRolesPermissionsDebuggerEnabled = () => {
  return Cookies.get(COOKIE_NAME) === 'true';
};
