import type { DefaultTheme } from 'styled-components';

import type { BadgeProps } from './Badge';

export const getTypeColors = (
  theme: DefaultTheme
): {
  // eslint-disable-next-line no-unused-vars
  [key in BadgeProps['type']]: { from: string; to: string }; // TODO: Allow only theme color values
} => ({
  active: {
    from: theme.colors.greenLight[700],
    to: theme.colors.greenLight[500],
  },
  pending: {
    from: theme.colors.yellow[600],
    to: theme.colors.yellow[400],
  },
  inactive: {
    from: theme.colors.grey[600],
    to: theme.colors.grey[400],
  },
  error: {
    from: theme.colors.red[600],
    to: theme.colors.red[400],
  },
  attention: {
    from: theme.colors.orange[600],
    to: theme.colors.orange[400],
  },
  info: {
    from: theme.colors.brand[500],
    to: theme.colors.brand[300],
  },
  closed: {
    from: theme.colors.cyan[600],
    to: theme.colors.cyan[400],
  },
  scheduled: {
    from: theme.colors.purple[600],
    to: theme.colors.purple[400],
  },
  archived: {
    from: theme.colors.grey[600],
    to: theme.colors.grey[400],
  },
  canceled: {
    from: theme.colors.grey[600],
    to: theme.colors.grey[400],
  },
});
