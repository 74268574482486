import escape from 'lodash/escape';
import unescape from 'lodash/unescape';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

import { SESSION_DATASTORE_USER_KEY } from '@/src/helpers/constants';

export function generateScriptTagWithPayloadInformation(domKey, payload, nonce) {
  return (
    <script
      id={domKey}
      type="application/json"
      nonce={nonce}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: escape(JSON.stringify(payload)),
      }}
    />
  );
}

function setPayloadInsideScript(scriptTag, payload) {
  scriptTag.innerHTML = escape(JSON.stringify(payload));
}

function generateElementFromReactDOM(jsx) {
  const renderContainer = window.document.createElement('div');
  const containerRoot = createRoot(renderContainer);

  /**
   * flushSync forces React to flush any updates inside the callback synchronously and ensures that the DOM is updated immediately
   * Otherwise the DOM is not ready when user info is appended to it.
   * https://beta.reactjs.org/reference/react-dom/flushSync
   */
  flushSync(() => {
    containerRoot.render(jsx);
  });

  return renderContainer.children.item(0);
}

function appendHTMLElementToHead(element) {
  window.document.head.appendChild(element);
}

function updateScriptElement(domKey, payload) {
  const userInfoScript = document.getElementById(domKey);
  if (userInfoScript) {
    setPayloadInsideScript(userInfoScript, payload);
  } else {
    const jsxScriptTag = generateScriptTagWithPayloadInformation(domKey, payload);
    const scriptContainer = generateElementFromReactDOM(jsxScriptTag);
    appendHTMLElementToHead(scriptContainer);
  }
}

/**
 * Append/update the user information as JSON to the DOM to persist between pages
 * @param {Object} user
 */
export function setDOMUserInfo(user) {
  return updateScriptElement(SESSION_DATASTORE_USER_KEY, user);
}

function getDOMScript(domKey) {
  const DOMTargetContent = window.document.getElementById(domKey)?.textContent;
  return DOMTargetContent ? JSON.parse(unescape(DOMTargetContent)) : undefined;
}

export function getDOMUserInfo() {
  return getDOMScript(SESSION_DATASTORE_USER_KEY);
}
