import type * as CSS from 'csstype';
import type { ResponsiveValue, RequiredTheme, Theme, ThemeValue } from 'styled-system';
import { system } from 'styled-system';

export interface CursorProps<ThemeType extends Theme = RequiredTheme> {
  cursor?: ResponsiveValue<CSS.Property.Cursor, ThemeType>;
}

export const cursor = system({
  cursor: true,
});

export interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'space', ThemeType>
> {
  columnGap?: ResponsiveValue<TVal, ThemeType>;
  gap?: ResponsiveValue<TVal, ThemeType>;
  rowGap?: ResponsiveValue<TVal, ThemeType>;
}

export const columnGap = system({
  columnGap: { property: 'columnGap', scale: 'space' },
});

export const gap = system({
  gap: { property: 'gap', scale: 'space' },
});

export const rowGap = system({
  rowGap: { property: 'rowGap', scale: 'space' },
});
