/* eslint-disable import/no-extraneous-dependencies */

'use client';

import type { ReactNode } from 'react';
import { useEffect, createContext, useContext, useState, useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import { AccessTokenStorage } from './AccessTokenStorage';

type FetchTokenFn = () => Promise<unknown>;

type TAccessTokenContext = {
  accessToken: string | null;
  fetchTokenFn?: FetchTokenFn;
};

const defaultContext: TAccessTokenContext = {
  accessToken: null,
};

const AccessTokenContext = createContext<TAccessTokenContext>(defaultContext);

type AccessTokenProviderProps = {
  children?: ReactNode;
};

/**
 * Provider component for the React agnostic access token store.
 */
export function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const accessToken = useSyncExternalStore(
    AccessTokenStorage.subscribe,
    AccessTokenStorage.getSnapshot,
    AccessTokenStorage.getServerSnapshot
  );

  return (
    <AccessTokenContext.Provider
      value={{
        accessToken,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
}

type UseIsAuthenticatedParams = {
  /**
   * Making authentication required for the context the hooks is being called in.
   *
   * When authentication is required and the user is currently is authenticated,
   * there is no access token, the hook will attempt to fetch an access token
   * based on the `fetchTokenFn` set on the `AccessTokenProvider`, handling
   * a failure with `onAuthError`.
   */
  required: boolean;
};

export function useIsAuthenticated(
  { required = false }: UseIsAuthenticatedParams = { required: false }
) {
  const { accessToken } = useContext(AccessTokenContext);
  const isMountedRef = useRef(true);
  const [isLoading, setLoading] = useState(() => {
    if (!accessToken && required) return true;
    return false;
  });

  useEffect(() => {
    const isMounted = isMountedRef.current;
    if (!accessToken && required) {
      setLoading(true);

      AccessTokenStorage.refresh()
        .catch((exception) => {
          // TODO: Add capture http exception
          if (process.env.NODE_ENV && !['test', 'production'].includes(process.env.NODE_ENV)) {
            console.error(
              `[@remote-com/auth] Refreshing access token failed.\n` +
                `- An authentication error should be handled by installing the onAuthFailResponse interceptor.\n` +
                `- Any other error should be captured.`,
              exception
            );
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [accessToken, required]);

  return { isAuthenticated: Boolean(accessToken), isLoading };
}

/**
 * Component to fetch an access token if none is set in the store yet.
 * This can be used to silently login a user that has a valid session cooke (refresh token)
 * when switching between Remote applications or after a page reload.
 */
export function AccessTokenFetcher() {
  const { isAuthenticated } = useIsAuthenticated();

  // The effect could run in a loop should the refresh fail since in case of
  // failure the user would still not be authenticated.
  // However since `isAuthenticated` will not change if the refresh fails,
  // it will remain `false`, the hook will not be executed again.
  //
  // Possible improvement:
  // Because the dependency array is no guarantee that the hook will not be run
  // multiple times we could use state to keep track of the number of attempts
  // that is being reset on a successful refresh.
  // Should the session become valid again through setting a new valid session cookie
  // in another tab/window we could miss this information if we are not attempting
  // another refresh.
  useEffect(() => {
    if (!isAuthenticated) {
      AccessTokenStorage.refresh();
    }
  }, [isAuthenticated]);

  return null;
}
