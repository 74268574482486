import { rgba } from 'polished';

const tintsLevels = [15, 30, 45, 60, 75, 90];
function tintsFrom(colors, levels, isGradient) {
  const tints = {};

  if (isGradient) {
    Object.keys(colors).forEach((name) => {
      const [a, c1, c1a, c2, c2a] = colors[name];
      tints[name] = `linear-gradient(${a}, ${c1} ${c1a}, ${c2} ${c2a})`;

      for (let i = 0; i < levels; i++) {
        const tint = tintsLevels[i];
        tints[`${name}_${tint}`] = `linear-gradient(${a}, ${rgba(c1, tint / 100)} ${c1a}, ${rgba(
          c2,
          tint / 100
        )} ${c2a})`;
      }
    });

    return tints;
  }

  Object.keys(colors).forEach((name) => {
    tints[name] = colors[name];

    for (let i = 0; i < levels; i++) {
      const tint = tintsLevels[i];
      tints[`${name}_${tint}`] = rgba(colors[name], tint / 100);
    }
  });

  return tints;
}

const primaryMain = {
  irisBlue: '#624DE3',
  darkBlue: '#00234B',
  black: '#0F1419',
  blank: '#FFFFFF',
};

const secondaryMain = {
  turquoise: '#75E8F0',
  darkTurquoise: '#01B0BB',
  royalPurple: '#4E3DB5',
  chambray: '#3639A4',

  redPink: '#FF4A5A',
  red: '#DD3150',
  salmon: '#FF9066',
  candlelight: '#FFD012',
  mustard: '#FFE15A',

  radiance: '#0090FF',
  brand: '#0061FF',
  cerulean: '#00B4F5',
  cerise: '#E83E8C',
  cabaret: '#D03765',

  deYork: '#6FCF97',
  conifer: '#B2E33C',
};

const neutralMain = {
  bayoux: '#525F7F',
  lynch: '#617798',
  pigeon: '#A8BDD4',
  heather: '#B1BECD',

  geyser: '#CBD3DC',
  mischka: '#E8ECEE',
  catskillWhite: '#EAF0F6',
  athensGray: '#ECEDF2',
  linkWater: '#F4F7FC',
  alabaster: '#F8FBFF',
};

const greyColors = {
  grey100: '#EDEFF2',
  grey200: '#ADB0B4',
  grey300: '#8A9095',
  grey400: '#646D76',
  grey500: '#464F57',
  grey600: '#2C3135',
  grey700: '#0F1419',
};

const shadesMain = {
  spindle: '#B7B8EB',
};

const lightsMain = {
  iceberg: '#DFF5F6',
  cosmos: '#FFDBDE',
  forget: '#FFF1EC',
  ginFizz: '#FFF9E1',

  pattensBlue: '#DFF1FF',
  wispPink: '#FCE7F0',
  feta: '#F5FBE6',
  foam: '#DFF5FD',
};

const gradientsMain = {
  purpleMinsk: ['227deg', '#5240CB', '0%', '#3C2E92', '80%'],
  zircon: ['180deg', '#F9FBFF', '0%', '#F0F5FF', '100%'],
};

const allTints = {
  ...tintsFrom(primaryMain, 6),
  ...tintsFrom(secondaryMain, 5),
  ...tintsFrom(neutralMain, 5),
  ...tintsFrom(shadesMain, 5),
  ...tintsFrom(lightsMain, 5),
  ...tintsFrom(lightsMain, 5),
  ...tintsFrom(gradientsMain, 5, true),
};

const forms = {
  inputValue: primaryMain.darkBlue,
  inputPlaceholder: neutralMain.bayoux,
  inputLabel: neutralMain.bayoux,
  inputLabelDisabled: allTints.lynch_60,
  inputDescription: neutralMain.lynch,
  inputBorder: neutralMain.heather,
  inputBorderHover: neutralMain.lynch,
  inputBorderFocus: primaryMain.irisBlue,
  inputBorderDisabled: neutralMain.geyser,
  inputGroupLabel: primaryMain.darkBlue,
  inputGroupDescription: neutralMain.lynch,
  error: secondaryMain.red,
};

export const colors = {
  ...greyColors,
  ...allTints,
  ...forms,
  primary: primaryMain.irisBlue,
};
