// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';
import { VERSIONS } from '@/src/services/defaults';

export const fetchFile = makeApiService({
  path: '/files/[slug]',
});

export const fetchBillingDocumentPDF = makeApiService({
  path: '/billing-platform/employer/billing-documents/[slug]/download',
});

export const fetchBillingDocumentPDFAsAdmin = makeApiService({
  path: '/billing-platform/rivendell/billing-documents/[slug]/download',
});

export const fetchBillingDocumentPIIFreeBreakdownAsAdmin = makeApiService({
  path: '/billing-platform/rivendell/billing-documents/[billingDocumentSlug]/pii-breakdown-csv/download',
});

export const fetchFileAsAdmin = makeApiService({
  path: '/files/[slug]',
  version: VERSIONS.ADMIN_V1,
});

export const fetchUploadConfig = makeApiService({
  path: `/upload-config`,
  deepTransformResponse: false,
});

export const notifyFileUpload = makeApiService({
  method: 'post',
  path: `/files/[fileSlug]/notification`,
  version: VERSIONS.ADMIN_V1,
});

export const updateFileAsAdmin = makeApiService({
  method: 'patch',
  path: '/files/[slug]',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});
