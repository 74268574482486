import { getUtm } from '../helpers';
import { createPartnerStackSignUp, getPartnerStackInfo } from '../partnerstack';

export function identifyHubSpotUser(user) {
  // eslint-disable-next-line no-underscore-dangle
  const hubspot = window._hsq || [];

  const { id, email, ...remainingProps } = user || {};

  return createPartnerStackSignUp({
    email,
    id,
    formName: 'Employ signup',
    // failing should not break hubspot.
  }).finally(() => {
    // eslint-disable-next-line camelcase
    const { pspartnerkey, pspartner_group } = getPartnerStackInfo();

    hubspot.push([
      'identify',
      {
        email,
        utm: getUtm(),
        pspartnerkey,
        pspartner_group,
        ...remainingProps,
      },
    ]);
  });
}
