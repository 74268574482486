import { Stack } from '../../foundations/layout/Stack';
import type { ColorPropsWithCustomPalette } from '../../utils/types';
import { Tooltip } from '../tooltip';

import { BadgeSignal, BadgeLabel } from './Badge.styled';

type BadgeLayout = 'standard' | 'compact';

type BadgeSize = 'sm' | 'lg';

export type BadgeType =
  | 'active'
  | 'archived'
  | 'attention'
  | 'canceled'
  | 'closed'
  | 'error'
  | 'inactive'
  | 'info'
  | 'pending'
  | 'scheduled';

export type BadgeProps = {
  /** When set to `true`, disables the tooltip for the badge. */
  isTooltipDisabled?: boolean;
  /** The label text. */
  label: string;
  /** The color of the label text. */
  labelColor?: ColorPropsWithCustomPalette['color'];
  /** Optional extra text to be displayed in a tooltip. */
  labelExtra?: string;
  /** The layout style of the badge. */
  layout?: BadgeLayout;
  /** The size of the badge. */
  size?: BadgeSize;
  /** The type of the badge, which determines its color and conveys a specific meaning. */
  type: BadgeType;
};

export const Badge = ({
  isTooltipDisabled,
  label,
  labelColor = 'grey.600',
  labelExtra,
  layout = 'standard',
  size = 'lg',
  type,
}: BadgeProps) => {
  const hasExtraLabel = !!labelExtra;

  if (layout === 'compact') {
    return (
      <Tooltip label={labelExtra || label} disabled={isTooltipDisabled}>
        <BadgeSignal $size={size} $type={type} tabIndex={0} />
      </Tooltip>
    );
  }

  return (
    <Tooltip label={labelExtra} disabled={!hasExtraLabel || isTooltipDisabled}>
      {/* minWidth is added to allow content to be correctly elided if overflowing */}
      <Stack direction="row" alignItems="center" minWidth={0}>
        <BadgeSignal $size={size} $type={type} />
        {/* title attr is useful in tiny layouts where the text gets truncated (eg table cells) */}
        <BadgeLabel title={hasExtraLabel ? undefined : label} $color={labelColor}>
          {label}
        </BadgeLabel>
      </Stack>
    </Tooltip>
  );
};

export const badgeTypesMapper: { [key in BadgeType as Uppercase<key>]: key } = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  ATTENTION: 'attention',
  CANCELED: 'canceled',
  CLOSED: 'closed',
  ERROR: 'error',
  INACTIVE: 'inactive',
  INFO: 'info',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
};
