import { BroadcastChannel } from 'broadcast-channel';

import { REMOTE_CHANNEL_NAME } from '@/src/domains/broadcast/constants';

/**
 * Post messages using app BroadcastChannel
 * @param {string} message - message to post
 * @returns
 */
export function postMessage(message) {
  if (typeof window === 'undefined') return;
  const broadcastChannel = new BroadcastChannel(REMOTE_CHANNEL_NAME);
  broadcastChannel.postMessage(message);
}
