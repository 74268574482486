export type AccessTokenObject = {
  accessToken: string;
  expiresIn: number;
  tokenType: 'Bearer';
  scope: string;
};

type Listener = () => void;

type Fetcher = () => Promise<AccessTokenObject>;

export class AccessTokenStorage {
  // The access token should be synced across tabs to avoid unnecessary requests.
  static #accessToken: string | null = null;

  static #expiresAt: number | null = null;

  static #tokenType: string | null = null;

  static #listeners: Listener[] = [];

  static #tokenFetcher: Fetcher | null = null;

  static #fetchPromise: Promise<void> | null = null;

  public static setAccessToken(token: AccessTokenObject) {
    this.#accessToken = token.accessToken;
    this.#expiresAt = Date.now() + token.expiresIn * 1000;
    this.#tokenType = token.tokenType;
    this.broadcastChanges();
  }

  public static setFetcher(fetcher: Fetcher) {
    this.#tokenFetcher = fetcher;
  }

  public static async refresh() {
    if (AccessTokenStorage.#tokenFetcher === null) {
      throw new Error(
        '[AccessTokenStorage] Fetcher has to be set before refreshing an access token.'
      );
    }
    try {
      if (AccessTokenStorage.#fetchPromise !== null) {
        return AccessTokenStorage.#fetchPromise;
      }

      // We are setting the access token within a then to make it part of the promise
      // that is being stored and returned for subsequent fetchers.
      // This way when the promise resolves for a caller that did not initiate the fetch
      // we can still ensure that the access token was already refreshed.
      AccessTokenStorage.#fetchPromise = AccessTokenStorage.#tokenFetcher()
        .then((token) => {
          AccessTokenStorage.setAccessToken(token);
        })
        .finally(() => {
          AccessTokenStorage.#fetchPromise = null;
        });

      await AccessTokenStorage.#fetchPromise;
    } catch (exception) {
      AccessTokenStorage.reset();
      throw exception;
    }
  }

  public static reset() {
    AccessTokenStorage.#accessToken = null;
    AccessTokenStorage.#expiresAt = null;
    AccessTokenStorage.#tokenType = null;
    AccessTokenStorage.#fetchPromise = null;
  }

  public static get accessToken() {
    if (this.#tokenType && this.#accessToken) {
      return `${this.#tokenType} ${this.#accessToken}`;
    }
    return null;
  }

  public static get isExpired() {
    if (this.#expiresAt === null) {
      return true;
    }
    return Date.now() > this.#expiresAt;
  }

  public static getSnapshot() {
    return AccessTokenStorage.accessToken;
  }

  public static getServerSnapshot() {
    return null;
  }

  public static subscribe(listener: Listener) {
    AccessTokenStorage.listeners = [listener, ...AccessTokenStorage.listeners];
    return () => AccessTokenStorage.unsubscribe(listener);
  }

  private static get listeners() {
    return this.#listeners;
  }

  private static set listeners(listeners) {
    this.#listeners = listeners;
  }

  private static unsubscribe(listener: Listener) {
    AccessTokenStorage.listeners = AccessTokenStorage.listeners.filter((l) => l !== listener);
  }

  private static broadcastChanges(): void {
    AccessTokenStorage.listeners.forEach((listener) => listener());
  }
}
