import { toast } from '@remote-com/norma';
import { closeModal, decodedUrlParams } from 'react-url-modal';

import { BlockErrorBoundary } from '@/src/components/BlockErrorBoundary';
// eslint-disable-next-line import/no-cycle
import { captureException } from '@/src/helpers/captureException';

export const TOAST_ERROR_TITLE = `There was a problem displaying the requested information`;
export const TOAST_ERROR_DESCRIPTION = 'Please try again in a moment';

export function ModalErrorBoundary(props) {
  function handleError(error) {
    toast.error({
      title: TOAST_ERROR_TITLE,
      description: TOAST_ERROR_DESCRIPTION,
    });
    const dataPassed = JSON.stringify(decodedUrlParams());
    const message = `Modal ${props} failed to render, here is the data provided ${dataPassed}
    ${error}`;

    captureException(message);
    // remove the parameter from the URL
    closeModal();
  }

  // Do not render anything as the modal is immediately closed and toast message is
  // displayed instead.
  function ErrorComponent() {
    return null;
  }

  return (
    <BlockErrorBoundary handleError={handleError} ErrorComponent={ErrorComponent}>
      {props.children}
    </BlockErrorBoundary>
  );
}
