type Breakpoints = Array<string> & {
  small: string;
  medium: string;
  large: string;
  xlarge: string;
};

// These properties are mostly used in ./storybook/preview.js
export const breakpointProperties = {
  small: {
    id: 'small',
    name: 'Small Viewport',
  },
  medium: {
    id: 'medium',
    name: 'Medium Viewport',
  },
  large: {
    id: 'large',
    name: 'Large Viewport',
  },
  xlarge: {
    id: 'xlarge',
    name: 'XLarge Viewport',
  },
} as const;

/* eslint-disable prefer-destructuring */
// @ts-expect-error - There is no way to define "aliases" when defining the array, so we expect error and define "aliases" below.
export const breakpoints: Breakpoints = ['480px', '768px', '1024px', '1440px'];
// Breakpoint structure: 0 <----SM----> 480px <----MD----> 768px <----LG----> 1024px <----XL----> 1440px <--- 2XL --> 1440px+

// Note: We are exposing "aliases" in the breakpoints array. This allows for the following syntax using styled-system:
// <Box bg={{small: 'red', medium: 'blue', 'green'}} /> which can be useful.
// If we remove these aliases, we always need to use the array syntax for the responsive prop like bg={['red', 'blue', 'green']}
breakpoints[breakpointProperties.small.id] = breakpoints[0];
breakpoints[breakpointProperties.medium.id] = breakpoints[1];
breakpoints[breakpointProperties.large.id] = breakpoints[2];
breakpoints[breakpointProperties.xlarge.id] = breakpoints[3];

// Numeric values for breakpoints
const small = parseInt(breakpoints.small, 10);
const medium = parseInt(breakpoints.medium, 10);
const large = parseInt(breakpoints.large, 10);
const xlarge = parseInt(breakpoints.xlarge, 10);

export const breakpointValues = { small, medium, large, xlarge };
