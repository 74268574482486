import type { RumEvent, RumEventDomainContext, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';

interface Options extends RumInitConfiguration {
  startSessionReplayRecording: boolean;
}

export function setupDatadogRum(options: Options) {
  const {
    sessionSampleRate = 100,
    sessionReplaySampleRate = 100,
    trackUserInteractions = true,
    trackResources = true,
    trackLongTasks = true,
    trackFrustrations = true,
    defaultPrivacyLevel = 'mask',
    beforeSend = (event: RumEvent, context: RumEventDomainContext) => {
      // @ts-expect-error
      const errorCode = context?.error?.code;

      event.context = {
        ...event.context,
        ...(errorCode ? { errorCode } : {}),
      };
    },
    startSessionReplayRecording,
    ...others
  } = options;

  datadogRum.init({
    ...others,
    sessionSampleRate,
    sessionReplaySampleRate,
    trackUserInteractions,
    trackResources,
    trackLongTasks,
    trackFrustrations,
    defaultPrivacyLevel,
    beforeSend,
  });

  datadogRum.setRumGlobalContext({
    codeVersion: options.version,
  });

  if (startSessionReplayRecording) {
    datadogRum.startSessionReplayRecording();
  }
}
