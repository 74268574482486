/* eslint-disable import/no-extraneous-dependencies */
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import { createQueryKey } from '../utils';

const METHOD = 'GET';

export const usePrefetchQuery = () => {
  const queryClient = useQueryClient();
  const { createService } = useApiService();
  const prefetchQuery = useCallback(
    (path, config = { params: {}, options: {} }) => {
      const fnArgs = config.params;
      const queryKey = createQueryKey(path, fnArgs);

      return queryClient.prefetchQuery(
        queryKey,
        ({ signal }) => {
          const service = createService(path, METHOD);
          return service({ signal, ...fnArgs });
        },
        config.options
      );
    },
    [queryClient, createService]
  );

  return { prefetchQuery };
};
