import type { AxiosError, InternalAxiosRequestConfig } from 'axios';

export type OnAuthFailRequestInterceptorConfig = { skipCustomAuthInterceptor: boolean };

export const NAME = 'onAuthFailInterceptor';

export type OnAuthFailParams = {
  /**
   * Route to redirect to in case of an authentication error.
   */
  redirectTo: string | (() => string);
};

/**
 * @param url
 * @param currentPath
 */
export function getRedirectPathFromUrl(url: string, currentPath: string): string {
  const [redirectPathname, redirectQuery = ''] = url.split('?');
  const redirectQueryParams = new URLSearchParams(redirectQuery);

  redirectQueryParams.set('redirect', currentPath);

  return `${redirectPathname}?${redirectQueryParams.toString()}`;
}

/**
 * Axios interceptor to handle requests failed because of authentication.
 */
export function onAuthFail({ redirectTo }: OnAuthFailParams) {
  return async (
    error: AxiosError & {
      config: (InternalAxiosRequestConfig & OnAuthFailRequestInterceptorConfig) | undefined;
    }
  ) => {
    const { config: originalReq, response } = error || {};

    if (
      typeof window !== 'undefined' &&
      response?.status === 401 &&
      !originalReq?.skipCustomAuthInterceptor
    ) {
      // Determine the appropriate URL to redirect to
      const redirectUrl = typeof redirectTo === 'function' ? redirectTo() : redirectTo;
      const fullRedirectUrl = getRedirectPathFromUrl(redirectUrl, window.location.pathname);

      // If a 401 request occurs, redirect to the given url.
      window.location.href = fullRedirectUrl;

      // Simulate in progress request
      // Prevents error while redirecting
      return new Promise(() => {});
    }

    // proceed as normal
    return Promise.reject(error);
  };
}
