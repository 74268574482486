export const creditRiskStatuses = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  REFERRED: 'referred',
  FAIL: 'fail',
  NO_DEPOSIT_REQUIRED: 'no_deposit_required',
  DEPOSIT_REQUIRED: 'deposit_required',
};

export const creditRiskBlockedOnboardingStatuses = [
  creditRiskStatuses.REFERRED,
  creditRiskStatuses.FAIL,
  creditRiskStatuses.DEPOSIT_REQUIRED,
];

export const dunsStatus = /** @type {const} */ {
  INACTIVE: false,
  ACTIVE: true,
};
