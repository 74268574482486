import type { ElementType, FC } from 'react';
import { forwardRef } from 'react';
import type { RequireAtLeastOne } from 'type-fest';

import { Tooltip, type TooltipPosition, type TooltipType } from '../tooltip';

import { ButtonIconArea } from './Button.styled';
import type { PolymorphicComponent, PolymorphicComponentProps } from './types';

export type ButtonIconVariant = 'outline' | 'ghost' | 'solid';

interface BaseButtonIconProps {
  variant?: ButtonIconVariant;
  /** Defines the button color scheme */
  tone?: 'primary' | 'secondary' | 'destructive' | 'subtle';
  /** Defines the button padding and font-size */
  size?: 'sm' | 'xs' | 'md' | 'lg';
  /** The button accessible name */
  label?: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  Icon: ElementType;
  disabled?: boolean;
  /** Manually set a position for the button's tooltip (default is "top" like in the Tooltip component) */
  tooltipPosition?: TooltipPosition<TooltipType>;
  href?: string;
}

// Make sure that ButtonIcons have one of these props
export type ButtonIconProps = RequireAtLeastOne<
  BaseButtonIconProps,
  'aria-labelledby' | 'aria-label' | 'label'
>;

const ButtonIcon: PolymorphicComponent<'button', ButtonIconProps> = forwardRef(
  <C extends ElementType = 'button'>(
    {
      variant = 'outline',
      tone = 'primary',
      size = 'md',
      href,
      Icon,
      label,
      disabled,
      tooltipPosition,
      ...props
    }: PolymorphicComponentProps<C, ButtonIconProps>,
    ref?: PolymorphicComponentProps<C, ButtonIconProps>['ref']
  ) => {
    const ariaLabel = props['aria-label'] || label;

    // We force cast `as` to any to avoid Typescript errors due to `styled-components` trying to infer the rest of the HTML attributes.
    const elAttrs = href ? { href, as: 'a' as any } : { type: 'button' as const };

    const disabledProps = {
      'aria-disabled': 'true',
      onClick: (e: Event) => {
        // aria-disabled does not prevent interaction
        // as the disabled attr
        e.preventDefault();
      },
    };

    const disabledAttrs = disabled ? disabledProps : {};

    const ButtonIconComponent = (
      <ButtonIconArea
        ref={ref}
        $size={size}
        $variant={variant}
        $tone={tone}
        {...elAttrs}
        {...props}
        {...disabledAttrs}
        aria-label={ariaLabel}
      >
        <Icon width={size === 'xs' || size === 'sm' ? 16 : 20} />
      </ButtonIconArea>
    );

    return ariaLabel ? (
      <Tooltip
        label={label}
        position={tooltipPosition}
        type="caption"
        trigger="focus mouseenter"
        interactive={false} // Confusing for Screen Readers MR!22815
      >
        {ButtonIconComponent}
      </Tooltip>
    ) : (
      ButtonIconComponent
    );
  }
);

export const ButtonIconForStory = ButtonIcon as FC<ButtonIconProps>;

export default ButtonIcon;
