import camelcaseKeys from 'camelcase-keys';
import { isBefore, parseISO } from 'date-fns';
import type { Channel } from 'phoenix';
import { useContext, useEffect, useRef, useState } from 'react';

import { useGet } from '../../hooks/useGet';
import { WebSocketContext } from '../WebSocketProvider';

function checkHasTokenExpired(tokenExpiry: string) {
  if (!tokenExpiry) {
    return true;
  }

  const tokenExpiryDate = parseISO(tokenExpiry);
  const currentDate = new Date();
  return isBefore(tokenExpiryDate, currentDate);
}

const TIMEOUT_ERROR_MESSAGE =
  'useChannel has no websocket connection available after 10 seconds: channelName:';

const useChannel = <TChannelEventType extends Record<string, any>>(
  channelName: string,
  options: { enabled: boolean } = { enabled: true }
) => {
  const { socket, onError } = useContext(WebSocketContext);
  const [channel, setChannel] = useState<Channel>();

  const [eventSubscriptions, setEventSubscriptions] = useState(
    // We don't care what the response type is here, let's just match phoenix API
    new Map<string, (response?: any) => void | Promise<void>>()
  );

  const { data: sessionData, refetch } = useGet('/api/v1/session/ws/token', {
    options: {
      select: ({ data }) => data,
      enabled: options.enabled,
    },
  });

  const { token, expiresAt } = sessionData ?? {};
  const timeout = useRef<NodeJS.Timeout>();

  function startErrorTimeout() {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      const message = `${TIMEOUT_ERROR_MESSAGE} ${channelName}`;
      console.error(message);
      if (onError) {
        onError(new Error(message));
      }
    }, 10000);
  }

  /**
   * Setup a timeout that will log an error if the websocket
   * connection is not available after 10 seconds.
   *
   * When we join the channel, we will clear this timeout.
   *
   * This is the more reliable way of avoiding false positive datadog errors.
   */
  useEffect(() => {
    if (options.enabled) {
      startErrorTimeout();
    }

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (options.enabled) {
      const timeoutRef = timeout.current;

      if (!socket) {
        console.error('Websocket:: No connection available');
        return;
      }

      if (token) {
        const hasTokenExpired = expiresAt && checkHasTokenExpired(expiresAt);
        if (hasTokenExpired) {
          refetch();
          startErrorTimeout();
          return;
        }
        const phoenixChannel = socket.channel(channelName, { token });

        phoenixChannel
          ?.join()
          .receive('ok', () => {
            // Clear the timeout with the error message
            if (timeoutRef) clearTimeout(timeoutRef);
            // Immediately subscribe to existing event subscriptions
            eventSubscriptions.forEach((callback, event) => {
              phoenixChannel.on(event, callback);
            });
            setChannel(phoenixChannel);
          })
          .receive('error', (exception) => {
            const { reason } = exception;
            const errorMessage = `Websocket:: Error connecting to channel: ${channelName}, ${reason}`;
            console.error(errorMessage);
            const error = {
              ...exception,
              message: errorMessage,
            };
            if (onError) {
              onError(error);
            }
          });

        return () => {
          eventSubscriptions.forEach((_, id) => {
            phoenixChannel?.off(id);
          });
          phoenixChannel?.leave();
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, token]);

  /**
   * Subscribe to channel message
   * @param event
   * @param callback
   */
  function subscribe<TEvent extends keyof TChannelEventType>(
    event: TEvent,
    callback: (response: TChannelEventType[TEvent]) => void | Promise<void>
  ) {
    // Convert payload to camelcase
    const wrappedCallback: typeof callback = (response) => {
      if (response.data) {
        return callback({ ...response, data: camelcaseKeys(response.data, { deep: true }) });
      }

      // Just in case data does not exists as we're not enforcing websockets data types to have this format
      return callback({ ...response, data: camelcaseKeys(response, { deep: true }) });
    };

    // The reason a channel can be undefined is that we might be attempting to subscribe to an event,
    // but we have not yet received the confirmation message ("ok") from the server.
    if (channel) {
      channel.on(event as string, wrappedCallback);
    }

    setEventSubscriptions(eventSubscriptions.set(event as string, wrappedCallback));
  }

  /**
   * Manually unsubscribe from message. This shouldn't be necessary to call as useEffect already unbind messages when a component unmounts.
   * @param event
   */
  function unsubscribe<TEvent extends keyof TChannelEventType>(event: TEvent) {
    channel?.off(event as string);
    eventSubscriptions.delete(event as string);
    setEventSubscriptions(new Map(eventSubscriptions));
  }

  return { subscribe, unsubscribe, channel };
};

export { useChannel };
