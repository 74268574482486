import { transparentize } from 'polished';
import { css } from 'styled-components';

/* [1] outline transparent ensures focus is visible in Windows High Contrast
  as the box-shadow is ignored by default.
  https://blogs.windows.com/msedgedev/2020/09/17/styling-for-windows-high-contrast-with-new-standards-for-forced-colors/
*/

export const focusRingBorderCSS = (colorMain) => css`
  border-color: ${colorMain};
  box-shadow: 0 0 0 3px ${transparentize(0.5, colorMain)};
  outline: 1px solid transparent; /* [1] */
  outline: 1px solid ${colorMain};
  outline-offset: -1px;
`;

export const focusRingOffsetBorder = (colorMain, colorBg = 'var(--colors-blank)') => css`
  border-color: ${colorMain};
  box-shadow: 0 0 0 3px ${colorBg}, 0 0 0 5px ${colorMain};
  outline: 1px solid transparent; /* [1] */
`;

export function focusVisible(rules) {
  return css`
    .js-focus-visible &:focus {
      outline: none;
    }

    &:focus-visible {
      ${rules}
    }

    &:focus.focus-visible {
      ${rules}
    }
  `;
}
