/* eslint-disable import/no-extraneous-dependencies */
import qs from 'qs';

/**
 * Parses the query string to a query object.
 * @param {string} url
 */
export function parseQueryFromUrl(url = '', options = {}) {
  // By default, arrayLimit is 20, but in our codebase we want it to be Infinity as the backend always expects the array format.
  return qs.parse(url.split('?')[1], { arrayLimit: Infinity, ...options });
}

/**
 * Stringifies the query object to a query string.
 * @param {object} query
 */
export function stringifyTableQuery(
  query,
  { skipNulls = false, skipEmptyStrings = false, skipCustomMatcher = false } = {}
) {
  const hasFilters = skipEmptyStrings || skipCustomMatcher;

  const pickDeep = (object) => {
    const isObject = (value) => typeof value === 'object' && value !== null;

    const deepPick = (obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (isObject(value)) {
          const deepPicked = deepPick(value);
          return { ...acc, [key]: deepPicked };
        }

        const isEmpty = skipEmptyStrings && value === '';
        const isCustom = skipCustomMatcher && skipCustomMatcher(key, value);
        return isEmpty || isCustom ? acc : { ...acc, [key]: value };
      }, {});
    };

    return deepPick(object);
  };

  const filteredQuery = hasFilters ? pickDeep(query) : query;

  return qs.stringify(filteredQuery, {
    skipNulls,
  });
}
