import Cookies from 'js-cookie';

// In sync with gaia and the blog
const cookieName = 'remote_utm_query_string';

export function getUtm(windowLocation = window.location) {
  const utmCookie = Cookies.get(cookieName) || '';

  const utm = utmCookie.length
    ? `${windowLocation.origin}${windowLocation.pathname}${utmCookie}`
    : windowLocation.href;

  return utm;
}

export function hasWindow() {
  return typeof window !== 'undefined';
}

export function getCompanyIdEventValue(companyId) {
  return companyId || undefined;
}
