import { useQuery, useQueryClient } from 'react-query';

import { MAX_FILE_SIZE_BYTES } from '@/src/domains/files/constants';
import { fetchUploadConfig } from '@/src/domains/files/services';

export const FETCH_UPLOAD_QUERY = 'fetchUploadConfig';

export const useUploadConfig = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(FETCH_UPLOAD_QUERY)?.data;
  const { data: queryConfig } = useQuery(FETCH_UPLOAD_QUERY, fetchUploadConfig, {
    enabled: !data,
  });

  if (data) return data;

  return queryConfig || {};
};

// UploadConfig is called from _app.js and hooks can't be called in a class component
export function UploadConfig({ user }) {
  useQuery(FETCH_UPLOAD_QUERY, fetchUploadConfig, { enabled: !!user });
  return null;
}

export const useUploadConfigByDocumentType = (documentType) => {
  const config = useUploadConfig();
  const uploadConfig = config[documentType];

  return {
    extensions: uploadConfig?.extensions || [],
    maxSize: uploadConfig?.max_size || MAX_FILE_SIZE_BYTES,
  };
};
