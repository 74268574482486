import type { AvatarTone } from '@remote-com/norma';
import { createContext, useContext } from 'react';

import type { API } from '@/src/api/config/api.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import { isAdmin, isEmployer } from '@/src/domains/registration/auth/helpers';

export type Login = {
  loginWithPassword: API.LoginAccountResponse['data']['logins'] | undefined;
  loginWithSSO: API.LoginAccountResponse['data']['logins'] | undefined;
};

export type UserContextUser = UserAccountResponse & {
  initialsColor?: AvatarTone;
  isOnMacOS?: boolean;
};

const UserContext = createContext<{
  user?: UserContextUser;
  loginMethods?: Login;
  refreshUser?: () => void;
}>({
  user: undefined,
});

export const useUser = () => {
  const { user, loginMethods, refreshUser } = useContext(UserContext);
  const userIsAdmin = isAdmin(user);
  const userIsEmployer = isEmployer(user);
  return { user, userIsAdmin, userIsEmployer, loginMethods, refreshUser };
};

export default UserContext;
