import { useMutation } from 'react-query';

import { BROADCAST_MESSAGE } from '@/src/domains/broadcast/constants';
import { postMessage } from '@/src/domains/broadcast/postMessage';
import { logoutSprigUser } from '@/src/domains/sprig/Sprig';
import { deleteSession } from '@/src/services/User';

export const useSignOut = ({ reason } = {}) => {
  const { mutate } = useMutation(deleteSession, {
    onSuccess: () => {
      if (reason === 'timeout') {
        postMessage(BROADCAST_MESSAGE.signOutTimeout);
      } else {
        postMessage(BROADCAST_MESSAGE.signOut);
      }
      logoutSprigUser();
    },
  });
  return mutate;
};
