import type { AvatarTone } from './Avatar';

export const profileInitialsColors: AvatarTone[] = [
  { background: 'orange.200', text: 'orange.800' },
  { background: 'purple.200', text: 'purple.700' },
  { background: 'green.200', text: 'green.800' },
  { background: 'brand.100', text: 'brand.700' },
  { background: 'pink.200', text: 'pink.800' },
  { background: 'greenLight.200', text: 'greenLight.800' },
  { background: 'cyan.200', text: 'cyan.800' },
  { background: 'fuchsia.200', text: 'fuchsia.800' },
  { background: 'grey.200', text: 'grey.600' },
  { background: 'yellow.200', text: 'yellow.800' },
];

/**
 * Sum the UTF-16 code unit of a given string
 */
function sumChars(string: string): number {
  let sum = 0;

  for (let i = 0; i < string.length; i++) {
    sum += string.charCodeAt(i);
  }

  return sum;
}

/**
 * Sum the UTF-16 code unit and get the reminder of the division by the profile colors list length
 */
function getProfileColorIndex(name: string): number {
  return sumChars(name) % profileInitialsColors.length;
}

export function getRandomColorForInitialsImage(name: string): AvatarTone {
  const index = name ? getProfileColorIndex(name) : 0;

  return profileInitialsColors[index];
}

export function getFormattedName(name: string): string {
  /* initials library considers that anything inside parenthesis
     represents the preferred initials (see https://github.com/gr2m/initials#notes) */
  const cleanName = name.replace(/[()]/g, '');

  const userName = cleanName.split(' ');

  return userName.length > 2 ? `${userName.shift()} ${userName.pop()}` : cleanName;
}
