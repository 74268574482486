import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';

import { Box } from './Box';

const LayoutContainer = styled(Box)(
  system({
    numberOfColumns: {
      property: 'gridTemplateColumns',
      transform: (rawValue) => (rawValue ? `repeat(${rawValue}, 1fr)` : null),
    },
  })
);

type ColumnsProps = {
  gap?: number | number[];
  mx?: number | (number | null)[];
  numberOfColumns?: number | number[];
  rowGap?: number | number[];
  [key: string]: any;
};

export const Columns = ({
  children,
  gap: gridColumnGap = 4,
  rowGap: gridRowGap = 4,
  mx = [6, null, null, 7, 8],
  numberOfColumns = [2, 4, 6, 8, 12],
  ...props
}: PropsWithChildren<ColumnsProps>) => {
  return (
    <LayoutContainer
      display="grid"
      gridColumnGap={gridColumnGap}
      gridRowGap={gridRowGap || gridColumnGap}
      // @ts-expect-error
      numberOfColumns={numberOfColumns}
      mx={mx}
      {...props}
    >
      {children}
    </LayoutContainer>
  );
};

type ColumnProps = {
  end?: number | number[];
  span?: number | number[];
  start?: number | number[];
};

export const Column = styled(Box)<ColumnProps>(
  system({
    start: {
      property: 'gridColumnStart',
      transform: (rawValue) => (rawValue ? `${rawValue}` : null),
    },
    end: {
      property: 'gridColumnEnd',
      transform: (rawValue) => (rawValue ? `${rawValue}` : null),
    },
    span: {
      property: 'gridColumn',
      transform: (rawValue) => `auto / span ${rawValue || 2}`,
    },
  })
);
