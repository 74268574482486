import type { ReactElement, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { error } from '@/src/helpers/general';

import type { FeatureFlagConfig, FeatureFlagName, FeatureFlagUserAttributes } from './config';
import type { FeatureFlagUserEnabledOptions } from './user';
import { FeatureFlagUser } from './user';

/**
 * @private
 *
 * Context to make the user-specific feature flags available across the app.
 * This is more like a React wrapper of a library:
 * - For user-agnostic configs, see "./config"
 * - For user-specific decisions, see "./user"
 */
const Context = createContext<FeatureFlagUser | null>(null);

/**
 * @private
 * Evaluates if a feature flag is enabled for the current user, based on the current context
 */
function isFlagEnabled(
  context: FeatureFlagUser | null,
  name: FeatureFlagName,
  options?: FeatureFlagUserEnabledOptions
): boolean {
  if (context === null) {
    error('Missing feature flag provider');
    return false;
  }
  return context.isFlagEnabled(name, options);
}

/**
 * @public
 *
 * Returns whether a feature flag is enabled for the current user.
 * This supports both the old (legacy flags from next.js's public runtime config)
 * and new (full-stack flags from the server) systems.
 */
export function useIsFeatureFlagEnabled(
  name: FeatureFlagName,
  options?: FeatureFlagUserEnabledOptions
): boolean {
  const context = useContext(Context);

  // Inside the useIsFeatureFlagEnabled function
  return isFlagEnabled(context, name, options);
}

/**
 * @public
 * @generator
 * Hook that returns a function for checking if a feature flag is enabled for the current user.
 * Please note: This is not usually needed, favor using "useIsFeatureFlagEnabled" directly.
 */
export function useIsFeatureFlagEnabledFactory(): (
  name: FeatureFlagName,
  options?: FeatureFlagUserEnabledOptions
) => boolean {
  const context = useContext(Context);

  return (name) => isFlagEnabled(context, name);
}

export interface FeatureFlagUserProviderProps {
  children: ReactNode;
  /**
   * User identifier
   */
  userId: string | null;
  /**
   * User attributes to be used for audience targeting
   */
  attributes?: FeatureFlagUserAttributes;
  /**
   * Feature flag configuration data
   */
  config: FeatureFlagConfig;
}

/**
 * @private
 *
 * Provider to make the user's feature flag available across the app.
 * It initializes the class with the same config for all users and all environments.
 * It's required for "useIsFeatureFlagEnabled" to work.
 *
 * It's likely most developers will not need to use this directly.
 * It's already used in the "App" component to wrap the whole app.
 */
export function FeatureFlagProvider(props: FeatureFlagUserProviderProps): ReactElement {
  const { children, userId, config, attributes } = props;
  const [host, setHost] = useState<FeatureFlagUser>(() => {
    return new FeatureFlagUser({ config, userId, attributes });
  });

  // Update the host if userId changes
  useEffect(() => {
    if (userId !== host.userId) {
      setHost(new FeatureFlagUser({ config, userId, attributes }));
    }
  }, [config, userId, attributes, host.userId]);

  return <Context.Provider value={host}>{children}</Context.Provider>;
}
