import { convertFileMbToBytes as convertMbToBytes } from './helpers';

export const FILE_PDF_ONLY_FORMAT = 'application/pdf';

export const FILE_WORD_DOCUMENTS = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const FILE_DOCUMENT_SUPPORTED_FORMATS = [FILE_PDF_ONLY_FORMAT, ...FILE_WORD_DOCUMENTS];

export const FILE_WORD_DOCUMENTS_EXTENSIONS = {
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
};

export const FILE_SPREADSHEET_EXTENSIONS = {
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
};

// Due to its usages, this record type is actually better than "as const"
export const FILE_HUMAN_READABLE_FORMATS: Record<string, string | undefined> = {
  ...FILE_SPREADSHEET_EXTENSIONS,
  ...FILE_WORD_DOCUMENTS_EXTENSIONS,
  'application/pdf': '.pdf',
  'image/jpg': '.jpg',
  'image/jpeg': '.jpg',
  'image/png': '.png',
};

export const FILE_MAX_SIZE_MB = 20;

export const FILE_MAX_SIZE_BYTES = convertMbToBytes(FILE_MAX_SIZE_MB);
