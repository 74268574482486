import Head from 'next/head';

function composeSiteTitle({ title, template }: { title: string; template: string }) {
  if (!title) {
    return 'Remote';
  }

  return template.replace('%s', title);
}

type Props = {
  children: string;
  template?: string;
};

export default function SiteTitle({ children: title, template = '%s | Remote' }: Props) {
  const composedSiteTitle = composeSiteTitle({ title, template });

  return (
    <Head>
      <title key="page-title">{composedSiteTitle}</title>
    </Head>
  );
}
