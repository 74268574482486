import { useState } from 'react';

import { getExceptionInfo } from '@/src/helpers/general';

const initialState = {
  errorTitle: null,
  errorMessage: null,
  successMessage: null,
  successDescription: null,
};

type CustomState = {
  errorTitle: string | null;
  errorMessage: string | null;
  successMessage: string | null;
  successDescription: string | null;
};

// `PartialMutationResult` is used instead of `UseMutationResult` from 'react-query's own
// types to allow wrapping `useMutationWithCustomState` around the object produced by
// `useMutationWithDefaultParams`. The latter's `mutate` and `mutateAsync` methods are
// not compatible with those of `UseMutationResult<TData, TError, TVariables, TContext>`
// type, because the variables argument is a partial of `TVariables`, while the options
// argument gets the full, non-partial `TVariables` type.
type PartialMutationResult<TError> = {
  error: TError | null;
  reset: () => void;
  isError: boolean;
  isSuccess: boolean;
};

export const useMutationWithCustomState = <
  TError,
  TMutationResult extends PartialMutationResult<TError>
>(
  mutation: TMutationResult
): TMutationResult & {
  customState: CustomState;
  customReset: () => void;
  setCustomState: (customState: Partial<CustomState>) => void;
} => {
  const [customState, setCustomState] = useState<CustomState>(initialState);

  const handleSetCustomState = (newState = {}) => {
    setCustomState((oldState) => ({
      ...oldState,
      ...newState,
    }));
  };

  const handleReset = () => {
    mutation.reset();
    setCustomState(initialState);
  };

  const getErrorMessage = () =>
    customState.errorMessage ? customState.errorMessage : getExceptionInfo(mutation.error)?.message;

  return {
    ...mutation,
    customState: {
      // Use mutation statuses to derived success/error messages.
      errorTitle: mutation.isError ? customState.errorTitle : null,
      errorMessage: mutation.isError ? getErrorMessage() : null,
      successMessage: mutation.isSuccess ? customState.successMessage : null,
      successDescription: mutation.isSuccess ? customState.successDescription : null,
    },
    customReset: handleReset,
    setCustomState: handleSetCustomState,
  };
};
