import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  #__next,
  body {
    height: 100%;
  }

  /* VWO triggers a blank page when it loads, causing flicker. This overrides their styles */
  html body {
    opacity: 1 !important;
    filter: none ! important;
  }

  #__next {
    /* Reach ui modal appears as a sibling in the DOM so creating a separate stacking context
     will allow the modal always to appear on the top of the elements within the app.
     */
    isolation: isolate;
  }

  button,
  a {
    cursor: pointer;
  }

  button:disabled,
  button:disabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }

  b, strong {
    font-weight: 600 !important; /* to bypass react-hot-toast overrides */
  }

  /* ---- RCP-related styles ---- */
  body.rcp-active {
    display: flex;
  }

  body.rcp-active.vertical {
    flex-direction: column-reverse;
  }

  body.rcp-active.vertical > #__next {
    width: 100% !important;
  }

  body.rcp-active.vertical #remote-control-panel {
    width: 100% !important;
  }
  body.rcp-active > div{
    flex: 1 1 auto;
  }

  /* Radix dropdown Portal, to be higher than RemoteControlPanel.jsx */
  [data-radix-popper-content-wrapper] { 
    z-index: 1000001 !important; 
  }

  /* Bookit popup, enables popup to be clickable when called from a modal */
  .bookit-popup {
    pointer-events: auto !important;
  }
`;

export default GlobalStyle;
