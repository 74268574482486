import type { AxiosResponse } from 'axios';
import { AxiosHeaders } from 'axios';

import { AccessTokenStorage } from './AccessTokenStorage';

export const NAME = 'onRefreshHintInterceptor';

const refreshHintHeader = 'x-remote-jwt-refresh-hint';

/**
 * Axios interceptor to handle responses with refresh hint.
 */
export async function onRefreshHint(response: AxiosResponse) {
  if (
    (response?.headers instanceof AxiosHeaders && response.headers.has(refreshHintHeader)) ||
    Object.prototype.hasOwnProperty.call(response.headers, refreshHintHeader)
  ) {
    await AccessTokenStorage.refresh();
  }

  return response;
}
