import { getCompanyIdEventValue } from '../helpers';

import { trackPageView } from './methods';

export function useTrackPageView({ user }) {
  return (url, props = {}) => {
    // Filter PII in the page title for some specific pages
    const subpaths = [
      '/companies/',
      'dashboard/company',
      'dashboard/company-settings',
      '/people/',
      '/users/',
    ];
    const hasTitlePii = subpaths.some((subpath) => url.includes(subpath));

    const properties = hasTitlePii ? { title: 'PII', ...props } : props;

    trackPageView({
      properties: { companyID: getCompanyIdEventValue(user?.companySlug), ...properties },
    });
  };
}
