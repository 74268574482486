// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconAlertTriangle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M10.53 2.28a3 3 0 0 1 4.03 1.06l8.48 14.15v.01a3 3 0 0 1-2.56 4.5H3.52a3 3 0 0 1-2.57-4.5l.01-.01L9.43 3.35l.86.51-.86-.52a3 3 0 0 1 1.1-1.06Zm.62 2.1L2.68 18.5A1 1 0 0 0 3.54 20h16.92a1 1 0 0 0 .86-1.5L12.85 4.39a1 1 0 0 0-1.7 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z" /></svg>;
const ForwardRef = forwardRef(IconAlertTriangle);
export { ForwardRef as IconAlertTriangle };