import defaultToast from 'react-hot-toast';
import type {
  Toast as ToastProps,
  Renderable,
  ValueOrFunction,
  ToastOptions,
} from 'react-hot-toast';

import { IconV2DuotoneCheckCircle } from '../../icons/build/IconV2DuotoneCheckCircle';
import { IconV2DuotoneTimesFull } from '../../icons/build/IconV2DuotoneTimesFull';
import { IconV2OutlineTimes } from '../../icons/build/IconV2OutlineTimes';
import { Box } from '../../layout';
import { Spinner } from '../spinner';

import { ButtonClose, ToastTitle } from './Toast.styled';

type Messages = {
  loading: Renderable;
  success: ValueOrFunction<Renderable, any>;
  error: ValueOrFunction<Renderable, any>;
};
type JSXFactoryFn = () => JSX.Element;

/**
 * Toast content - The content of the toast, allows text, function that returns JSX or an object with title and description
 */
type ToastContent = { title: string; description?: string | JSX.Element } | string | JSXFactoryFn;

const toastContent = (toast: ToastProps, content: ToastContent): Renderable => {
  let body: JSX.Element;

  if (typeof content === 'function') {
    body = content();
  } else if (typeof content === 'object' && (content?.title || content?.description)) {
    body = (
      <>
        {content.title && <ToastTitle>{content.title}</ToastTitle>}
        {content.description}
      </>
    );
  } else {
    // strict string handling
    body = <>{content}</>;
  }

  return (
    <>
      {body}
      <ButtonClose aria-label="Close message" onClick={() => defaultToast.dismiss(toast.id)}>
        <IconV2OutlineTimes width={14} height={14} />
      </ButtonClose>
    </>
  );
};

const position = 'bottom-left';
const duration = 9000; // The duration of the Toast is 9 seconds (more info here: https://www.figma.com/file/a1VDZJlgx8djzj1lqTfdrK?node-id=12153:264516#380771764)
const className = 'toast';
const SIZE = {
  width: 21.5,
  height: 21.5,
};

const CONTENT = {
  SUCCESS: {
    icon: <IconV2DuotoneCheckCircle {...SIZE} aria-label="Success:" />,
    className: `${className} ${className}-success`,
  },
  ERROR: {
    icon: <IconV2DuotoneTimesFull {...SIZE} aria-label="Error:" />,
    className: `${className} ${className}-error`,
  },
  LOADING: {
    icon: (
      <Box mt={1}>
        <Spinner aria-label="Loading" color="brand.500" size={18} />
      </Box>
    ),
    className: `${className} ${className}-loading`,
  },
};

type Toast = {
  dismiss: typeof defaultToast.dismiss;
  remove: typeof defaultToast.remove;
  loading: (content: ToastContent, options?: ToastOptions) => string;
  success: (content: ToastContent, options?: ToastOptions) => string;
  error: (content: ToastContent, options?: ToastOptions) => string;
  promise: <T>(promiseFn: Promise<T>, messages: Messages, options?: ToastOptions) => Promise<T>;
};

export const toast: Toast = {
  /**
   * Dismiss toast by passing toast ID. Passing nothing dismisses all active toasts
   */
  dismiss: defaultToast.dismiss,

  /**
   * Remove toast by passing toast ID, like dismiss but no animations are run. Passing nothing dismisses all active toasts
   */
  remove: defaultToast.remove,

  /**
   * Display loading toast passing optional options
   */
  loading: (content, options = {}) =>
    defaultToast((t) => toastContent(t, content), {
      duration,
      position,
      ...CONTENT.LOADING,
      ...options,
    }),

  /**
   * Display success toast passing optional options
   */
  success: (content, options = {}) =>
    defaultToast((t) => toastContent(t, content), {
      duration,
      position,
      ...CONTENT.SUCCESS,
      ...options,
    }),

  /**
   * Display error toast passing optional options
   */
  error: (content, options = {}) =>
    defaultToast((t) => toastContent(t, content), {
      duration,
      position,
      ...CONTENT.ERROR,
      ...options,
    }),

  promise: (promiseFn, { loading, success, error }, options) =>
    defaultToast.promise(
      promiseFn,
      {
        // @ts-expect-error TODO: Promise wrapper needs refactoring because we are currently using this library not according to its types or even examples in the docs. Our implementation works but it needs to be addressed before something breaks with future lib versions
        loading: (t: Toast) => toastContent(t, loading),
        // @ts-expect-error
        success: () => (t: Toast) => toastContent(t, success),
        // @ts-expect-error
        error: (e) => (t: Toast) => {
          if (typeof error === 'function') {
            // @ts-expect-error
            return toastContent(t, error(e));
          }
          // @ts-expect-error
          return toastContent(t, error);
        },
      },
      {
        position,
        className,
        success: CONTENT.SUCCESS,
        loading: CONTENT.LOADING,
        error: CONTENT.ERROR,
        ...options,
      }
    ),
};

export const {
  success: successToast,
  error: errorToast,
  promise: promiseToast,
  dismiss,
  remove,
} = toast;
