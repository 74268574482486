import { useGet } from '@remote-com/data-layer';
import { Tooltip } from '@remote-com/norma';
import dynamic from 'next/dynamic';
import type { ReactNode } from 'react';
import { cloneElement, isValidElement } from 'react';

import type { API } from '@/src/api/config/api.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import type { Permissions } from '@/src/domains/registration/auth/constants/permissions';
import type {
  AdminPermissionName,
  EmployerPermissionName,
} from '@/src/domains/registration/auth/helpers';
import { isRolesPermissionsDebuggerEnabled } from '@/src/domains/remoteControlPanel/tools/roles-permissions-debugger/utils';
import {
  customerPermissionsDictionary,
  permissionsDictionary,
} from '@/src/domains/rolesPermissions/permissionsTranslator';

import { useUser } from '../UserProvider/context';

import useUserCan from './useUserCan';

export const userCanOperators = {
  SOME: 'SOME',
  ALL: 'ALL',
} as const;

export type UserCanOperators = keyof typeof userCanOperators;

type AccessResult = {
  name: string;
  accessLevel: string;
  hasAccess: boolean;
  uiPermission: string[];
};

type FunctionChildren = (props: { hasAccess: boolean; results: AccessResult[] }) => ReactNode;

type MapAdminPermissionNameToRule<TPermissionName> = TPermissionName extends AdminPermissionName
  ? [keyof Permissions[TPermissionName], TPermissionName]
  : never;
type MapEmployerPermissionNameToRule<TPermissionName> =
  TPermissionName extends EmployerPermissionName
    ? [keyof Permissions['employer'][TPermissionName], TPermissionName]
    : never;
export type PermissionRule =
  | MapAdminPermissionNameToRule<AdminPermissionName>
  | MapEmployerPermissionNameToRule<EmployerPermissionName>;
type PermissionsProps = Array<PermissionRule>;

type TUserCan = (params: {
  permissions?: PermissionsProps;
  user?: UserAccountResponse;
  children: ReactNode | FunctionChildren;
  additionalCondition?: boolean;
  operator?: UserCanOperators;
}) => JSX.Element;

const TooltipContent = dynamic(
  () =>
    import('@/src/domains/remoteControlPanel/tools/roles-permissions-debugger/TooltipContent').then(
      (mod) => mod.TooltipContent
    ),
  { ssr: false }
);

const usePermissions = () => {
  const { userIsAdmin } = useUser();
  const { data: adminPermissions } = useGet('/api/v1/rivendell/permissions', {
    options: {
      select: ({ data }) =>
        Object.assign({}, ...data) as API.Rivendell.PermissionsResponse['data'][number],
      enabled: isRolesPermissionsDebuggerEnabled() && userIsAdmin,
    },
  });
  const { data: employerPermissions } = useGet('/api/v1/employer/permissions', {
    options: {
      select: ({ data }) => {
        return Object.assign({}, ...data) as API.Employer.PermissionsResponse['data'][number];
      },
      enabled: isRolesPermissionsDebuggerEnabled() && !userIsAdmin,
    },
  });

  function getUIPermissions(apiPermission: string) {
    const result: string[] = [];
    Object.values(adminPermissions || employerPermissions || {})
      .flat()
      .forEach(({ dependencies, id }) => {
        if (dependencies[apiPermission]) {
          result.push(
            userIsAdmin
              ? permissionsDictionary[id]?.label
              : customerPermissionsDictionary[id]?.label
          );
        }
      });
    return result;
  }

  return {
    getUIPermissions,
  };
};

const UserCan: TUserCan = ({
  user,
  children,
  permissions = [],
  additionalCondition = true,
  operator = userCanOperators.ALL,
  ...props
}) => {
  const { getUIPermissions } = usePermissions();

  const { userCan } = useUserCan();
  const isValidPermissionsArray = Array.isArray(permissions) && !!permissions.length;

  const results: AccessResult[] = isValidPermissionsArray
    ? permissions.map(([accessLevel, name]) => ({
        name,
        accessLevel,
        hasAccess: userCan(accessLevel, name, user),
        uiPermission: isRolesPermissionsDebuggerEnabled()
          ? getUIPermissions(name.replaceAll('_', '-'))
          : [],
      }))
    : [];

  function hasPermissions() {
    if (operator === userCanOperators.ALL) {
      return results.every(({ hasAccess }) => hasAccess);
    }
    if (operator === userCanOperators.SOME) {
      return results.some(({ hasAccess }) => hasAccess);
    }

    console.error(
      `Unknown operator ${operator}. Available options are 'ALL' or 'SOME'. Hiding children.`
    );
    return false;
  }

  const userCanPerformOperation = hasPermissions();

  function forbidAccess() {
    if (typeof children === 'function') {
      return children({ hasAccess: false, results });
    }
    return null;
  }

  function grantAccess() {
    if (typeof children === 'function') {
      return children({ hasAccess: true, results });
    }

    if (isRolesPermissionsDebuggerEnabled()) {
      return (
        <Tooltip
          label={
            isValidPermissionsArray ? (
              <TooltipContent results={results} />
            ) : (
              'Invalid permissions array'
            )
          }
          animationDelay={0}
        >
          {isValidElement(children) ? (
            cloneElement(
              children as React.ReactElement<{
                [x: string]: unknown;
                style: Record<string, string>;
              }>,
              {
                ...props,
                style: { border: '1px solid #D03765' },
              }
            )
          ) : (
            <>{children}</>
          )}
        </Tooltip>
      );
    }

    return isValidElement(children) ? cloneElement(children, { ...props }) : children;
  }

  if (userCanPerformOperation) {
    if (!additionalCondition) {
      return forbidAccess();
    }
    return grantAccess();
  }
  return forbidAccess();
};

export default UserCan;
