export const termsOfServiceLocation = {
  SIGNUP: 'signup',
  OFF_PLATFORM: 'off_platform',
};

export const termsOfServiceLocationLabels = {
  [termsOfServiceLocation.SIGNUP]: 'On platform',
  [termsOfServiceLocation.OFF_PLATFORM]: 'Off platform',
};

export const TERMS_OF_SERVICE_FIELD_NAME = 'tosAcceptDate';
