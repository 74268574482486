import type * as CSS from 'csstype';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components';

import { textVariants } from './textVariants';

export interface TypographyProperties {
  fontFamily?: CSS.Property.FontFamily;
  lineHeight?: CSS.Property.LineHeight;
  fontWeight?: CSS.Property.FontWeight;
  fontSize?: CSS.Property.FontSize;
  letterSpacing?: CSS.Property.LetterSpacing;
}

function getTextStyles({
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
  letterSpacing,
}: TypographyProperties): FlattenSimpleInterpolation {
  return css`
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: ${lineHeight};
    letter-spacing: ${letterSpacing};
    font-family: ${fontFamily};
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  `;
}

export interface FormVariant {
  inputLineHeight?: CSS.Property.LineHeight;
  inputFontWeight?: CSS.Property.FontWeight;
  inputFontSize?: CSS.Property.FontSize;
  inputLetterSpacing?: CSS.Property.LetterSpacing;
  inputLineHeightSmall?: CSS.Property.LineHeight;
}

type Variants = 'mono' | 'brand' | keyof typeof textVariants.variants;

type Typography = {
  // eslint-disable-next-line no-unused-vars
  [k in Variants]: FlattenSimpleInterpolation;
} & {
  forms: FormVariant;
};

// merge textVariants from v1 and v2
const textVariantsWithV1Compatibilities = {
  variants: textVariants.variants,
};

const typography = Object.entries(textVariantsWithV1Compatibilities.variants).reduce(
  (variants, [variantName, variantValue]) => ({
    ...variants,
    [variantName]: getTextStyles(variantValue as TypographyProperties),
  }),
  {
    // TODO: Remove this forms object since it's only used in a few places and it does not make sense to have it in Typography v2
    forms: {
      inputFontWeight: 500,
      inputFontSize: '0.875rem',
      inputLineHeight: 1.5,
      inputLineHeightSmall: 1.1875,
    },
  } as Typography
);

typography.mono = css`
  font-family: var(--typography-fontMono);
`;

typography.brand = css`
  font-family: var(--typography-fontBrand);
`;

export { typography, textVariantsWithV1Compatibilities as textVariants };
