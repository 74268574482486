// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';
import type { UseQueryOptions, UseQueryResult } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import type { GetRequest, GetResponse, GetPath } from '../GET.types';
import { createQueryKey } from '../utils';

const METHOD = 'GET';

export type UseGetOptions<KPath extends GetPath, TError = unknown, TDataVal = {}> = Omit<
  UseQueryOptions<GetResponse<KPath>, TError, TDataVal, any>,
  'queryKey' | 'queryFn'
>;

export function useGet<
  KPath extends GetPath,
  TError = unknown,
  TData = undefined,
  TDataVal = TData extends {} ? TData : undefined
>(
  path: KPath,
  config?: {
    params?: GetRequest<KPath>;
    options?: UseGetOptions<KPath, TError, TDataVal extends undefined ? GetResponse<KPath> : TData>;
  }
): UseQueryResult<TDataVal extends undefined ? GetResponse<KPath> : TData, TError> {
  const { createService } = useApiService();
  const service = createService(path, METHOD);
  const { options, params: fnArgs } = config || {};

  const queryKey = createQueryKey(path, fnArgs as any);

  return useQuery(queryKey, ({ signal }) => service({ signal, ...(fnArgs as any) }), options);
}
