import pickBy from 'lodash/pickBy';
// qs is a well tested small 3kb lib that supports parsing to/from nested objects, arrays and other features
import qs from 'qs';

/**
 * Query decoder that can be used option for parseQueryFromUrl.
 * @param {String} value - Value that can be decoded
 * @param {Function} defaultDecoder
 * @param {String} _charset
 * @param {String} type - Type can be "key" or "value"
 */
export function standardQueryDecoder(value, defaultDecoder, _charset, type) {
  if (type === 'key') {
    // For keys, we want to use a standard decoder to handle different array formats
    return defaultDecoder(value);
  }
  if (type === 'value') {
    // Solution reference:
    // https://github.com/ljharb/qs/issues/91#issuecomment-348481496

    // If there's no fraction part, then make sure it doesn't start with 0.
    // We want to preserve leading 0s as invoice numbers can start with 0.
    // See https://linear.app/remote/issue/BIL-364/first-digit-truncation-when-going-back-while-searching-by-invoice
    if (/^([0]|[1-9]\d*|\d*\.\d+)$/.test(value)) {
      return parseFloat(value);
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };

    if (value in keywords) {
      return keywords[value];
    }

    const sanitizedValue = value.replace(/\+/g, ' ');

    return decodeURIComponent(sanitizedValue);
  }

  return value;
}

/**
 * Parses the query string to a query object.
 * @param {string} url - the url with the query string to parse
 * @param {import('qs').IParseOptions & { decoder?: never | undefined }} [options={}] - the parsing options
 * @returns {{[key: string]: undefined | string | string[] | import('qs').ParsedQs | import('qs').ParsedQs[]}}
 */
export function parseQueryFromUrl(url = '', options = {}) {
  // By default, arrayLimit is 20, but in our codebase we want it to be Infinity as the backend always expects the array format.
  return qs.parse(url.split('?')[1], { arrayLimit: Infinity, ...options });
}

function isEmptyStr(value) {
  return value === '';
}

/**
 * Stringifies the query object to a query string.
 * @param {object} query
 */
export function stringifyQuery(
  query,
  { skipNulls = false, skipEmptyStrings = false, skipCustomMatcher } = {}
) {
  const hasFilters = skipEmptyStrings || skipCustomMatcher;

  const filteredQuery = hasFilters
    ? pickBy(query, (value, key) => {
        const isEmpty = skipEmptyStrings && isEmptyStr(value);
        const isCustom = skipCustomMatcher && skipCustomMatcher(key, value);

        return !isEmpty && !isCustom;
      })
    : query;

  return qs.stringify(filteredQuery, {
    skipNulls,
  });
}
