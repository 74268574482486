// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneBriefcase = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M7.65 22.75h8.7c2.24 0 3.36 0 4.22-.44a4 4 0 0 0 1.74-1.74c.44-.86.44-1.98.44-4.22v-5.5a26.65 26.65 0 0 1-21.5 0v5.5c0 2.24 0 3.36.44 4.22a4 4 0 0 0 1.74 1.74c.86.44 1.98.44 4.22.44Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 2.75a3.25 3.25 0 0 0-3.16 2.5h6.32A3.25 3.25 0 0 0 12 2.75Zm0-1.5a4.75 4.75 0 0 0-4.7 4h-.46c-1.48 0-2.22 0-2.82.2a4 4 0 0 0-2.58 2.57c-.19.6-.19 1.34-.19 2.82 1.94.86 3.95 1.47 6 1.84v1.82a.75.75 0 0 0 1.5 0v-1.59c2.16.26 4.34.26 6.5 0v1.59a.75.75 0 0 0 1.5 0v-1.82c2.05-.37 4.06-.98 6-1.84 0-1.48 0-2.22-.2-2.82a4 4 0 0 0-2.57-2.58c-.6-.19-1.34-.19-2.82-.19h-.47a4.75 4.75 0 0 0-4.69-4Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneBriefcase);
export { ForwardRef as IconV2DuotoneBriefcase };