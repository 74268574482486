import { GlobalStyles as NormaGlobalStyles } from '@remote-com/norma';

import EmployGlobalStyles from '@/src/style/global';

export { themeV2 } from '@remote-com/norma';

export const GlobalStyles = () => (
  <>
    <NormaGlobalStyles />
    <EmployGlobalStyles />
  </>
);
