import { datadogRum } from '@datadog/browser-rum';

import { getAnalyticsConfig } from '../setup';

// Ignored to avoid false positives with auth, non existing end points or wrong user data
const IGNORED_STATUSES = [401, 404];
const isRelevantError = (exception) => {
  const responseStatus = exception?.response?.status;

  // we might get strings or errors that weren't caused by an HTTP request
  if (responseStatus === undefined) {
    return true;
  }

  return !IGNORED_STATUSES.includes(responseStatus);
};

export const captureException = (exception) => {
  const { DATADOG_RUM_ENABLED } = getAnalyticsConfig();
  if (DATADOG_RUM_ENABLED) {
    const responseStatusCode = exception?.response?.status;
    const context = responseStatusCode ? { responseStatusCode } : undefined;

    datadogRum.addError(exception, context);
  }
};

export const captureHTTPException = (exception) => {
  if (isRelevantError(exception)) {
    captureException(exception);
  }
};
