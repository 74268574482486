import * as Palettes from './palettes';

type SemanticColorValues = {
  foreground: string;
  background: string;
  border: string;
};

export const SEMANTIC_COLORS = {
  negative: {
    foreground: Palettes.red[700],
    background: Palettes.red[50],
    border: Palettes.red[400],
  },
  positive: {
    foreground: Palettes.green[800],
    background: Palettes.green[50],
    border: Palettes.green[400],
  },
  attention: {
    foreground: Palettes.yellow[800],
    background: Palettes.yellow[50],
    border: Palettes.yellow[400],
  },
  informative: {
    foreground: Palettes.brand[700],
    background: Palettes.blueLight[50],
    border: Palettes.brand[300],
  },
  neutral: {
    foreground: Palettes.grey[600],
    background: Palettes.grey[50],
    border: Palettes.grey[400],
  },
};
// This is best using "satisfies" so we could have the values strictly typed
// (e.g. "foreground") while maintain the custom keys (e.g. "negative").
// However Next.js doesn't support "satisfies" under babel building, see:
// - https://github.com/vercel/next.js/issues/43799
// @TODO: Replace the line above with this when the above issue is fixed
// } satisfies Record<string, object>;

export type SemanticColorNames = keyof typeof SEMANTIC_COLORS;

/**
 * Flatten the type like `${"neutral"}.${"foreground"}
 */
export type SemanticColorForStyledSystem = Record<
  `${SemanticColorNames}.${keyof SemanticColorValues}`,
  string
>;
