import { createContext, useContext, useState, useCallback } from 'react';
import type { PropsWithChildren } from 'react';

import type { PowerSearchActionConfig } from '@/src/domains/powerSearch/types';

type PowerSearchContext = {
  /**
   * An array of power search action configurations.
   */
  actions: PowerSearchActionConfig[];
  /**
   * A function to update the current query actions.
   */
  updateActions: (actions: PowerSearchActionConfig[]) => void;
  /**
   * A function to remove the current query actions.
   */
  removeActions: (actions: PowerSearchActionConfig[]) => void;
};

const PowerSearchContext = createContext<PowerSearchContext | null>(null);

type PowerSearchContextProviderProps = PropsWithChildren<{}>;

function PowerSearchContextProvider({ children }: PowerSearchContextProviderProps) {
  const [actions, setActions] = useState<PowerSearchActionConfig[]>([]);

  const updateActions = useCallback((newActions: PowerSearchActionConfig[]) => {
    setActions((prevActions) => [...prevActions, ...newActions]);
  }, []);

  const removeActions = useCallback((actionsToRemove: PowerSearchActionConfig[]) => {
    setActions((prevActions) => prevActions.filter((action) => !actionsToRemove.includes(action)));
  }, []);

  return (
    <PowerSearchContext.Provider
      value={{
        actions,
        updateActions,
        removeActions,
      }}
    >
      {children}
    </PowerSearchContext.Provider>
  );
}

const usePowerSearchContext = () => {
  return useContext(PowerSearchContext);
};

export { PowerSearchContextProvider, usePowerSearchContext };
