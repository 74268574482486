import type { MutationFunction, UseMutationOptions } from 'react-query';
// This file should be deleted after migrating to useMutationWithCustomState
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useMutation } from 'react-query';

import { useMutationWithCustomState } from '@/src/hooks/useMutationWithCustomState';

/*
 * @deprecated Favor using 'useMutationWithCustomState' through a data layer hook instead
 */
export function useCustomMutation<TData, TError, TVariables, TContext>(
  mutationFn: MutationFunction<TData, TVariables>,
  opts?: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  const mutation = useMutation(mutationFn, opts);
  const extendedMutation = useMutationWithCustomState(mutation);

  return extendedMutation;
}
