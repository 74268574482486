import type { ElementType, FC, MouseEvent } from 'react';
import { forwardRef } from 'react';
import type { DefaultTheme } from 'styled-components';
import type { RequiredTheme, SpaceProps, Theme } from 'styled-system';

import { SROnly } from '../text';

import { ButtonMain, IconAddon, LoadingAddon } from './Button.styled';
import type { PolymorphicComponent, PolymorphicComponentProps } from './types';

type MarginProps<ThemeType extends Theme = RequiredTheme> = Pick<
  SpaceProps<ThemeType, number | 'auto'>,
  | 'm'
  | 'margin'
  | 'mt'
  | 'marginTop'
  | 'mr'
  | 'marginRight'
  | 'mb'
  | 'marginBottom'
  | 'ml'
  | 'marginLeft'
  | 'mx'
  | 'marginX'
  | 'my'
  | 'marginY'
>;

type BaseStyledButtonProps = MarginProps<DefaultTheme>;

export interface ButtonProps extends BaseStyledButtonProps {
  /**
   * Defines the button overall layout
   * - solid - the button with a background
   * - outline - the button with border
   * - raw - Button with no styling
   */
  variant?: 'solid' | 'outline' | 'ghost' | 'raw';
  /** Defines the button color scheme */
  tone?: 'primary' | 'secondary' | 'destructive';
  /** Defines the button padding and font-size and influences the icon size */
  size?: 'lg' | 'sm' | 'xs' | 'md';
  /** Displays a spinner and prevents interaction */
  isLoading?: boolean;
  /** Icon SVG (component) as prefix */
  IconBefore?: ElementType;
  /** Icon SVG (component) as suffix */
  IconAfter?: ElementType;
}

const Button: PolymorphicComponent<'button', ButtonProps> = forwardRef(
  <C extends ElementType = 'button'>(
    {
      children,
      variant = 'solid',
      tone = 'primary',
      size = 'md',
      isLoading = false,
      IconBefore,
      IconAfter,
      href,
      disabled,
      ...props
    }: PolymorphicComponentProps<C, ButtonProps>,
    ref?: PolymorphicComponentProps<C, ButtonProps>['ref']
  ) => {
    // We force cast `as` to any to avoid Typescript errors due to `styled-components` trying to infer the rest of the HTML attributes.
    const elAttrs = href ? { href, as: 'a' as any } : { type: 'button' };

    const disabledProps = {
      'aria-disabled': 'true',
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        // aria-disabled does not prevent interaction
        // as the disabled attr
        e.preventDefault();
      },
    };
    const loadingAttrs = isLoading ? disabledProps : {};
    const disabledAttrs = disabled ? disabledProps : {};

    return (
      <ButtonMain
        ref={ref}
        $size={size}
        $variant={variant}
        $tone={tone}
        $isLoading={isLoading}
        {...elAttrs}
        {...props}
        {...loadingAttrs}
        {...disabledAttrs}
      >
        {IconBefore && (
          <IconAddon as={IconBefore} $place="before" $size={size} $variant={variant} />
        )}
        {children}
        {isLoading && (
          <>
            <SROnly aria-live="assertive">Loading</SROnly>
            <LoadingAddon />
          </>
        )}
        {IconAfter && <IconAddon as={IconAfter} $place="after" $size={size} $variant={variant} />}
      </ButtonMain>
    );
  }
);

export const ButtonForStory = Button as FC<ButtonProps>;

export default Button;
