import getConfig from 'next/config';
import Head from 'next/head';

import { defaultIcons, temporaryIcons } from './constants';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

const currentEnvironment = ENVIRONMENT !== 'not-set' ? ENVIRONMENT : 'development';

function getFavicons(temporaryIcon = {}) {
  return {
    faviconPNG: temporaryIcon.faviconPNG || defaultIcons[currentEnvironment].faviconPNG,
    faviconSVG: temporaryIcon.faviconSVG || defaultIcons[currentEnvironment].faviconSVG,
    faviconAppleTouchIcon:
      temporaryIcon.appleTouchIcon || defaultIcons[currentEnvironment].appleTouchIcon,
  };
}

export function Favicon() {
  const isProduction = currentEnvironment === 'production';
  const favicons = getFavicons(isProduction && temporaryIcons.ukraine);

  return (
    <Head>
      <link rel="icon" href={favicons.faviconPNG} sizes="any" />
      <link rel="icon" href={favicons.faviconSVG} type="image/svg+xml" />
      <link rel="apple-touch-icon" href={favicons.faviconAppleTouchIcon} />
    </Head>
  );
}
