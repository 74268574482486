import Head from 'next/head';
import Script from 'next/script';

const initLayerWithConsent = () => `
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push([
    'consent',
    'default',
    {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'granted',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      wait_for_update: '500',
      regions: 'all',
    }
  ])
`;

const initGTMScript = (gtmContainerId) =>
  `(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    const n = d.querySelector('[nonce]');
    n && j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'));
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "${gtmContainerId}")`;

const initGTMNoScript = (gtmContainerId) => `<iframe
  src="https://www.googletagmanager.com/ns.html?id=${gtmContainerId}"
  height="0"
  width="0"
  style="display:none;visibility:hidden"
></iframe>`;

export function GoogleTagManagerScript({
  config: { GOOGLE_TAGMANAGER_ENABLED, GOOGLE_TAGMANAGER_CONTAINER_ID },
}) {
  if (!GOOGLE_TAGMANAGER_ENABLED || typeof window === 'undefined') return null;

  return (
    <>
      <Script
        // eslint-disable-next-line no-underscore-dangle
        nonce={window.__webpack_nonce__}
        id="data-layer-init"
        dangerouslySetInnerHTML={{
          __html: initLayerWithConsent(),
        }}
      />
      <Script
        // eslint-disable-next-line no-underscore-dangle
        nonce={window.__webpack_nonce__}
        id="gtm-init"
        dangerouslySetInnerHTML={{
          __html: initGTMScript(GOOGLE_TAGMANAGER_CONTAINER_ID),
        }}
      />

      <Head>
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: initGTMNoScript(GOOGLE_TAGMANAGER_CONTAINER_ID),
          }}
        />
      </Head>
    </>
  );
}
