import { BroadcastChannel } from 'broadcast-channel';
import { useEffect } from 'react';

import { REMOTE_CHANNEL_NAME } from '@/src/domains/broadcast/constants';
import { handleMessage } from '@/src/domains/broadcast/handleMessage';

export function BroadcastMessageHandler() {
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(REMOTE_CHANNEL_NAME);
    broadcastChannel.addEventListener('message', handleMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleMessage);
      broadcastChannel.close();
    };
  }, []);

  return null;
}
