import type * as CSS from 'csstype';
import { rem } from 'polished';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components';

interface TypographyProperties {
  lineHeight?: CSS.Property.LineHeight;
  fontWeight?: CSS.Property.FontWeight;
  fontSize?: CSS.Property.FontSize;
  letterSpacing?: CSS.Property.LetterSpacing;
  textTransform?: CSS.Property.TextTransform;
}

function getTextStyles({
  fontWeight,
  fontSize,
  lineHeight,
  letterSpacing,
}: TypographyProperties): FlattenSimpleInterpolation {
  return css`
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: ${lineHeight};
    letter-spacing: ${letterSpacing};
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  `;
}

type FontWeightMediumKey<VariantKey extends string> = `${VariantKey}Medium`;
type FontWeightBoldKey<VariantKey extends string> = `${VariantKey}Bold`;
type FontWeightKey<VariantKey extends string> =
  | VariantKey
  | FontWeightMediumKey<VariantKey>
  | FontWeightBoldKey<VariantKey>;

type FontWeightVariants<VariantKey extends string> = {
  // eslint-disable-next-line no-unused-vars
  [key in FontWeightKey<VariantKey>]: TypographyProperties;
};

type TextVariant = Record<string, TypographyProperties>;

interface TextVariants {
  variants: TextVariant;
}

const createFontWeightVariants = <VariantKey extends string>(textVariant: TextVariant) => {
  const textVariantName = Object.keys(textVariant)[0] as string;
  return {
    [textVariantName]: {
      ...textVariant[textVariantName],
      fontWeight: 400,
    },
    [`${textVariantName}Medium`]: {
      ...textVariant[textVariantName],
      fontWeight: 500,
    },
    [`${textVariantName}Bold`]: {
      ...textVariant[textVariantName],
      fontWeight: 600,
    },
  } as FontWeightVariants<VariantKey>;
};

const getUnitlessLineHeight = (fontSize: number, lineHeight: number) => lineHeight / fontSize;

const textVariants: TextVariants = {
  variants: {
    scale1: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(80, 80),
      fontSize: rem(80),
      letterSpacing: 0,
    },
    scale2: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(52, 58),
      fontSize: rem(52),
      letterSpacing: 0,
    },
    scale3: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(42, 52),
      fontSize: rem(42),
      letterSpacing: 0,
    },
    scale4: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(36, 46),
      fontSize: rem(36),
      letterSpacing: 0,
    },
    titleXXL: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(30, 40),
      fontSize: rem(30),
      letterSpacing: 0,
    },
    titleXL: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(26, 32),
      fontSize: rem(26),
      letterSpacing: 0,
    },
    titleLG: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(24, 34),
      fontSize: rem(24),
      letterSpacing: 0,
    },
    titleMD: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(22, 28),
      fontSize: rem(22),
      letterSpacing: 0,
    },
    titleSM: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(20, 26),
      fontSize: rem(20),
      letterSpacing: 0,
    },
    titleXS: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(18, 24),
      fontSize: rem(18),
      letterSpacing: 0,
    },
    ...createFontWeightVariants<'bodyLead'>({
      bodyLead: {
        lineHeight: getUnitlessLineHeight(18, 26),
        fontSize: rem(18),
        letterSpacing: 0,
      },
    }),
    ...createFontWeightVariants<'body'>({
      body: {
        lineHeight: getUnitlessLineHeight(16, 24),
        fontSize: rem(16),
        letterSpacing: 0,
      },
    }),
    bodySpecial: {
      fontWeight: 400,
      lineHeight: getUnitlessLineHeight(15, 18),
      fontSize: rem(15),
      letterSpacing: '-0.2',
    },
    ...createFontWeightVariants<'bodySM'>({
      bodySM: {
        fontWeight: 400,
        lineHeight: getUnitlessLineHeight(14, 22),
        fontSize: rem(14),
      },
    }),
    ...createFontWeightVariants<'bodyXS'>({
      bodyXS: {
        fontWeight: 400,
        lineHeight: getUnitlessLineHeight(12, 14),
        fontSize: rem(12),
        letterSpacing: 0,
      },
    }),
    ...createFontWeightVariants<'bodyTable'>({
      bodyTable: {
        lineHeight: getUnitlessLineHeight(14, 18),
        fontSize: rem(14),
        letterSpacing: '-0.2',
      },
    }),
    bodyCaption: {
      fontWeight: 400,
      lineHeight: getUnitlessLineHeight(13, 16),
      fontSize: rem(13),
      letterSpacing: 0,
    },
    subHead: {
      fontWeight: 500,
      lineHeight: getUnitlessLineHeight(13, 16),
      fontSize: rem(13),
      letterSpacing: '0.7',
      textTransform: 'uppercase',
    },
    subHeadM: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(12, 16),
      fontSize: rem(12),
      letterSpacing: '0.7',
      textTransform: 'uppercase',
    },
    subHeadSM: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(11, 14),
      fontSize: rem(11),
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
    subHeadXS: {
      fontWeight: 600,
      lineHeight: getUnitlessLineHeight(10, 14),
      fontSize: rem(10),
      letterSpacing: '1',
      textTransform: 'uppercase',
    },
  },
};

// Build Form text variants using existing variants:
textVariants.variants.inputValue = textVariants.variants.bodyMedium;
textVariants.variants.inputPlaceholder = textVariants.variants.bodyMedium;
textVariants.variants.inputLabel = textVariants.variants.bodyXS;
textVariants.variants.inputDescription = textVariants.variants.bodyXS;
textVariants.variants.inputGroupLabel = textVariants.variants.bodyLeadMedium;
textVariants.variants.inputGroupDescription = textVariants.variants.bodySM;

export { textVariants };

interface FormVariant {
  inputFontWeight?: CSS.Property.FontWeight;
  inputFontSize?: CSS.Property.FontSize;
  inputLineHeight?: CSS.Property.LineHeight;
  inputLineHeightSmall?: CSS.Property.LineHeight;
}

type Variants = 'mono' | keyof typeof textVariants.variants;

type Typography = {
  // eslint-disable-next-line no-unused-vars
  [k in Variants]: FlattenSimpleInterpolation;
} & {
  forms: FormVariant;
};

const typography = Object.entries(textVariants.variants).reduce(
  (variants, [variantName, variantValue]) => ({
    ...variants,
    [variantName]: getTextStyles(variantValue),
  }),
  {
    forms: {
      inputFontWeight: 500,
      inputFontSize: '0.875rem',
      inputLineHeight: 1.5,
      inputLineHeightSmall: 1.1875,
    },
  } as Typography
);

typography.mono = css`
  font-family: var(--typography-fontMono);
`;

export { typography };
