import Script from 'next/script';

import { cookieControlConfigFactory } from './config';
import { CookieScriptsStyleOverrides } from './CookieConsent.styled';
import { fetchCookiesConfig } from './services';

const FEATURE_FLAG_PREFIX = 'FLAG_*';
const isDev = () => !['production', 'sandbox'].includes(process.env.NODE_ENV);

function showCookieBanner(brandingConfig = {}) {
  return async () => {
    try {
      const globalConfig = await fetchCookiesConfig();

      const localConfig = isDev()
        ? {
            ...globalConfig,
            // The FLAG_* cookies are used by the Remote Control Panel to force-enable
            // the value of a feature flag. Note that this is only supported in
            // non-production environments and is used mostly for debugging and testing
            // features that are not yet available to users.
            necessaryCookies: `${globalConfig.necessaryCookies},${FEATURE_FLAG_PREFIX}`,
          }
        : globalConfig;

      if (typeof window !== 'undefined' && !!window.CookieControl?.load) {
        window.CookieControl.load(cookieControlConfigFactory(localConfig, brandingConfig));
      }
    } catch (e) {
      console.error(e);
    }
  };
}

/**
 * @param {Object} props
 * @param {boolean} [props.hasCaptcha]
 * @param {Object} [props.branding]
 * @param {string} [props.branding.buttonIconUrl]
 */
export function CookieConsent({ hasCaptcha, branding }) {
  if (typeof window === 'undefined') return null;

  return (
    <>
      <CookieScriptsStyleOverrides $hasCaptcha={hasCaptcha} />
      <Script
        // eslint-disable-next-line no-underscore-dangle
        nonce={window.__webpack_nonce__}
        src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
        onLoad={showCookieBanner(branding)}
        strategy="lazyOnload"
      />
    </>
  );
}
