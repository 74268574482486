import { identifyHubSpotUser } from '../hubspot';

function gtmTrack(args = {}) {
  (window.dataLayer || []).push({
    ...args,
  });
}

export const googleTagManager = {
  trackEmployPageView: (path) => gtmTrack({ event: 'employ_page_view', path }),
  trackSignup: async (user, path, shouldIdentifyUser = true, extraValues = {}) => {
    gtmTrack({ event: 'user_submitted_employ', label: path, ...extraValues });

    // Google tag manager is responsible for loading hubspot.
    return shouldIdentifyUser ? identifyHubSpotUser(user) : Promise.resolve();
  },
  trackCookieConsent: ({ accept, name }) =>
    gtmTrack({ event: `${name}_consent_${accept ? 'given' : 'revoked'}` }),
  trackSignUpTypeNew: (productType, projectedEmployees, whenBy) =>
    gtmTrack({
      productType,
      projectedEmployees,
      whenBy,
    }),
};
