import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

/*
 * You can explore and tweak the query in real time at https://all-remote-content.prismic.io/graphql
 */
const query = `
{
  allCookie_control_configs {
    edges {
      node {
        _meta {
          lang
        }
        statement_url {
          _linkType
          ... on _ExternalLink {
            url
          }
        }
        button_icon
        statement_description
        statement_name
        statement_updated
        necessary_title
        necessary_description
        notify_title
        notify_description
        _linkType
        necessary_cookies
        optional_cookies {
          name
          label
          cookies
          description
          on_accept_event
          on_revoke_event
        }
      }
    }
  }
}
`;

/*
 * Required to be included in the headers, ref specifies the version of the content we want
 * from Prisma, in that case we always want the latest version which is master.
 */
const fetchPrismicRef = async () => {
  const { data } = await axios.get('https://all-remote-content.cdn.prismic.io/api/v2');

  return data?.refs?.find((element) => element.isMasterRef)?.ref;
};

/*
 * Get from Prismic the user configured options for the banner.
 * note: GET instead of a POST request since it's not supported yet by Prismic.
 */
export const fetchCookiesConfig = async () => {
  try {
    const ref = await fetchPrismicRef();

    const { data } = await axios.get(
      `https://all-remote-content.cdn.prismic.io/graphql?query=${encodeURIComponent(query)}`,
      {
        headers: {
          'content-type': 'application/json',
          'Prismic-ref': ref,
        },
      }
    );

    const configs = data.data.allCookie_control_configs.edges;
    // eslint-disable-next-line no-underscore-dangle
    const englishConfig = configs.find((config) => config?.node?._meta.lang === 'en-us')?.node;

    return camelcaseKeys(englishConfig);
  } catch (error) {
    console.error(error);
    return {};
  }
};
