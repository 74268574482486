import * as Palettes from './palettes';
import { SEMANTIC_COLORS } from './semantic';
import { v1ColorCompatibilities } from './v1ColorCompatibilities';

export const colors = {
  ...Palettes,
  primary: Palettes.brand[600],
  blank: Palettes.white,
  ...SEMANTIC_COLORS,

  // compatibility layers
  ...v1ColorCompatibilities,
};
