import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import range from 'lodash/range';
import snakeCase from 'lodash/snakeCase';
import pLimit from 'p-limit';
import type { ValueOf } from 'type-fest';

type QueryParams = {
  [key: string]:
    | string
    | number
    | boolean
    | Record<string, any>
    | (string | number | boolean | Record<string, any>)[];
};

/**
 * Based on an object with key values, return the query string
 * @param params - An object containing the query parameters
 * @returns A string representing the query parameters
 */
export function generateQueryParams(params: QueryParams): string {
  let url = '';

  if (isObject(params)) {
    Object.entries(params)
      .filter(([, value]) => !!value)
      .forEach(([key, value], index) => {
        url += `${index !== 0 ? '&' : ''}`;
        if (Array.isArray(value)) {
          value.forEach((unit, ix) => {
            url += `${ix !== 0 ? '&' : ''}${key}[]=${unit}`;
          });
        } else {
          url += `${key}=${encodeURIComponent(String(value))}`;
        }
      });
  }

  return url;
}

function appendToFormData(
  formData: FormData,
  data: any,
  key: string,
  toSnakeCase: boolean = false,
  filesAsArray: boolean = false
) {
  if (data instanceof File) {
    formData.append(key, data, data.name);
  } else if (Array.isArray(data)) {
    data.forEach((entry, index) => {
      const shouldUseArrayKey = (filesAsArray && entry instanceof File) || !isObject(entry);
      const nestedKey = shouldUseArrayKey ? `${key}[]` : `${key}[${index}]`;
      appendToFormData(formData, entry, nestedKey, toSnakeCase);
    });
  } else if (data === null) {
    formData.append(key, '');
  } else if (isObject(data)) {
    Object.entries(data).forEach(([prop, value]) => {
      const nestedKey = key ? `${key}[${snakeCase(prop)}]` : snakeCase(prop);
      appendToFormData(formData, value, nestedKey, toSnakeCase);
    });
  } else {
    formData.append(key, data);
  }
}

type TransformParamsOptions = {
  filesAsArray?: boolean; // Transforms the keys of arrays of Files to `key_name[]` instead of with indexes `key_name[0]` for easy integration with Tiger. Use if uploading multiple files in one request..
};

/**
 * Return a FormData object with the params that are passed
 * Optionally, convert the keys to snake case
 */
export function getParamsAsFormData(
  params: Record<string, any>,
  toSnakeCase: boolean = true,
  transformParamsOptions: TransformParamsOptions = {}
): FormData {
  if (!params) {
    throw new Error('No params were passed.');
  }

  const formData = new FormData();
  const entries = Object.entries(params);

  entries.forEach(([key, value]) => {
    const formattedKey = toSnakeCase ? snakeCase(key) : key;

    appendToFormData(
      formData,
      value,
      formattedKey,
      toSnakeCase,
      transformParamsOptions.filesAsArray ?? false
    );
  });

  return formData;
}

interface FileLikeObject {
  insertedAt?: Date;
  uploadedByRole?: string;
  slug?: string;
  subType?: string;
  type?: string;
  [key: string]: any; // Additional properties can be dynamic
}

/**
 * Checks if the "item" is a file-like Object, as returned
 * by our API (which includes some properties that can be used
 * to get the file contents from the /files/<slug> endpoint)
 * @param item - The object to check
 */
function isExistingFile(item: ValueOf<QueryParams>): item is FileLikeObject {
  const existingFileProperties: string[] = [
    'insertedAt',
    'uploadedByRole',
    'slug',
    'subType',
    'type',
  ];

  if (isObject(item)) {
    const keys = ('file' in item ? Object.keys(item.file) : Object.keys(item)) as string[];
    return existingFileProperties.every((property) => keys.includes(property));
  }

  return false;
}

function isNewFileObject(item: any): boolean {
  return isObject(item) && 'file' in item && item.file instanceof File;
}
/**
 * Removes properties of the "params" object if they are what
 * is returned by our API as a "file". In case it's an array of files
 * leave the array with the file slugs that were returned by our API
 * or the new file object if they aren't.
 */
export function getParamsWithoutExistingFiles(params: QueryParams) {
  const result = {};
  Object.entries(params).forEach(([key, value]) => {
    if (!isExistingFile(value)) {
      // check if it's an array of files
      // return only slugs for existing files and the fileObject if it's a new file
      if (Array.isArray(value)) {
        const newValue = value.map((item) => {
          if (isExistingFile(item)) {
            return 'file' in item ? item.file.slug : item.slug;
          }
          if (isNewFileObject(item)) return item.file;
          return item;
        });
        Object.assign(result, { [key]: newValue.length ? newValue : null });
      } else {
        Object.assign(result, { [key]: value });
      }
    }
  });

  return result;
}

/**
 * On some of the endpoints that return lists with pagination,
 * the list that is shown to the user comes as the value of a "data" key.
 * { data: ['red', 'green', 'blue', 'yellow'], page: 1, totalPages: 2 }
 * But others use a different key.
 * * { colors: ['red', 'green', 'blue', 'yellow'], page: 1, totalPages: 2 }
 * This helper receives an API response and allows for that key to be renamed,
 * returning an object with the list and pagination.
 * 🚨 Heads up! This is meant to be used with services that use the **makeServiceApi factory**
 */
export function getDataWithRenamedProperty(
  APIResponse: { [key: string]: any },
  propertyThatHasTheData: string,
  targetName: string = 'data'
): { [key: string]: any } {
  if (!APIResponse) {
    throw new Error('No APIResponse was passed.');
  }
  if (!propertyThatHasTheData) {
    throw new Error('No propertyThatHasTheData was passed.');
  }

  const { data: actualResponse } = APIResponse;
  const { [propertyThatHasTheData]: mainData, ...result } = actualResponse;

  return {
    ...result,
    [targetName]: mainData,
  };
}

type RequestFunction<T> = (item?: T, requestParamsList?: T[]) => Promise<any>;

interface ConcurrentFetchCallsParams<T> {
  requestParamsList: T[];
  requestFn: RequestFunction<T>;
  concurrency?: number;
  isPromiseAllSettled?: boolean;
}

interface ConcurrentFetchCallsResult {
  data: PromiseSettledResult<any>[] | any[];
  pendingCount: number;
  activeCount: number;
  clearQueue: () => void;
}

/**
 * When a lot of requests need to be sent to the backend this function will make sure only a max number get sent to the BE at the same time to not overload the backend
/**
 * @param {Object} params
 * @param {Array} params.requestParamsList List of request params you want to loop over and make a call on each item
 * @param {Function} params.requestFn The request to make for each item, this function receives the item itself
 * @param {number} params.concurrency Max number of concurrent calls. Defaults to 4
 */
export const createConcurrentFetchCalls = async <T>({
  requestParamsList,
  requestFn,
  concurrency = 4,
  isPromiseAllSettled = false,
}: ConcurrentFetchCallsParams<T>): Promise<ConcurrentFetchCallsResult> => {
  const limit = pLimit(concurrency);
  const { pendingCount, activeCount, clearQueue } = limit;
  const allRequests = requestParamsList.map((item) =>
    limit(() => requestFn(item, requestParamsList))
  );
  const data = isPromiseAllSettled
    ? await Promise.allSettled(allRequests)
    : await Promise.all(allRequests);

  return {
    data,
    pendingCount,
    activeCount,
    clearQueue,
  };
};

type QueryFunction<T> = (params: any) => Promise<T>;

interface Config {
  [key: string]: unknown;
  pageLimit?: number;
}

interface Result<T> {
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  data: T[] | { data: T[] };
}

type TransformFunction<T> = (results: Result<T>[]) =>
  | {
      currentPage: number;
      totalPages: number;
      data: T[];
    }
  | T[];

const defaultTransformFn = <T>(
  results: Result<T>[]
): { currentPage: number; totalPages: number; data: T[] } => ({
  currentPage: 1,
  totalPages: 1,
  data: (results as Result<T>[]).reduce<T[]>(
    (all, response) => all.concat(response.data as T[]),
    []
  ),
});

type AllPageFetcherFunction<T> = (params: Config) => Promise<T[]>;

/**
 * Given a fetch function, return another function that will fetch all
 * the pages.
 *
 * TODO: This won't work with makeApiService fetches, as it doesn't account for queryParams parameter. Fixme.
 */
export function makeAllPageFetcherFunction<T>(
  queryFn: QueryFunction<any>,
  transformFn: TransformFunction<T> = defaultTransformFn,
  { pageLimit, pageSize }: Config = {}
): AllPageFetcherFunction<T> {
  return async (params) => {
    if (!params.disable_pagination) {
      return queryFn(params);
    }

    const paramsWithoutPage = {
      ...omit(params, ['page', 'pageIndex', 'disable_pagination']),
      pageSize,
    };

    const firstFetchResponse = await queryFn({ ...paramsWithoutPage, page: 1 });

    if (firstFetchResponse.totalPages === 1 || firstFetchResponse.totalPages === 0) {
      return transformFn([firstFetchResponse]);
    }

    const responses = await createConcurrentFetchCalls({
      requestParamsList: range(
        2,
        pageLimit && pageLimit < firstFetchResponse.totalPages
          ? pageLimit + 1
          : firstFetchResponse.totalPages + 1
      ),
      requestFn: (page?: number) => queryFn({ ...paramsWithoutPage, page }),
    });

    return transformFn([firstFetchResponse, ...responses.data]);
  };
}

/**
 * Configure a `useQuery` hook call to prevent retries on certain HTTP status codes,
 * for example, 404.
 *
 * @param {Array<number>} acceptedErrorStatusCodes
 * @param {number} maxRetries
 * @returns {function} Function that can be passed as `useQuery` `retry` option.
 */
export function createQueryRetryCondition(
  acceptedErrorStatusCodes: number[] = [404],
  maxRetries: number = 3
) {
  const acceptedStatusCodesSet = new Set(acceptedErrorStatusCodes);

  return function shouldRetry(failureCount: number, error: any) {
    return !acceptedStatusCodesSet.has(error.response?.status) && failureCount <= maxRetries;
  };
}
