const ddActionAttribute = 'data-dd-action-name';
const ddPrivacyAttribute = 'data-dd-privacy';

// Ordered list of attributes that will be used as the action name if the 'data-dd-action-name' attribute is not specified.
const fallbackAttributes = [
  'aria-label',
  'ariaLabel',
  'id',
  'name',
  'trackingName',
  'data-testid',
  'dataTestid',
];

/**
 * @typedef {Object} Options
 * @property {Boolean} showChildContents - Option to specify that the contents of this element should be included in the session replay.
 */
/**
 * Helper function that attempts to apply the data-dd-action-name
 * attribute to interactive elements. If the attribute can be generated,
 * the attribute's value will be displayed in DataDog session replays feature.
 * @param {Object} props - Component props.
 * @param {Options} options - Options
 */
export function getDDSessionReplayAttribute(props = {}, options = { showChildContents: false }) {
  if (props[ddActionAttribute]) {
    return { [ddActionAttribute]: props[ddActionAttribute] };
  }

  if (props[ddPrivacyAttribute]) {
    return { [ddPrivacyAttribute]: props[ddPrivacyAttribute] };
  }

  if (typeof props.children === 'string' && options?.showChildContents) {
    return { [ddActionAttribute]: props.children };
  }

  const actionName = fallbackAttributes.find((name) => props[name]);

  return actionName ? { [ddActionAttribute]: props[actionName] } : {};
}

/**
 * Attribute to catch uncaught events that may contain PII in DataDog.
 * When this attribute catches an event, it will replace the event's contents with
 * its own value to protect against the potential leak of PII.
 */
export const DD_SESSION_REPLAY_PII_BLOCKER = {
  'data-dd-action-name': 'Element without data-dd-action-name attribute',
};
