import { PageViewsTracker } from '@remote-com/analytics';

import { useUserContext } from '@/src/components/UserProvider';

const AnalyticsWrapper = () => {
  const { user } = useUserContext();
  return <PageViewsTracker user={user} />;
};

export default AnalyticsWrapper;
