import type { ParsedUrlQuery } from 'querystring';

import { getSearchParamsFromURL } from '@/src/helpers/url';

import { routeTeamsMap } from './routeTeamsMap';
import type { EngineeringTeamName } from './teams';
import { remoteEngineeringTeams, UNKNOWN_OWNERSHIP } from './teams';

function nonNullables<T>(value: T): value is NonNullable<T> {
  return value != null;
}

/**
 * Creates an ownership context for the given teams.
 * @param {EngineeringTeamName[] | EngineeringTeamName | null} teamsName - The name of the engineering team or an array of engineering teams.
 * @returns {OwnershipContext} The ownership context for the given teams.
 */
export function createOwnershipContextForTeams(
  teamsName: EngineeringTeamName[] | EngineeringTeamName | null
) {
  if (!teamsName) {
    return UNKNOWN_OWNERSHIP;
  }

  if (Array.isArray(teamsName)) {
    return teamsName
      .map((team) => remoteEngineeringTeams.find(({ name }) => name === team))
      .filter(nonNullables)
      .reduce<{ teams: string; userGroupIds: string }>(
        (allTeams, currentTeam) => {
          return {
            teams: allTeams.teams ? `${allTeams.teams} | ${currentTeam.name}` : currentTeam.name,
            userGroupIds: allTeams?.userGroupIds
              ? `${allTeams?.userGroupIds} <!subteam^${currentTeam?.slack.userGroupId}>`
              : `<!subteam^${currentTeam?.slack.userGroupId}>`,
          };
        },
        { teams: '', userGroupIds: '' }
      );
  }

  if (typeof teamsName === 'string') {
    const team = remoteEngineeringTeams.find(({ name }) => name === teamsName);
    return { teams: team?.name, userGroupIds: `<!subteam^${team?.slack.userGroupId}>` }; // syntax used to mention a subteam in slack
  }

  return UNKNOWN_OWNERSHIP;
}
export function findTeamByRoute({ route, query = {} }: { route: string; query?: ParsedUrlQuery }) {
  const allRouteMatches = Object.keys(routeTeamsMap)
    // Filter out all matching routes without taking into account query parameters.
    .filter((key) => key.split('?')[0] === route)
    // Sort the results to make sure a match without query parameters will be first.
    .sort();

  const bestRouteMatch = allRouteMatches.reduce<{
    route: string;
    matchesCount: number;
  }>(
    (best, current) => {
      // Get the search params from the current route string.
      const currentSearchParams = getSearchParamsFromURL(current);

      // Count the number of query parameters that match between the current route and the query.
      const matchesCount = Object.keys(query).reduce((count, key) => {
        return currentSearchParams[key] === query[key] ? count + 1 : count;
      }, 0);

      if (matchesCount > best.matchesCount) {
        return { route: current, matchesCount };
      }

      return best;
    },
    { route: allRouteMatches[0], matchesCount: 0 }
  ).route;

  const teamsName = routeTeamsMap[bestRouteMatch];

  return createOwnershipContextForTeams(teamsName);
}
