/* eslint-disable remote/disallow-data-layer-endpoint-assignment */
module.exports = {
  '/api/v1/[userType]/contract-documents/[slug]/comments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/bank-accounts/[slug]/default': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/business-info': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/change-password': { serviceOptions: { asFormData: false } },
  '/api/v1/account/contracts/[contractSlug]/administrative-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/contracts/[contractSlug]/employment-eligibility': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/account/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]': {
    serviceOptions: {
      asFormData: false,
      // The submitted payload originates from JSON-schema-based form,
      // so no additional transformation is needed.
      toSnakeCase: false,
    },
  },
  '/api/v1/account/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]/upload': {
    serviceOptions: {
      asFormData: true,
      // The submitted payload originates from JSON-schema-based form,
      // so no additional transformation is needed.
      toSnakeCase: false,
    },
  },
  '/api/v1/account/email/resend-changed-email-confirmation': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/nium/beneficiaries': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/account/payment-preferences': { serviceOptions: { asFormData: false } },
  '/api/v1/account/profile-email/resend-changed-email-confirmation': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/totp': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/upload-personal-file': { serviceOptions: { asFormData: true } },
  '/api/v1/account/withdrawal-methods/[slug]/default': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/withdrawal-methods/[slug]/eligible-invoice-settings-assignment': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/ai-knowledge-bot/smooch/conversations/take-control-from-ultimate-ai': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/ai-knowledge-bot/smooch/tokens': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/ai-knowledge-bot/support/open-ticket': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/autoscan/document': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/billing-platform/rivendell/billable-items/adjustments': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['data'] },
    },
  },
  '/api/v1/billing-platform/rivendell/billable-items/corrections': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/billing-platform/rivendell/billing-documents/[slug]/register-legacy-invoice': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/billing-platform/rivendell/billing-documents/[slug]/reprint-pdf': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/career-development/journal/feedback': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/career-development/journal/notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/career-development/journal/notes/ai/suggest': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/company-feedback': {
    serviceOptions: { asFormData: true, transformParamsOptions: { filesAsArray: true } },
  },
  '/api/v1/company-feedback/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/countries/[countryCode]/[formName]/validation': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/countries/[countryCode]/fillable_documents/[documentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/email-change-confirmation': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/benefits/integrations/kota/session': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/benefits/integrations/simply-insured/session': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/benefits/safety-wing/enroll': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/clients': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/contract-documents/[contractDocumentSlug]/form': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contract-documents/[contractDocumentSlug]/preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contractor-invoices': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/contractor-invoices/payout-quote-preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contractor-invoices/preview': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/contractor-invoices/recurring': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/contractor-invoices/time-tracking/create': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contractor-invoices/time-tracking/preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/custom-field-values': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/employment-requests': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]/default': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/time-preferences': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/time-trackings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/employments/[slug]/timeoff/entitlements-usage': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/employments/[slug]/timesheets': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/expenses': {
    serviceOptions: { asFormData: true, transformParamsOptions: { filesAsArray: true } },
  },
  '/api/v1/employee/expenses/[expenseSlug]/cancel': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/information-requests/[informationRequestSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/pdf-filler/[pdfSlug]/preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/pdf-filler/[pdfSlug]/submit': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/remote-work-request': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/timesheets/report': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/travel-letter-request': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/1099-tax-forms/generate-for-non-employees': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/1099-tax-forms/report': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/annual-plans/[planSlug]/accept': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/beneficiaries-for/[resourceType]/[resourceSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/benefits/integrations/kota/session': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/benefits/integrations/partner-consent': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/benefits/integrations/simply-insured/session': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create-contractors/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create-contractors/validate-csv': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/bulk-create/direct-employees/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create/direct-employees/validate-csv': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/bulk-create/employee-of-record/[countryCode]/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create/employee-of-record/validate-csv/[countryCode]': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/bulk-create/global-payroll/legal_entity/[legalEntitySlug]/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create/global-payroll/validate-csv/[countryCode]': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/bulk-create/hris-direct-employees': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-create/hris-direct-employees/parser': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/bulk-scheduled-contractor-invoices': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/bulk-scheduled-contractor-invoices/creation-template': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/bulk-scheduled-contractor-invoices/validate-creation': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/career-development/performance-cycles': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]/calibrators/assign':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]/close':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/preview-split':
    {
      serviceOptions: {
        asFormData: false,
        toSnakeCase: false,
        transformResponseOptions: {
          // Don't transform the data because the filters contain the custom filter slug
          stopPaths: ['data'],
        },
      },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/split':
    { serviceOptions: { asFormData: false, toSnakeCase: false } },
  '/api/v1/employer/checklists': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/checklists/[checklistSlug]/create-default-template': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/checklists/[checklistSlug]/tasks': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/company-feedback/[slug]/status/[status]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/company-product-tiers/[companyProductTierSlug]/accept': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/company/business-validation-document': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/company/departments': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/company/nium-virtual-accounts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/company/payment-settings': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/company/privacy-notice': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/company/team-members': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents/[slug]/preview': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents/[slug]/sign': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents/[slug]/signature-trail': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-documents/batch-signing': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents/bulk-create': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-documents/finalized': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data.input'] } },
  },
  '/api/v1/employer/contract-eligibility/[slug]/identity/decline': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-eligibility/[slug]/identity/verify': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-templates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-templates/[slug]/preview': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contractor-invoices/approve': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contractor-invoices/decline': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contractor-invoices/mark-as-externally-paid': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contractor-invoices/outstanding-payment-summary': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contractor-invoices/outstanding-payments': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/contractor-invoices/preview': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contractor-invoices/report': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contracts/[contractSlug]/files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/cost-approval-requests/[costApprovalRequestSlug]/approve': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/cost-approval-requests/[costApprovalRequestSlug]/decline': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/cost-calculator/estimate-pdf': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/employer/cost-calculator/estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/employer/cost-calculator/worldwide-estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/employer/custom-field-definitions': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/custom-field-values': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/custom-field-values/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/documents': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/employee-pay-elements/batch': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employment-requests': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employment-requests/check-if-automatable': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/contract-amendments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/contract-document-form-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/contractor-plus-subscription': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/department': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/direct-offboardings': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/employments/[employmentSlug]/direct-offboardings/[offboardingSlug]/files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/employments/[employmentSlug]/files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/employments/[employmentSlug]/global-payroll-offboardings': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/employments/[employmentSlug]/nium-beneficiaries': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/reporting-line': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/salaries': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/user-relationship': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[slug]/contractor-rate': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/expenses/[slug]/approve': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/expenses/[slug]/decline': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/extra-entitlements': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/hris/company-admins/employee-invites': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/hris/reporting-lines': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/hris/temp-account-assocs': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/implementation-fees/[resourceSlug]/accept': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/import-jobs': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/import-jobs/[slug]/reupload-csv': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/import-jobs/[slug]/rows/find-and-replace': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/import-jobs/[slug]/submit': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/integration/[integrationName]/account-mapping/contractors': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/account-mapping/contractors/all': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/auth_link': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/sync': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/remote_api/tokens': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/leave-policies': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/leave-policies/[leavePolicySlug]/variants': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/documents/upload': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-beneficiaries': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        // exclude nium beneficiary fields from snake-casing
        exclude: [/^(?!accountUsageType).*/],
      },
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/signatory-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/upgrade': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/start': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/upload-personal-document': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/nium-kyb/onboarding-case/kyc': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/onboarding/company': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/onboarding/company/remind-complete-company-signup': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/employments': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/onboarding/employments/[employmentSlug]/set-contract-origin': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/employments/[slug]/reserve': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/employments/[slug]/save-draft': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/pre-hire-check/[slug]/approve': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/pre-hire-check/[slug]/decline': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/pre-hire-check/[slug]/onboarding': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/outstanding-payments/[outstandingPaymentSlug]/estimate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/outstanding-payments/[slug]/pay': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/outstanding-payments/[slug]/simulate-pay-in-transfer': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/outstanding-payments/[slug]/unschedule': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/[slug]/verify-deposits': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/card': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/gusto-ach/[slug]/verify-deposits': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/sepa': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/sepa/setup-success': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/setup-intent': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/sign-ach-verification-payload': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/confirm-payment': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payouts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-run-comments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payroll-runs/[slug]/input-assignment': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/pricing-plans/[planSlug]/accept': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/projects': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/reporting-lines/account-link': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/risk-reserves/[slug]/proof-of-payment': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/roles': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/roles/[slug]/assignments': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/scheduled-contractor-invoices': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/tce/estimate-pdf': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/tce/estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/employer/timeoff/[timeOffSlug]/approve': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/timeoff/[timeOffSlug]/approve_cancel': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/timesheets/bulk-approvals': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/workflows': { serviceOptions: { asFormData: false } },
  '/api/v1/freelancer/client-invitations': { serviceOptions: { asFormData: false } },
  '/api/v1/identity-verification/[inquiryId]/resume': { serviceOptions: { asFormData: false } },
  '/api/v1/identity-verification/associate': { serviceOptions: { asFormData: false } },
  '/api/v1/onboarding/confirm-email': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/pdf-filler/[pdfSlug]/values': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/profile-email-change-confirmation': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/prompts/update-across-all-profiles': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/referral-link': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/registration': {
    serviceOptions: {
      asFormData: false,
      // Avoids snake-casing this marketing parameter to impact_subid_1
      transformParamsOptions: {
        exclude: ['impact_subid1'],
      },
    },
  },
  '/api/v1/registration/send-confirmation': { serviceOptions: { asFormData: false } },
  '/api/v1/reset-password': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell//outstanding-refunds/[refundSlug]/reimbursement': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/annual-plans': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/annual-plans/[planSlug]/share': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/annual-plans/[planSlug]/terminate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/autoscan/document': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/benefit-cost-correction': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-costs/[benefitCostSlug]/correction': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/benefit-costs/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-details': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/benefit-groups': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/benefit-plans': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-tiers': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['errors.benefit_tier_plans'] },
    },
  },
  '/api/v1/rivendell/benefits/batch-index': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefits/package-manager/benefit-groups': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/benefits/package-manager/yearly-mappings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/bulk-create/employee-of-record/[countryCode]/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/bulk-create/global-payroll/legal_entity/[legalEntitySlug]/create-and-invite': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/bulk-employment-changes/bulk-amendments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/bulk-employment-changes/bulk-amendments/templates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/bulk-employment-changes/bulk-amendments/templates/validations': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/bulk-upload/benefit-plans': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/carriers': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/employee-of-record/[countryCode]/create-and-invite':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/employee-of-record/validate-csv/[countryCode]':
    {
      serviceOptions: { asFormData: true },
    },
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/global-payroll/validate-csv/[countryCode]':
    {
      serviceOptions: { asFormData: true },
    },
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers/[companyProductTierSlug]/cancel':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers/[companyProductTierSlug]/share':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/companies/[companySlug]/compliance-requests': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/hris/temp-account-assocs': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/team-members': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[slug]/documents': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/companies/[slug]/tags': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/collections-settings/bulk-update': {
    // Be careful: this is explicitly "true"
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/company-products/[companyProductSlug]/discount': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/compliance-request-item-templates': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/compliance/industry-suggestions': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/compliance/load-external-data': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents': { serviceOptions: { asFormData: true } }, // Set to multipart in the BE
  '/api/v1/rivendell/contract-documents/[slug]/comments': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/[slug]/preview': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/[slug]/send': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/[slug]/share': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/[slug]/signature-trail': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contract-documents/bulk-create': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/create-and-send': { serviceOptions: { asFormData: true } }, // Set to multipart in the BE
  '/api/v1/rivendell/contract-templates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contract-templates/[slug]/preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contract-templates/supported-automations/[automationType]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contracts/[contractSlug]/contract-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contracts/[contractSlug]/contract-eligibility': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/cost-approval-requests': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/cost-approval-requests/[costApprovalRequestSlug]/costs': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/cost-calculator/estimate-pdf': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/rivendell/cost-calculator/estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/rivendell/cost-calculator/worldwide-estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/rivendell/custom-field-definitions': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/data-exports/[slug]/create': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/data-exports/templates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/data-exports/templates/[slug]/duplicate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/default-benefit-offers/batch-upsert': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/demo-accounts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/document-comments': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/document-requests': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/employee-pay-elements': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-pay-elements-from-input': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-pay-elements/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/employee-pay-elements/batch/preview': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-payment-installments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-payment-installments/[slug]/notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-payment-installments/[slug]/operations/manual-processing': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/accept': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/compliance_review': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/external-processing': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/manual-processing': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/pending': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/reject': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/return': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employee-payment-installments/bulk-operations/wont_process': {
    serviceOptions: { asFormData: false, transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/contracts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/convert': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/mobility-assignments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/reporting-line': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/salaries': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/user-relationship': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[slug]/payslips': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/employments/[slug]/risk-reserves': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/expenses': {
    serviceOptions: { asFormData: true, transformParamsOptions: { filesAsArray: true } },
  },
  '/api/v1/rivendell/expenses/[slug]/line-items': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/extra-entitlements': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/global-payroll-offboardings/bulk-complete': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/holidays': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/holidays/activate_all': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/holidays/delete_all': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/implementation-fees': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/implementation-fees/[slug]/share': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/import-jobs': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/import-jobs/[slug]/reupload-csv': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/import-jobs/[slug]/rows/find-and-replace': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/import-jobs/[slug]/submit': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/information-requests': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/inter-company-invoices/[interCompanyInvoiceSlug]/finalize': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/inter-company-invoices/[interCompanyInvoiceSlug]/reprint': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/inter-company-remoter-markup': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/invoice-report-adjustments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/credit': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/service-invoices': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/rebill': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/contractors-product-usage': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/eor-employment-usage': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/eor-prefunding-billables': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/eor-reconciliation-billables': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/eor-supplemental-services-billables': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/global-payroll-usage': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/upload-billables': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/background-jobs/upload-usage-to-zuora': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/juro/contracts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/attendance-policies': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/core-benefits': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/knowledge-database/country-salary-configurations': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/employer-contributions': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/fact-schemas': {
    serviceOptions: { asFormData: false, deepTransformResponse: false, toSnakeCase: false },
  },
  '/api/v1/rivendell/knowledge-database/facts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/regions': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/knowledge-database/timeoff-policies': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-requests': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/[legalEntityDocumentSlug]/reject': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/complete': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/upload': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/onboarding': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/signatory-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/upgrade': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[slug]/employee-pay-elements/import': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[slug]/tags': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/collections-settings/bulk-update': {
    // Be careful: this is explicitly "true"
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/legal-entity-pay-element-data-source': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-element-template-collections': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-element-templates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-elements': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/nium-kyb/rfis/[slug]/reset': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/notifications/custom': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/notifications/custom/audience-counts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/offboardings': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/offboardings/bulk-complete': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/offboardings/bulk-create': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/offboardings/pending-payment': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/onboarding-notes': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payment-file/generate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payment-file/generate-and-upload': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payment-file/generate-penny-test': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payment-file/generate-penny-test/preview-totals-by-currency': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-calendars': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-runs': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['data.validations'] },
    },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-integration/calculate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-integration/fetch-payslips': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-integration/fetch-receipts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-integration/submit': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-comments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/refresh-payments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/billing/memos': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/fx-rates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/input-assignments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-outputs/batch-upload-files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-outputs/summarization': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-contracts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/upload-payroll-outputs': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/validate': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['data.validations'] },
    },
  },
  '/api/v1/rivendell/payroll-runs/force-actions': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-runs/year-end-statement': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payslips/[slug]/files': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/pricing-plans/[planSlug]/share': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/pricing-plans/[planSlug]/terminate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/pricing/[companySlug]/pricing-plans': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/pricing/team-plan-fees': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/remote-paid-services': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/reports/[type]/download': {
    serviceOptions: {
      asFormData: false,
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/rivendell/roles/[slug]/assignments': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/rivendell/severance-reserves/employments/disable': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/severance-reserves/employments/enable': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/severance-reserves/employments/reset': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/task-assignments': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/tax-notes': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/tce/estimate-pdf': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/tce/estimates': {
    serviceOptions: { asFormData: false, deepTransformResponse: false },
  },
  '/api/v1/rivendell/team-plans': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/team-plans/[slug]/share': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/travel-letter-request/[slug]/generate-contract-document': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[slug]/tags': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[userSlug]/administrative-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[userSlug]/home-address': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[userSlug]/notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[userSlug]/personal-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/users/[userSlug]/unlock-account': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/vat-groups': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/vat-groups/[vatGroupSlug]/add-legal-entity': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/vat-groups/[vatGroupSlug]/remove-legal-entity': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/session': { serviceOptions: { asFormData: false, skipCustomAuthInterceptor: true } },
  '/api/v1/session/2fa': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/session/accounts': { serviceOptions: { asFormData: false } },
  '/api/v1/session/jwt': { serviceOptions: { asFormData: false } },
  '/api/v1/session/magic-link': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/session/user-profiles': { serviceOptions: { asFormData: false } },
  '/api/v1/sso/redirect': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/support-interactions/zendesk/tickets/comments/ai/summary': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/table-views': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/rivendell/knowledge-database/facts': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        deep: false,
        exclude: ['metaschema'],
      },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[factIdentifier]/fact-values': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: { deep: false, exclude: ['relevancy'] },
    },
  },
  '/boba/api/v1/admin/companies/[companySlug]/profiles/logos': {
    serviceOptions: { asFormData: true, useAuthentication: true },
  },
  '/boba/api/v1/employer/company-profiles': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/company-profiles/company-logo': {
    serviceOptions: { asFormData: true, useAuthentication: true },
  },
  '/boba/api/v1/employer/hiring-managers': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[jobSlug]/checkout': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[jobSlug]/publish': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/import': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/contact': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/onboard': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/view': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/subscriptions': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/subscriptions/customer-portal': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/remote-ai/api/v1/generate/feature-flag-audiences-explanation': {
    serviceOptions: { asFormData: false },
  },
  '/remote-ai/api/v1/generate/form-field-content': {
    serviceOptions: { asFormData: false, responseType: 'stream' },
  },
  '/remote-ai/api/v1/generate/workflow-condition-validation': {
    serviceOptions: { asFormData: false },
  },
};
