import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { generateSelectOptions, generateSelectOptionsFromValues } from '@/src/helpers/forms';

/**
 * List based on the file types allowed on Tiger.Enums.FileType
 */
export const FILE_TYPES = /** @type {const} */ ({
  ANTI_HARASSMENT_TRAINING: 'anti_harassment_training',
  BENEFIT: 'benefit',
  BENEFIT_DETAIL: 'benefit_detail',
  BENEFIT_TIER: 'benefit_tier',
  BUSINESS_VALIDATION_DOCUMENT: 'business_validation_document',
  COMPLIANCE_REQUEST: 'compliance_request',
  CONTRACT: 'contract',
  CONTRACTOR_AGREEMENT: 'contractor_agreement',
  /**
   * Generic type for most compliance documents:
   * - Should be uploaded exclusively by Remote admins
   * - Accept a wide range of formats, especially photos and images
   * - See: https://gitlab.com/remote-com/employ-starbase/tiger/-/merge_requests/17472
   */
  COMPLIANCE_DOCUMENT: 'compliance_document',
  CONTRACTOR_INVOICE: 'contractor_invoice',
  CREDIT_NOTE: 'credit_note',
  CUSTOM_CONTRACTOR_AGREEMENT: 'custom_contractor_agreement',
  CONTRACTOR_SERVICES_AGREEMENT: 'contractor_services_agreement',
  CONTRACTOR_STATEMENT_OF_WORK: 'contractor_statement_of_work',
  CUSTOM_CONTRACTOR_AGREEMENT_V2: 'custom_contractor_agreement_v2',
  DOCUMENT: 'document',
  DOCUMENT_SCAN: 'document_scan',
  EMPLOYMENT_REQUEST: 'employment_request',
  EXPENSE: 'expense',
  EXPENSE_POLICY: 'expense_policy',
  EXTERNAL_CONTRACT: 'external_contract',
  GENERIC_EMPLOYMENT_DOCUMENT: 'generic_employment_document',
  HEALTH_CHECK: 'health_check',
  ID: 'id',
  INTER_COMPANY_INVOICE: 'inter_company_invoice',
  MSA_DOCUMENT: 'msa_document',
  NOTICE: 'notice',
  OFFBOARDING: 'offboarding',
  OTHER: 'other',
  PAYROLL: 'payroll',
  PAYROLL_RUN: 'payroll_run',
  PERSONAL: 'personal',
  POST_ENROLLMENT: 'post_enrollment',
  PRIVACY_NOTICE: 'privacy_notice',
  REMOTE_INVOICE: 'remote_invoice',
  REMOTE_PAID_SERVICE: 'remote_paid_service',
  SAFETY_TRAINING: 'safety_training',
  SERVICE_ORDER: 'service_order',
  SLA_DOCUMENT: 'sla_document',
  TAX_DOCUMENT: 'tax_document',
  TERMS_OF_SERVICE: 'terms_of_service',
  TIME_OFF: 'timeoff',
  TIMEOFF_REPORT: 'timeoff_report',
  TRANSACTION_RECEIPT: 'transaction_receipt',
  VISA: 'visa',
  DIRECT_OFFBOARDING: 'direct_offboarding',
  EMPLOYER_MANAGED_DOCUMENT: 'employer_managed_document',
});

// This mapping is used interchangeably for both files.type and contract_documents.type enums
export const DOCUMENT_TYPES = /** @type {const} */ ({
  DELETED_PAYSLIP: 'deleted_payslip',
  EMPLOYMENT_AGREEMENT_AMENDMENT: 'ea_amendment',
  EMPLOYMENT_AGREEMENT: 'employee_agreement',
  EMPLOYMENT_VERIFICATION_LETTER: 'employment_verification_letter',
  GLOBAL_OFFER_LETTER: 'global_offer_letter',
  MOBILITY: 'mobility',
  OFFBOARDING_DOCUMENT: 'offboarding_document',
  ONBOARDING: 'onboarding',
  PAYSLIP: 'payslip',
  PROBATION_CONFIRMATION_LETTER: 'probation_completion_letter',
  WORD_TEMPLATE: 'word_template',
  MEDICAL_REFERRAL: 'medical_referral',
  TRAVEL_LETTER: 'travel_letter',
  ...FILE_TYPES,
});

export const DOCUMENT_SUB_TYPES = /** @type {const} */ ({
  CUSTOM_DOCUMENT: 'custom_document',
  COMPANY_DOCUMENT: 'company_document',
  INTERNAL_DOCUMENT: 'internal_document',
  YEAR_END_STATEMENT: 'year_end_statement',
  PAYROLL_OUTPUTS: 'payroll_outputs',
  PAYSLIP: 'payslip',
  PERSONAL_ID: 'personal_id',
  GUSTO_PAYROLL_RECEIPT: 'gusto_payroll_receipt',
  GUSTO_PAYROLL_JOURNAL_REPORT_MAIN: 'gusto_payroll_journal_report_main',
  GUSTO_PAYROLL_JOURNAL_REPORT_BONUS: 'gusto_payroll_journal_report_bonus',
  ADDITIONAL_CONTRIBUTIONS_REPORT: 'additional_contributions_report',
  GROSS_TO_NET_REPORT: 'gross_to_net_report',
  RRSP: 'RRSP',
  PAYROLL_DEBIT_DETAILS: 'Payroll Debit Details',
  PAYROLL_REGISTER_FINAL: 'Payroll Register - Final',
  PAYROLL_REGISTER_INITIAL: 'Payroll Register - Initial',
  HEALTH_TAX_REPORT: 'Health Tax Report',
  WORKERS_COMPENSATION_BOARD: "Workers' Compensation Board Report",
  PAYMENT_TO_INACTIVE_EMPLOYEES: 'Payment To Inactive Employees',
  TRAVEL_LETTER: 'travel_letter',
});

export const DOCUMENT_SUB_TYPE_LABELS = {
  [DOCUMENT_SUB_TYPES.CUSTOM_DOCUMENT]: 'Custom Document',
  [DOCUMENT_SUB_TYPES.COMPANY_DOCUMENT]: 'Employer Document', // A little confusing but "company" is mapped to "Employer" and "internal" to "Company"
  [DOCUMENT_SUB_TYPES.INTERNAL_DOCUMENT]: 'Company Document',
  [DOCUMENT_SUB_TYPES.YEAR_END_STATEMENT]: 'Year End Statement',
  [DOCUMENT_SUB_TYPES.PAYROLL_OUTPUTS]: 'Payroll Outputs',
  [DOCUMENT_SUB_TYPES.PAYSLIP]: 'Payslip',
  [DOCUMENT_SUB_TYPES.PERSONAL_ID]: 'Personal identification (front)',
  [DOCUMENT_SUB_TYPES.GUSTO_PAYROLL_RECEIPT]: 'Gusto payroll receipt',
  [DOCUMENT_SUB_TYPES.GUSTO_PAYROLL_JOURNAL_REPORT_MAIN]: 'Gusto main payroll journal report',
  [DOCUMENT_SUB_TYPES.GUSTO_PAYROLL_JOURNAL_REPORT_BONUS]: 'Gusto bonus payroll journal report',
  [DOCUMENT_SUB_TYPES.GROSS_TO_NET_REPORT]: 'Gross-to-net report',
  [DOCUMENT_SUB_TYPES.ADDITIONAL_CONTRIBUTIONS_REPORT]: 'Additional contributions report',
  [DOCUMENT_SUB_TYPES.RRSP]: 'RRSP',
  [DOCUMENT_SUB_TYPES.PAYROLL_DEBIT_DETAILS]: 'Payroll Debit Details',
  [DOCUMENT_SUB_TYPES.PAYROLL_REGISTER_FINAL]: 'Payroll Register - Final',
  [DOCUMENT_SUB_TYPES.PAYROLL_REGISTER_INITIAL]: 'Payroll Register - Initial',
  [DOCUMENT_SUB_TYPES.HEALTH_TAX_REPORT]: 'Health Tax Report',
  [DOCUMENT_SUB_TYPES.WORKERS_COMPENSATION_BOARD]: "Workers' Compensation Board Report",
  [DOCUMENT_SUB_TYPES.PAYMENT_TO_INACTIVE_EMPLOYEES]: 'Payment To Inactive Employees',
  [DOCUMENT_SUB_TYPES.TRAVEL_LETTER]: 'TRAVEL_LETTER',
};

export const DOCUMENT_TYPE_LABELS = /** @type {const} */ ({
  [DOCUMENT_TYPES.DOCUMENT]: 'Document',
  [DOCUMENT_TYPES.MSA_DOCUMENT]: 'Master Service Agreement',
  [DOCUMENT_TYPES.TERMS_OF_SERVICE]: 'Terms of Service',
  [DOCUMENT_TYPES.BUSINESS_VALIDATION_DOCUMENT]: 'Business validation document',
  [DOCUMENT_TYPES.SERVICE_ORDER]: 'Service order',
  [DOCUMENT_TYPES.SLA_DOCUMENT]: 'Service Level Agreement',
  [DOCUMENT_TYPES.VISA]: 'Visa',
  [DOCUMENT_TYPES.TAX_DOCUMENT]: 'Tax',
  [DOCUMENT_TYPES.CONTRACTOR_INVOICE]: 'Contractor invoice',
  [DOCUMENT_TYPES.EXPENSE]: 'Expense',
  [DOCUMENT_TYPES.EXPENSE_POLICY]: 'Expense Policy',
  [DOCUMENT_TYPES.CONTRACTOR_AGREEMENT]: 'Contractor Agreement',
  [DOCUMENT_TYPES.CONTRACTOR_SERVICES_AGREEMENT]: 'Contractor Services Agreement',
  [DOCUMENT_TYPES.CONTRACTOR_STATEMENT_OF_WORK]: 'Contractor Statement of Work',
  [DOCUMENT_TYPES.CUSTOM_CONTRACTOR_AGREEMENT]: 'Custom Contractor Agreement',
  [DOCUMENT_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2]: 'Custom Contractor Agreement',
  [DOCUMENT_TYPES.CONTRACT]: 'Contract',
  [DOCUMENT_TYPES.ID]: 'Identity',
  [DOCUMENT_TYPES.NOTICE]: 'Notice',
  [DOCUMENT_TYPES.OTHER]: 'Other document',
  [DOCUMENT_TYPES.PAYSLIP]: 'Payslip',
  [DOCUMENT_TYPES.DELETED_PAYSLIP]: 'Deleted Payslip',
  [DOCUMENT_TYPES.PERSONAL]: 'Personal',
  [DOCUMENT_TYPES.EMPLOYMENT_AGREEMENT]: 'Employment Agreement',
  [DOCUMENT_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: 'EA Amendment',
  [DOCUMENT_TYPES.EXTERNAL_CONTRACT]: 'Contract',
  [DOCUMENT_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: 'Generic Employment Document',
  [DOCUMENT_TYPES.EMPLOYMENT_VERIFICATION_LETTER]: 'Employment verification letter',
  [DOCUMENT_TYPES.PROBATION_CONFIRMATION_LETTER]: 'Probation confirmation letter',
  [DOCUMENT_TYPES.GLOBAL_OFFER_LETTER]: 'Global Offer Letter',
  [DOCUMENT_TYPES.HEALTH_CHECK]: 'Health Check',
  [DOCUMENT_TYPES.ANTI_HARASSMENT_TRAINING]: 'Anti-harassment training',
  [DOCUMENT_TYPES.SAFETY_TRAINING]: 'Safety training',
  [DOCUMENT_TYPES.PAYROLL_RUN]: 'Payroll Run',
  [DOCUMENT_TYPES.MOBILITY]: 'Mobility',
  [DOCUMENT_TYPES.TIME_OFF]: 'Time Off',
  [DOCUMENT_TYPES.ONBOARDING]: 'Onboarding',
  [DOCUMENT_TYPES.OFFBOARDING]: 'Offboarding',
  [DOCUMENT_TYPES.OFFBOARDING_DOCUMENT]: 'Offboarding Document',
  [DOCUMENT_TYPES.COMPLIANCE_DOCUMENT]: 'Compliance Document',
  [DOCUMENT_TYPES.BENEFIT_DETAIL]: 'Benefit Detail',
  [DOCUMENT_TYPES.BENEFIT_TIER]: 'Benefit Tier',
  [DOCUMENT_TYPES.COMPLIANCE_REQUEST]: 'Compliance Request',
  [DOCUMENT_TYPES.CREDIT_NOTE]: 'Credit Note',
  [DOCUMENT_TYPES.DOCUMENT_SCAN]: 'Document Scan',
  [DOCUMENT_TYPES.INTER_COMPANY_INVOICE]: 'Inter-Company Invoice',
  [DOCUMENT_TYPES.PAYROLL]: 'Payroll',
  [DOCUMENT_TYPES.POST_ENROLLMENT]: 'Post Enrollment',
  [DOCUMENT_TYPES.PRIVACY_NOTICE]: 'Privacy Notice',
  [DOCUMENT_TYPES.REMOTE_PAID_SERVICE]: 'Remote Paid Service',
  [DOCUMENT_TYPES.TIMEOFF_REPORT]: 'Timeoff Report',
  [DOCUMENT_TYPES.TRANSACTION_RECEIPT]: 'Transaction Receipt',
  [DOCUMENT_TYPES.EMPLOYER_MANAGED_DOCUMENT]: 'Generic Document',
  [DOCUMENT_TYPES.MEDICAL_REFERRAL]: 'Medical Referral',
  [DOCUMENT_TYPES.TRAVEL_LETTER]: 'Travel Letter',
});

// Used in the Employer's dashboard
export const OTHER_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.DOCUMENT,
  DOCUMENT_TYPES.EXPENSE_POLICY,
  DOCUMENT_TYPES.MSA_DOCUMENT,
  DOCUMENT_TYPES.BUSINESS_VALIDATION_DOCUMENT,
  DOCUMENT_TYPES.SERVICE_ORDER,
  DOCUMENT_TYPES.SLA_DOCUMENT,
  DOCUMENT_TYPES.VISA,
  DOCUMENT_TYPES.CONTRACTOR_AGREEMENT,
  DOCUMENT_TYPES.CUSTOM_CONTRACTOR_AGREEMENT,
  DOCUMENT_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
  DOCUMENT_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
  DOCUMENT_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
  DOCUMENT_TYPES.TERMS_OF_SERVICE,
  DOCUMENT_TYPES.PRIVACY_NOTICE,
];

// Used in the Employer's dashboard
export const TAX_DOCUMENT_TYPES = [DOCUMENT_TYPES.TAX_DOCUMENT];

export const DOCUMENT_TYPES_EMPLOYERS_CAN_UPLOAD_FOR_COMPANY = [
  DOCUMENT_TYPES.DOCUMENT,
  DOCUMENT_TYPES.BUSINESS_VALIDATION_DOCUMENT,
  DOCUMENT_TYPES.EXPENSE_POLICY,
];

export const DOCUMENT_TYPES_EMPLOYEES_CAN_FILTER = [
  DOCUMENT_TYPES.CONTRACT,
  DOCUMENT_TYPES.DOCUMENT,
  DOCUMENT_TYPES.CONTRACTOR_INVOICE,
  DOCUMENT_TYPES.EXPENSE,
  DOCUMENT_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
  DOCUMENT_TYPES.ID,
  DOCUMENT_TYPES.NOTICE,
  DOCUMENT_TYPES.PAYSLIP,
  DOCUMENT_TYPES.PERSONAL,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.TIME_OFF,
  DOCUMENT_TYPES.OFFBOARDING,
];

export const DOCUMENT_TYPES_CONTRACTORS_CAN_FILTER = [
  DOCUMENT_TYPES.CONTRACT,
  DOCUMENT_TYPES.DOCUMENT,
  DOCUMENT_TYPES.CONTRACTOR_INVOICE,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.ID,
  DOCUMENT_TYPES.PERSONAL,
  DOCUMENT_TYPES.TIME_OFF,
  DOCUMENT_TYPES.NOTICE,
  DOCUMENT_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
];

export const DOCUMENT_TYPES_EMPLOYEES_CAN_UPLOAD = [
  DOCUMENT_TYPES.PERSONAL,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.CONTRACT,
];

export const DOCUMENT_TYPES_DIRECT_EMPLOYEES_CAN_UPLOAD = [
  DOCUMENT_TYPES.PERSONAL,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.EXTERNAL_CONTRACT,
  DOCUMENT_TYPES.OTHER,
];

export const DOCUMENT_TYPES_EMPLOYERS_CAN_UPLOAD_FOR_DIRECT_EMPLOYEES = [
  ...DOCUMENT_TYPES_DIRECT_EMPLOYEES_CAN_UPLOAD,
];

export const COMPANY_DOCUMENT_TYPES_ADMINS_CAN_UPLOAD = [
  DOCUMENT_TYPES.DOCUMENT,
  DOCUMENT_TYPES.CONTRACTOR_AGREEMENT,
  DOCUMENT_TYPES.BUSINESS_VALIDATION_DOCUMENT,
  DOCUMENT_TYPES.MSA_DOCUMENT,
  DOCUMENT_TYPES.SERVICE_ORDER,
  DOCUMENT_TYPES.SLA_DOCUMENT,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.VISA,
  DOCUMENT_TYPES.TERMS_OF_SERVICE,
  DOCUMENT_TYPES.COMPLIANCE_DOCUMENT,
];

const DOCUMENT_TYPES_ADMINS_CAN_REQUEST = [
  DOCUMENT_TYPES.ONBOARDING,
  DOCUMENT_TYPES.CONTRACT,
  DOCUMENT_TYPES.NOTICE,
  DOCUMENT_TYPES.PERSONAL,
  DOCUMENT_TYPES.TAX_DOCUMENT,
  DOCUMENT_TYPES.VISA,
];

export const MOBILITY_DOCUMENT_TYPES = [DOCUMENT_TYPES.MOBILITY, DOCUMENT_TYPES.ID];

// This is a frontend-only enum that allows to filter the employee documents
// table by a single "document source" filter that could be uploaded by multiple
// user roles.
export const DOCUMENT_SOURCES = {
  SELF: 'self',
  REMOTE: 'remote',
  EMPLOYER: 'employer',
  SERVICE_PROVIDER: 'service_provider',
};

const DOCUMENT_SOURCE_LABELS_FOR_EMPLOYEES = {
  [DOCUMENT_SOURCES.SELF]: 'Uploaded by you',
  [DOCUMENT_SOURCES.REMOTE]: 'Provided by Remote',
  [DOCUMENT_SOURCES.EMPLOYER]: 'Provided by the company',
  [DOCUMENT_SOURCES.SERVICE_PROVIDER]: 'Uploaded by the service provider',
};

export const UPLOADER_ROLE_TO_DOCUMENT_SOURCE_MAPPINGS = [
  { role: USER_TYPE.EMPLOYEE, source: DOCUMENT_SOURCES.SELF },
  { role: USER_TYPE.ADMIN, source: DOCUMENT_SOURCES.REMOTE },
  { role: USER_TYPE.EMPLOYER, source: DOCUMENT_SOURCES.EMPLOYER },
  { role: USER_TYPE.SERVICE_PROVIDER, source: DOCUMENT_SOURCES.SERVICE_PROVIDER },
  // We have some automation accounts that have user role as 'deleted'
  // Any files uploaded by these accounts can be labeled as if they were uploaded by a Remote Admin
  { role: USER_TYPE.DELETED, source: DOCUMENT_SOURCES.REMOTE },
];

export const DOCUMENT_UPLOADER_LABELS_FOR_EMPLOYEES = {
  [USER_TYPE.EMPLOYEE]: 'Uploaded by you',
  [USER_TYPE.ADMIN]: 'Provided by Remote',
  [USER_TYPE.EMPLOYER]: 'Provided by the company',
  [USER_TYPE.SERVICE_PROVIDER]: 'Uploaded by the service provider',
  // We have some automation accounts that have user role as 'deleted'
  // Any files uploaded by these accounts can be labeled as if they were uploaded by a Remote Admin
  [USER_TYPE.DELETED]: 'Provided by Remote',
};

export const DOCUMENT_UPLOADER_LABELS_FOR_ADMINS = {
  [USER_TYPE.EMPLOYEE]: 'Employee',
  [USER_TYPE.ADMIN]: 'Admin',
  [USER_TYPE.EMPLOYER]: 'Company',
  [USER_TYPE.SERVICE_PROVIDER]: 'Service provider',
  // We have some automation accounts that have user role as 'deleted'
  [USER_TYPE.DELETED]: 'Automation',
};

export const DOCUMENT_UPLOADER_LABELS_FOR_EMPLOYERS = {
  [USER_TYPE.EMPLOYEE]: 'Provided by employee',
  [USER_TYPE.ADMIN]: 'Provided by Remote',
  [USER_TYPE.EMPLOYER]: 'Uploaded by you',
  [USER_TYPE.SERVICE_PROVIDER]: 'Uploaded by the service provider',
  // We have some automation accounts that have user role as 'deleted'
  // Any files uploaded by these accounts can be labeled as if they were uploaded by a Remote Admin
  [USER_TYPE.DELETED]: 'Provided by Remote',
};

export const DOCUMENTS_PAGE_TABS_FOR_EMPLOYERS = {
  TEMPLATES: 'templates',
  DOCUMENTS: 'documents',
  FILES: 'files',
  TAX: 'tax',
  CONTRACTS: 'contracts',
  CONTRACTORS_1099_NEC_FORMS: 'contractors-1099-nec-forms',
};

export const allEmployeeDocumentOptions = generateSelectOptionsFromValues(
  Object.values(FILE_TYPES),
  DOCUMENT_TYPE_LABELS
);
export const employerDocumentOptionsForCompany = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_EMPLOYERS_CAN_UPLOAD_FOR_COMPANY,
  DOCUMENT_TYPE_LABELS
);

export const employerDocumentOptionsForDirectEmployee = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_EMPLOYERS_CAN_UPLOAD_FOR_DIRECT_EMPLOYEES,
  DOCUMENT_TYPE_LABELS
);

export const employeeDocumentOptionsForEmployeesUpload = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_EMPLOYEES_CAN_UPLOAD,
  DOCUMENT_TYPE_LABELS
);

export const employeeDocumentOptionsForDirectEmployeesUpload = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_DIRECT_EMPLOYEES_CAN_UPLOAD,
  DOCUMENT_TYPE_LABELS
);

export const employeeDocumentOptionsForEmployeesFilter = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_EMPLOYEES_CAN_FILTER,
  DOCUMENT_TYPE_LABELS
);
// For contractors a subset of employee-facing document types is relevant, e.g.
// it excludes payslips, expenses, etc.
export const employeeDocumentOptionsForContractorsFilter = generateSelectOptionsFromValues(
  DOCUMENT_TYPES_CONTRACTORS_CAN_FILTER,
  DOCUMENT_TYPE_LABELS
);
export const companyDocumentOptionsForAdmins = generateSelectOptionsFromValues(
  COMPANY_DOCUMENT_TYPES_ADMINS_CAN_UPLOAD,
  DOCUMENT_TYPE_LABELS
);
export const adminDocumentUploaderOptions = generateSelectOptions(
  DOCUMENT_UPLOADER_LABELS_FOR_ADMINS
);
export const employeeDocumentUploaderOptions = generateSelectOptions(
  DOCUMENT_SOURCE_LABELS_FOR_EMPLOYEES
);

export const mobilityDocumentOptions = generateSelectOptionsFromValues(
  MOBILITY_DOCUMENT_TYPES,
  DOCUMENT_TYPE_LABELS
);

export const getDocumentRequestOptions = ({
  canRequestMobilityTypeDocumentsFromEmployee,
  availableActivityCheckTypes,
}) => {
  const documentRequestOptions = [
    ...DOCUMENT_TYPES_ADMINS_CAN_REQUEST,
    ...availableActivityCheckTypes,
    ...(canRequestMobilityTypeDocumentsFromEmployee ? [DOCUMENT_TYPES.MOBILITY] : []),
  ].sort();

  return generateSelectOptionsFromValues(documentRequestOptions, DOCUMENT_TYPE_LABELS);
};

export const documentSubTypeOptions = generateSelectOptionsFromValues(
  Object.values(DOCUMENT_SUB_TYPES),
  DOCUMENT_SUB_TYPE_LABELS
);
