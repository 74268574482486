import isEmpty from 'lodash/isEmpty';
import { cloneElement } from 'react';
import type { ReactElement } from 'react';

import type { featureFlags } from '@/next/featureFlagNames';
import { isFlagActive } from '@/src/components/FeatureFlag/isFlagActive';

type FeatureName = keyof typeof featureFlags;

type Props = {
  name: FeatureName;
  children: ReactElement;
  [key: string]: unknown;
};

export function FeatureFlag({ name, children, ...props }: Props) {
  if (!isFlagActive(name)) {
    return null;
  }

  // Note: This is for cases where a parent component is cloning all of its children to pass new props to them.
  // For such cases, we need to pass these props to the child of <FeatureFlag />
  return isEmpty(props) ? children : cloneElement(children, { ...props });
}
