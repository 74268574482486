/* eslint-disable no-unused-vars, no-restricted-imports, no-redeclare */

import {
  ButtonIcon as ButtonIconNorma,
  ButtonInline as ButtonInlineNorma,
  Button as ButtonNorma,
  StandaloneLink as StandaloneLinkNorma,
} from '@remote-com/norma';

import { withNextLink } from '@/src/helpers/withNextLink';

export const Button = withNextLink(ButtonNorma);
export const ButtonIcon = withNextLink(ButtonIconNorma);
export const ButtonInline = withNextLink(ButtonInlineNorma);
export const StandaloneLink = withNextLink(StandaloneLinkNorma);
