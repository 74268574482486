import { useRouter } from 'next/router';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { ADMIN_DASHBOARD_ROUTE, DASHBOARD_ROUTE } from '@/src/constants/routes';
import { captureException } from '@/src/helpers/captureException';
import { isTest } from '@/src/helpers/general';

import type { FeatureFlagAttributeValue } from '../config';
import type { FeatureFlagUserProviderProps } from '../context';
import { FeatureFlagProvider } from '../context';

// These are the attributes supported  by the SDK
const ATTRIBUTE = {
  UserCountry: 'userCountry',
  UserType: 'userType',
  CompanySlug: 'companySlug',
} as const;

type SupportedAttribute = (typeof ATTRIBUTE)[keyof typeof ATTRIBUTE];

export interface EmployUserAttributes
  extends Record<SupportedAttribute, FeatureFlagAttributeValue> {}

interface EmployFeatureFlagProviderProps extends Pick<FeatureFlagUserProviderProps, 'config'> {
  /**
   * The account object that will be used to generate user attributes for Feature Flag audience targeting
   */
  account?: UserAccount;
}

/**
 * In theory, "account" should always be defined
 * when the user is visiting an auth-protected route.
 * However, in practice, it seems that there are cases where this does not happen.
 * This hook is both a safety check and a report for us to investigate.
 */
function useReportUserAttributes(props: {
  account: EmployFeatureFlagProviderProps['account'];
}): void {
  const { account } = props;

  const router = useRouter();
  const isTestEnv = isTest();

  // In test env, router is not defined (at least by default),
  // and we also don't need to report.
  if (isTestEnv) return;

  // Also good if the user is not visiting an auth-protected route
  const needAuth =
    router.pathname.includes(DASHBOARD_ROUTE) || router.pathname.includes(ADMIN_DASHBOARD_ROUTE);
  if (!needAuth) return;

  // We have the user attributes so it's good!
  if (account?.userAttributes) return;

  // At this point a missing user is visiting an auth-protected route,
  // so let's report
  const msg =
    'EmployFeatureFlagProvider: account.userAttributes should be provided for logged-in users';
  captureException(new Error(msg));
}

/**
 * EmployFeatureFlagProvider is a wrapper around the FeatureFlagProvider that provides a pre-defined set of
 * user attributes (see `EmployUserAttributes`) based on the account object.
 * It also provides a userId, which can be the account slug OR any other user identifier (an URL/Query param, a random ID, etc.).
 */
export const EmployFeatureFlagProvider: React.FC<EmployFeatureFlagProviderProps> = ({
  children,
  config,
  account,
}) => {
  useReportUserAttributes({ account });

  return (
    <FeatureFlagProvider
      config={config}
      userId={account?.slug ?? null}
      attributes={account?.userAttributes ?? {}}
    >
      {children}
    </FeatureFlagProvider>
  );
};
