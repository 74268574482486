import type { AxiosError, InternalAxiosRequestConfig } from 'axios';

export const NAME = 'onForbiddenFailInterceptor';

export type OnForbiddenFailParams = {
  /**
   * Route to redirect to in case of an authentication error.
   */
  redirectTo: string | (() => string);
  /**
   * API paths the redirect should be applied on.
   * If none are defined the redirect will be applied in all
   * cases of a 403 error.
   */
  paths?: string[];
};

function shouldRedirect(path?: string, paths?: string[]) {
  if (typeof paths === 'undefined') {
    return true;
  }

  return typeof path === 'undefined' ? false : paths.includes(path);
}

/**
 * Axios interceptor to handle requests failed because they are forbidden for the current user.
 */
export function onForbiddenFail({ redirectTo, paths }: OnForbiddenFailParams) {
  return async (
    error: AxiosError & {
      config: InternalAxiosRequestConfig | undefined;
    }
  ) => {
    const { config: originalReq, response } = error || {};

    if (
      typeof window !== 'undefined' &&
      response?.status === 403 &&
      shouldRedirect(originalReq?.url, paths)
    ) {
      // If a 401 request occurs, redirect to the given url.
      window.location.href = typeof redirectTo === 'function' ? redirectTo() : redirectTo;

      // Simulate in progress request
      // Prevents error while redirecting
      return new Promise(() => {});
    }

    // proceed as normal
    return Promise.reject(error);
  };
}
