import isEmpty from 'lodash/isEmpty';

export type AnalyticsConfig = {
  DATADOG_RUM_ENABLED: boolean;
};

let analyticsConfig: AnalyticsConfig | Record<string, never> = {};

export function setupAnalyticsConfig(config: AnalyticsConfig) {
  if (isEmpty(analyticsConfig)) {
    analyticsConfig = config;
  }
}

export function getAnalyticsConfig() {
  return analyticsConfig;
}
