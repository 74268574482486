import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const CleanOAPFromUrl = () => {
  const { query, replace, pathname } = useRouter();

  useEffect(() => {
    const { oap, ...restOfQuery } = query;
    if (oap) {
      replace({ pathname, query: restOfQuery });
    }
  }, [pathname, query, replace]);

  return null;
};
