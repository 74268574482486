import { marketingCookies } from '../constants';
import { googleTagManager } from '../googleTagManager';

const cookieControlBaseConfig = {
  apiKey: '99a1c0a70ecd52f5742d9b2146a6f69e294a1962',
  product: 'PRO_MULTISITE',
  necessaryCookies: [
    ...Object.entries(marketingCookies).map(([, value]) => value),
    // Rudderstack cookies
    'rl_page_init_referring_domain',
    'rl_page_init_referrer',
    'rl_group_trait',
    'rl_trait',
    'rl_user_id',
    'rl_session',
    'rl_group_id',
    'rl_anonymous_id',
  ],
  // optionalCookies are overwritten by Prismic - https://all-remote-content.prismic.io/documents~k=cookie_control_config&b=working&c=published&l=en-us/YxlDSxEAAHzJm8Ti/
  optionalCookies: [
    {
      name: 'analytics',
      label: 'Analytics',
      description:
        'Analytics cookies help us understand how visitors interact with our website by collecting and reporting information about them to us on an aggregate level without revealing actual visitor identity.',
      onAccept() {
        googleTagManager.trackCookieConsent({ accept: true, name: 'analytics' });
      },
      onRevoke() {
        googleTagManager.trackCookieConsent({ accept: false, name: 'analytics' });
      },
    },

    {
      name: 'marketing',
      label: 'Marketing',
      description:
        'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual visitor and thereby more valuable for publishers and third party advertisers.',
      onAccept() {
        googleTagManager.trackCookieConsent({ accept: true, name: 'marketing' });
      },
      onRevoke() {
        googleTagManager.trackCookieConsent({ accept: false, name: 'marketing' });
      },
    },
  ],
  statement: {
    description: 'Read',
    name: "Remote's Privacy Policy",
    url: 'https://remote.com/privacy-policy',
    updated: '15/07/2022',
  },
  initialState: 'notify',
  notifyDismissButton: false,
  position: 'right',
  theme: 'light',
  branding: {
    fontFamily: 'inherit',
    fontColor: '#617798',
    notifyFontColor: '#617798',
    fontSizeTitle: '18px',
    fontSizeIntro: '14px',
    fontSizeHeaders: '14px',
    fontSize: '14px',
    backgroundColor: '#fff',
    notifyBackgroundColor: '#fff',
    toggleText: '#fff',
    toggleColor: '#fff',
    toggleBackground: '#0061ff',
    removeIcon: false,
    removeAbout: true,
  },
  text: {
    necessaryTitle: 'Strictly Necessary',
    necessaryDescription:
      'Necessary cookies make our website operational by enabling core functions like page navigation and access to secure areas. This website cannot function properly without these cookies.',
    notifyTitle: 'Your choice regarding cookies on this site',
    notifyDescription:
      'Some of these cookies are essential, while others help us to improve our services and your experience by providing insights into how the site is being used.',
  },
};

export function cookieControlConfigFactory(prismicCookieControlValues, { buttonIconUrl } = {}) {
  const {
    necessaryTitle,
    necessaryDescription,
    statementDescription,
    statementName,
    statementUpdated,
    statementUrl,
    necessaryCookies: rawNecessaryCookies,
    optionalCookies,
    buttonIcon,
    notifyTitle,
    notifyDescription,
  } = prismicCookieControlValues;

  return {
    ...cookieControlBaseConfig,
    statement: {
      ...cookieControlBaseConfig.statement,
      ...(statementDescription && { description: statementDescription }),
      ...(statementName && { name: statementName }),
      ...(!!statementUrl?.url && { url: statementUrl.url }),
      ...(statementUpdated && {
        updated: new Date(Date.parse(statementUpdated)).toLocaleDateString('pt-PT'),
      }),
    },
    ...(!!optionalCookies.length && {
      optionalCookies: optionalCookies.map(({ name, label, cookies: rawCookies, description }) => ({
        name,
        label,
        cookies: rawCookies?.split(',') ?? [],
        description,
        onAccept() {
          googleTagManager.trackCookieConsent({ accept: true, name });
        },
        onRevoke() {
          googleTagManager.trackCookieConsent({ accept: false, name });
        },
      })),
    }),
    ...(rawNecessaryCookies && { necessaryCookies: rawNecessaryCookies.split(',') }),
    ...(buttonIcon && {
      branding: {
        ...cookieControlBaseConfig.branding,
        buttonIcon: buttonIconUrl ?? buttonIcon.url,
        buttonIconWidth: buttonIcon.dimensions.width,
        buttonIconHeight: buttonIcon.dimensions.height,
      },
    }),
    text: {
      ...cookieControlBaseConfig.text,
      ...(notifyTitle && {
        notifyTitle,
      }),
      ...(notifyDescription && {
        notifyDescription,
      }),
      ...(necessaryTitle && { necessaryTitle }),
      ...(necessaryDescription && { necessaryDescription }),
    },
  };
}
