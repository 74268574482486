/* eslint-disable prefer-destructuring */
export const space = [
  0, //    0
  2, //    1
  4, //    2
  8, //    3
  12, //   4
  16, //   5
  24, //   6
  32, //   7
  40, //   8
  56, //   9
  72, //   10
  88, //   11
  120, //  12
  152, //  13
  184, //  14
];
