import { LS_SELECTED_TOOL, SS_PANEL_OPEN } from '@/src/domains/remoteControlPanel/constants';
import { isDev } from '@/src/helpers/general';

/**
 * Runs the MSW server only in the development environment when the API Mocks panel is being used.
 */
export async function setupAPIMocksForDev() {
  const isClient = typeof window !== 'undefined';

  if (isClient && isDev()) {
    // We start the server automatically if the Remote Control Panel is open to support mocking requests that happen "on load".
    // The service worker must be running before they are performed.
    if (
      window.sessionStorage.getItem(SS_PANEL_OPEN) === 'true' && // Panel is open
      window.localStorage.getItem(LS_SELECTED_TOOL) === 'API Mocks' // Mocks tab is/was last selected
    ) {
      const { setupRuntimeMswServer } = await import(
        /* webpackMode: "lazy" */
        '@remote-com/data-layer-mocks-api/runtimeMswServer'
      );

      await setupRuntimeMswServer({});
    }
  }
}
