export function convertFileMbToBytes(value: number): number {
  return value * Math.pow(1024, 2);
}

export function convertFileBytesToMb(value: number): number {
  return value / Math.pow(1024, 2);
}

/**
 * Returns file extension based on name
 */
export function getFileExtension(
  file:
    | { name: string } // FileLike
    | string
    | undefined
): string | undefined {
  if (!file) {
    return undefined;
  }

  if (typeof file === 'string') {
    return file.split('.').pop();
  }

  return file.name.split('.').pop();
}

/**
 * Removes the extension from a file name
 * @example getFileNameWithoutExtension('file.txt') // Returns 'file'
 */
export function getFileNameWithoutExtension(fileName: string): string {
  const fileExtension = getFileExtension(fileName);

  if (!fileExtension) {
    return fileName;
  }
  return fileName.slice(0, -fileExtension.length - 1);
}

/**
 * @deprecated This is a duplicate of "getPreviewPageUrl",
 * originally from Employ > files > helpers.
 *
 * It is hard-forked here because the original implementation relies
 * on the whole routing module, which we cannot bring over.
 *
 * The dummy implementation here is intentional to reflect
 * the original chain of imports.
 *
 * @todo This should either be a props, or better yet, come from a context.
 * Think of it like a config that the consumer app (like Employ) should provide.
 * In other words, this should be top-down, not bottom-up.
 *
 * Good news: This is used in one place that also supposed to be removed.
 * See its usages.
 */
export const dangerouslyGetFilePreviewPageUrl = (params: { fileSlug: string }): string => {
  const { fileSlug } = params;
  const DASHBOARD_ROUTE = '/dashboard';
  const FILE_PREVIEW_ROUTE = `${DASHBOARD_ROUTE}/file-preview`;
  return `${FILE_PREVIEW_ROUTE}?fileSlug=${fileSlug}`;
};
