/*
 * Follows page view tracking example with google analytics:
 * https://github.com/vercel/next.js/blob/deprecated-main/examples/with-react-ga/pages/_app.js
 */
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { selectedTabHasChanged, trackAppcuesPageEvent } from './helpers';
import { useTrackPageView } from './hooks';

export const PageViewsTracker = ({ user }) => {
  const router = useRouter();
  const trackPageView = useTrackPageView({ user });
  const prevUrl = useRef('');

  /**
   * Shallow routing is when we change the url by changing the router
   * state but not loading a different page, with this we avoid triggering
   * page view events when the query string changes eg: table filters stored in url.
   */
  const handleRouteChange = (url, { shallow }) => {
    if (!shallow) {
      trackPageView(url);
      return;
    }

    //  Most url shallow changes (like table filters updating Query String) are dismissed,
    //  but for Tab changes, while  we still don't want to track it with a rudderstack
    //  page event, we want to consider it a page event within Appcues.
    if (selectedTabHasChanged(prevUrl.current, url)) trackAppcuesPageEvent();
  };

  useEffect(() => {
    /**
     * `routeChangeComplete` won't run for the first page load unless the query string is
     * hydrated later on, so here we log a page view if this is the first render and
     * there's no query string
     */
    if (!router.asPath.includes('?')) {
      trackPageView(router.asPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  useEffect(() => {
    const handleStorePreviousUrl = () => {
      prevUrl.current = router.asPath;
    };

    // listen when page starts changing and store current page before changing
    router.events.on('routeChangeStart', handleStorePreviousUrl);

    return () => {
      router.events.off('routeChangeStart', handleStorePreviousUrl);
    };
  }, [router.events, router.asPath]);

  return null;
};
