import type { HttpMethod } from './ApiServiceContext';
import type { DeletePath, DeleteRequest } from './DELETE.types';
import type { GetPath, GetRequest } from './GET.types';
import type { PatchPath, PatchRequest } from './PATCH.types';
import type { PostPath, PostRequest } from './POST.types';
import type { PutPath, PutRequest } from './PUT.types';
import { createServiceOptions } from './utils';

export const createMakeRequest = (apiService: any) => {
  /**
   * This function intentionally has loose types,
   * because the typing here would not be useful in practice,
   * while potentially causing huge issue in development.
   *
   * Note that end-user utilities like "makeGet" and "makePost"
   * are always typed fully at the apps (like Employ and Jobs).
   *
   * To learn more, see https://remote-com.slack.com/archives/CFEH4JTSM/p1731398304848619
   */
  function makeRequest(method: HttpMethod, url: string, params?: any) {
    const serviceOptions = createServiceOptions(url, method);
    const service = apiService(serviceOptions);
    return service({ ...params });
  }

  return {
    makeGetRequest: <KPath extends GetPath>(url: KPath, params?: GetRequest<KPath>) =>
      makeRequest('GET', url, params),
    makePostRequest: <KPath extends PostPath>(url: KPath, params?: PostRequest<KPath>) =>
      makeRequest('POST', url, params),
    makePutRequest: <KPath extends PutPath>(url: KPath, params?: PutRequest<KPath>) =>
      makeRequest('PUT', url, params),
    makePatchRequest: <KPath extends PatchPath>(url: KPath, params?: PatchRequest<KPath>) =>
      makeRequest('PATCH', url, params),
    makeDeleteRequest: <KPath extends DeletePath>(url: KPath, params?: DeleteRequest<KPath>) =>
      makeRequest('DELETE', url, params),
  };
};
