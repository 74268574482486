// Add data types to window.navigator ambiently for implicit use in the entire project. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { useGet } from '@remote-com/data-layer';
import { getRandomColorForInitialsImage } from '@remote-com/norma';
import type { ReactNode } from 'react';
import { useContext, useState, useEffect } from 'react';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { transformPermissions } from '@/src/domains/account/helpers';
import { setDOMUserInfo } from '@/src/domains/registration/auth/helpers/userData';
import { isUserOnMacOS } from '@/src/helpers/general';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import { transformLoginsResponse } from '@/src/services/User';
import type { $TSFixMe } from '@/types';

import type { Login, UserContextUser } from './context';
import UserContext from './context';

export type UserAccountResponse = UserAccount;

type TUserProvider = {
  user?: UserAccountResponse;
  children: ReactNode;
};

const UserProvider = ({ user, children }: TUserProvider) => {
  const [currentUser, setCurrentUser] = useState<{
    user?: UserContextUser;
    loginMethods?: Login;
  }>({ user });
  const [timeToSignOutRemaining, setTimeToSignOutRemaining] = useState<number | undefined>();
  const [channelReference, setChannelReference] = useState();

  const { refetch } = useGet('/api/v1/account', {
    options: {
      enabled: false,
      select: transformPermissions, // permissions data must be formatted to snake_case as done in /services/User#getUser
    },
  });

  const { data: loginMethods, refetch: refetchLogins } = useGet('/api/v1/account/logins', {
    options: {
      enabled: !!user,
      select: transformLoginsResponse,
    },
  });

  useEffect(() => {
    if (user) {
      const initialsColor = getRandomColorForInitialsImage(user.name);
      setCurrentUser({
        loginMethods,
        user: {
          ...user,
          isOnMacOS: isUserOnMacOS(),
          initialsColor,
        },
      });
    }
  }, [user, loginMethods]);

  async function refreshUser() {
    try {
      const [userData, loginMethodsData] = await Promise.all([refetch(), refetchLogins()]);
      if (userData?.data && loginMethodsData?.data) {
        const initialsColor = getRandomColorForInitialsImage(userData.data.data.name);
        const isOnMacOS = isUserOnMacOS();
        setCurrentUser({
          user: { ...userData.data.data, isOnMacOS, initialsColor },
          loginMethods: loginMethodsData?.data,
        });
        setDOMUserInfo({ ...userData.data.data, isOnMacOS, initialsColor });
        return userData.data.data;
      }
    } catch {
      // Continue
    }
  }

  function setInactivityTimeRemaining(time: number) {
    setTimeToSignOutRemaining(time);
  }

  function setChannelRef(ch: any) {
    setChannelReference(ch);
  }

  const value = {
    user: currentUser.user,
    loginMethods: currentUser.loginMethods,
    refreshUser,
    setInactivityTimeRemaining,
    timeToSignOutRemaining,
    setChannelRef,
    channelReference,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

/**
 * Performs a mutation an update user on success, both in React.context and in the DOM
 * @param {Function} mutationFn function that performs an asynchronous task and returns a promise.
 * @param {*} options useMutation options
 * @returns
 */
export function useMutationAndUpdateUser(
  mutationFn: () => Promise<any>,
  options: { onSuccess?: (data: $TSFixMe) => void; onError?: (err: $TSFixMe) => void } = {}
) {
  const { refreshUser } = useUserContext();
  return useCustomMutation(mutationFn, {
    ...options,
    onSuccess: async (data: $TSFixMe) => {
      try {
        await refreshUser?.();
        options.onSuccess?.(data);
      } catch (err) {
        options?.onError?.(err);
      }
    },
  });
}

export default UserProvider;
