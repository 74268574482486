import styled, { css } from 'styled-components';

import { Text } from './Text';

export const srOnlyCSS = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const SROnly = styled.span`
  ${srOnlyCSS}
`;

export const FieldDescription = styled(Text).attrs({
  forwardedAs: 'div',
  variant: 'xs',
  color: 'inputDescription',
})`
  white-space: pre-wrap;
`;

FieldDescription.defaultProps = {
  mt: 3,
};

export const FieldError = styled(Text).attrs({
  forwardedAs: 'div',
  variant: 'xs',
  color: 'negative.foreground',
  mt: 3,
})`
  white-space: initial;

  ${FieldDescription} {
    display: inline;
  }
`;

/**
 * In general we should prefer `styled` over `css`.
 * However, this is intentionally extracted because several places use it as a snippet.
 */
export const TextEllipsisCSS = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * This wraps a `span` instead of a `Text` for historical reason.
 * It is moved from Employ to Norma.
 * While it was in Employ, it was a `span` so we kept it here to be safe.
 */
export const TextEllipsis = styled.span`
  ${TextEllipsisCSS}
`;
