import defaultDevelopmentAppleTouchIcon from './images/default-development/apple-touch-icon.png';
import defaultDevelopmentFaviconPNG from './images/default-development/favicon.png';
// eslint-disable-next-line import/no-unresolved
import defaultDevelopmentFaviconSVG from './images/default-development/favicon.svg?url';
import defaultProductionAppleTouchIcon from './images/default-production/apple-touch-icon.png';
import defaultProductionFaviconPNG from './images/default-production/favicon.png';
// eslint-disable-next-line import/no-unresolved
import defaultProductionFaviconSVG from './images/default-production/favicon.svg?url';
import defaultStagingAppleTouchIcon from './images/default-staging/apple-touch-icon.png';
import defaultStagingFaviconPNG from './images/default-staging/favicon.png';
// eslint-disable-next-line import/no-unresolved
import defaultStagingFaviconSVG from './images/default-staging/favicon.svg?url';
import ukraineFaviconPNG from './images/ukraine/favicon.png';
// eslint-disable-next-line import/no-unresolved
import ukraineFaviconSVG from './images/ukraine/favicon.svg?url';

// NOTE: For the default icon, all variants need to be defined
export const defaultIcons = {
  development: {
    appleTouchIcon: defaultDevelopmentAppleTouchIcon.src,
    faviconPNG: defaultDevelopmentFaviconPNG.src,
    faviconSVG: defaultDevelopmentFaviconSVG,
  },
  staging: {
    appleTouchIcon: defaultStagingAppleTouchIcon.src,
    faviconPNG: defaultStagingFaviconPNG.src,
    faviconSVG: defaultStagingFaviconSVG,
  },
  sandbox: {
    appleTouchIcon: defaultProductionAppleTouchIcon.src,
    faviconPNG: defaultProductionFaviconPNG.src,
    faviconSVG: defaultProductionFaviconSVG,
  },
  production: {
    appleTouchIcon: defaultProductionAppleTouchIcon.src,
    faviconPNG: defaultProductionFaviconPNG.src,
    faviconSVG: defaultProductionFaviconSVG,
  },
};

// NOTE: For temporary icons, you don't have to define all icons.
// For example, to make an icon visible in browsers, set `faviconPNG` and `faviconSVG` only.
export const temporaryIcons = {
  ukraine: {
    faviconPNG: ukraineFaviconPNG.src,
    faviconSVG: ukraineFaviconSVG,
  },
};
