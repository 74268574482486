import { Box, Stack, Badge } from '@remote-com/norma';
import type { ElementType, ReactNode } from 'react';
import { Component } from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import styled from 'styled-components';

import Error5XXIllustration from '@/public/images/error/500.svg';
import { Button } from '@/src/components/Button';
import NoResults from '@/src/components/Table/Components/NoResults';
import { captureException } from '@/src/helpers/captureException';

const StatusBox = styled(Box)`
  ${({ theme }) => theme.typography.sm};
  margin: 0 auto;
`;

function ErrorIllustration() {
  return <Error5XXIllustration width="161" height="100" />;
}

type Props = {
  /** Component rendered when an exception is caught. Props passed:
   * - onRetry: Function
   * - retryFailed: Bool
   * - isRetrying: Bool
   */
  ErrorComponent?: ElementType;
  /** Optional callback for handling errors */
  handleError?: (error: unknown) => void;
  /** Optional callback for handling retries */
  onRetry?: () => void;
  children: ReactNode;
};

type State = {
  error?: unknown;
  hasRetried?: boolean;
  isRetrying?: boolean;
};

class BaseErrorBoundary extends Component<Props, State> {
  private retryAnimationTimeout?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      hasRetried: false,
      isRetrying: false,
    };
    this.retryAnimationTimeout = undefined;
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidCatch(error: unknown) {
    const { handleError } = this.props;

    if (typeof handleError === 'function') {
      // Trust nobody, not even yourself.jpg
      try {
        handleError(error);
        return;
      } catch (e) {
        captureException(e);
      }
    }

    captureException(error);
  }

  componentWillUnmount() {
    clearTimeout(this.retryAnimationTimeout);
  }

  onRetry() {
    this.setState({ isRetrying: true });

    // In some cases the error could happen synchronously on child component mount, so delay
    // remounting to help users register that they have indeed retried.
    clearTimeout(this.retryAnimationTimeout);
    this.retryAnimationTimeout = setTimeout(() => {
      if (typeof this.props.onRetry === 'function') {
        // Trust nobody, not even yourself.jpg
        this.props.onRetry();
      }

      this.setState({ error: null, hasRetried: true, isRetrying: false });
    }, 200);
  }

  render() {
    const { error, hasRetried, isRetrying } = this.state;
    const { ErrorComponent } = this.props;
    const retryFailed = hasRetried && !isRetrying;

    if (error) {
      if (ErrorComponent) {
        return (
          <ErrorComponent
            error={error}
            onRetry={() => this.onRetry()}
            retryFailed={retryFailed}
            isRetrying={isRetrying}
          />
        );
      }

      return (
        <Stack gap={5}>
          <NoResults
            Illustration={ErrorIllustration}
            title="Sorry, something went wrong on our end"
            description="This page failed to load. Please retry or contact your Remote representative."
            actions={
              <Button
                isLoading={isRetrying}
                // Keep the callback fn to preserve this at onRetry
                onClick={() => {
                  this.onRetry();
                }}
              >
                Retry
              </Button>
            }
          />
          {retryFailed && (
            <StatusBox color="lynch">
              <Badge type="error" label="Content failed to load again, please contact us" />
            </StatusBox>
          )}
        </Stack>
      );
    }
    return this.props.children;
  }
}

export const BlockErrorBoundary = (props: Props) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset: resetReactQueryErrorBoundary }) => (
        <BaseErrorBoundary {...props} onRetry={resetReactQueryErrorBoundary} />
      )}
    </QueryErrorResetBoundary>
  );
};
