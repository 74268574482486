/* eslint-disable import/no-extraneous-dependencies */
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';

import paginationAllowList from './pageSizeAllowList';
import { parseQueryFromUrl } from './queryParser';
/**
 * Query decoder that can be used option for parseQueryFromUrl.
 * @param {String} value - Value that can be decoded
 * @param {Function} defaultDecoder
 * @param {String} _charset
 * @param {String} type - Type can be "key" or "value"
 */
export function standardQueryDecoder(value, defaultDecoder, _charset, type) {
  if (type === 'key') {
    // For keys, we want to use a standard decoder to handle different array formats
    return defaultDecoder(value);
  }
  if (type === 'value') {
    // Solution reference:
    // https://github.com/ljharb/qs/issues/91#issuecomment-348481496

    // If there's no fraction part, then make sure it doesn't start with 0.
    // We want to preserve leading 0s as invoice numbers can start with 0.
    // See https://linear.app/remote/issue/BIL-364/first-digit-truncation-when-going-back-while-searching-by-invoice
    if (/^([0]|[1-9]\d*|\d*\.\d+)$/.test(value)) {
      return parseFloat(value);
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };

    if (value in keywords) {
      return keywords[value];
    }

    const sanitizedValue = value.replace(/\+/g, ' ');

    return decodeURIComponent(sanitizedValue);
  }

  return value;
}

/**
 * On some of the endpoints that return lists with pagination,
 * the list that is shown to the user comes as the value of a "data" key.
 * { data: ['red', 'green', 'blue', 'yellow'], page: 1, totalPages: 2 }
 * But others use a different key.
 * * { colors: ['red', 'green', 'blue', 'yellow'], page: 1, totalPages: 2 }
 * This helper receives an API response and allows for that key to be renamed,
 * returning an object with the list and pagination.
 * 🚨 Heads up! This is meant to be used with services that use the **makeServiceApi factory**
 * @param {Object} params
 */
export function getDataWithRenamedProperty(
  APIResponse,
  propertyThatHasTheData,
  targetName = 'data'
) {
  if (!APIResponse) {
    throw new Error('No APIResponse was passed.');
  }
  if (!propertyThatHasTheData) {
    throw new Error('No propertyThatHasTheData was passed.');
  }

  const { data: actualResponse } = APIResponse;
  const { [propertyThatHasTheData]: mainData, ...result } = actualResponse;

  return {
    ...result,
    [targetName]: mainData,
  };
}

export const APIResponseToState = ({ data: results = {}, isLoading }) => {
  const {
    data = [],
    totalPages,
    currentPage,
    page,
    // Total item count can be returned from backend's `Paginator` service,
    // see `total_count` option.
    totalCount,
    total,
    ...rest
  } = results;
  // Mapping API response to react-table props
  // totalPages -> pageCount, currentPage -> pageIndex, page -> pageIndex
  // first page on API has index 1, we have to map it to 0 for react-table
  const pageValue = currentPage ?? page;
  const pageIndex = pageValue ? Math.max(pageValue - 1, 0) : 0;
  const pageCount = totalPages || 0;
  // The key for totals can be `total_count` or `total`
  // See: `/api/v1/rivendell/contract-templates` vs `/api/v1/rivendell/employees`
  const resultsTotal = totalCount ?? total;

  return {
    data,
    isEmptyTable: data.length === 0 && !isLoading,
    pageIndex,
    pageCount,
    totalCount: resultsTotal,
    isLoading,
    metadata: rest,
  };
};

export function decodeQueryParam(param) {
  if (isString(param)) {
    try {
      return decodeURIComponent(param.replace(/\+/g, ' '));
    } catch {
      return param;
    }
  }

  return param;
}

/**
 * stateToAPIQuery takes react-table state(see Table component for details), and
 * converts filters, sortBy, pagination and globalFilter into appropriate
 * query parameters, following our backend API conventions.
 * @param {object} tableState
 *
 * @param {object} options
 *
 * Which queryKey to use to send search filter from the table. Defaults to `globalFilter`
 * @param {string} options.globalFilterQueryKey
 *
 * filterConfig is an object that allows transformation of how
 * filters are sent to the backend.
 *
 * It takes a map of filter key to the configuration, example
 *
 * In this example, a filter for column `status`, would be sent
 * to the backend as using `statuses` query parameter, and it's value will always be
 * an array:
 *
 * status: {
 *   key: 'statuses',
 *   type: 'array', // or scalar, auto, ignore
 * }
 *
 * In this example, a filter for column `status`, won't be sent to the backend.
 *
 * status: {
 *   type: 'ignore'
 * }
 *
 * @param {function} options.filterConfig
 * @returns
 */
export const stateToAPIQuery = (tableState, options = {}) => {
  const globalFilterQueryKey = options.globalFilterQueryKey || 'globalFilter';
  const filterConfig = options.filterConfig || {};

  const query = {};
  const sortBy = tableState.sortBy?.[tableState.sortBy.length - 1];

  if (sortBy) {
    // Preserve dots in sortBy parameter, e.g "someField.nestedField" is converted to "some_field.nested_field"
    query.sortBy = sortBy.id ? sortBy.id.split('.').map(snakeCase).join('.') : '';
    query.order = sortBy.desc ? 'desc' : 'asc';
  }

  if (tableState.globalFilter || isNumber(tableState.globalFilter)) {
    query[globalFilterQueryKey] = tableState.globalFilter;
  }

  if (!isNil(tableState.pageIndex)) {
    query.page = tableState.pageIndex + 1;
  }

  if (tableState.pageSize) {
    query.pageSize = tableState.pageSize;
  }

  const filterGroups = groupBy(tableState.filters || [], ({ id }) => id);

  Object.entries(filterGroups).forEach(([key, group]) => {
    const values = group.map(({ value }) => value);
    const newKey = filterConfig[key]?.key || key;

    switch (filterConfig[key]?.type) {
      case 'array':
        query[newKey] = values;
        break;
      case 'scalar':
        [query[newKey]] = values;
        break;
      case 'ignore':
        break;
      case 'auto':
      default:
        query[newKey] = values.length === 1 ? values[0] : values;
    }
  });

  return query;
};

export const supportedTableParams = ['filters', 'globalFilter', 'pageIndex', 'pageSize', 'sortBy'];

export const urlToTableState = (url, { uniqueId } = { uniqueId: null }) => {
  const queryFromUrl = parseQueryFromUrl(url, {
    decoder: standardQueryDecoder,
  });

  const deriveNestedQueryFromUrl = uniqueId ? queryFromUrl[uniqueId] : queryFromUrl;
  const tableState = pick(deriveNestedQueryFromUrl, supportedTableParams);

  return {
    ...tableState,
    ...(tableState.filters
      ? // Let's filter out these entries without { value } prop specified
        { filters: tableState.filters.filter((filter) => filter && 'value' in filter) }
      : {}),
  };
};

/**
 * Evaluates if a path is part of the paginationAllowList.
 *
 */
export const urlShouldUsePageSize = (path, urlPrefix) => {
  const fullPath = `${urlPrefix}${path}`;
  const allowListSet = new Set(paginationAllowList);
  // replace all instances of [...] with ? to match the format of the allowlist
  const transformedUrl = fullPath.replace(/\[.*?\]/g, '?');
  return allowListSet.has(transformedUrl);
};

/**
 * Validates that tableId is no greater than 5 characters.
 * We do this to preserve short urls.
 */
export const validateTableId = (tableId) => {
  return typeof tableId === 'string' && tableId.length <= 5;
};
