import type { ValueOf } from 'type-fest';

export const USER_PROFILE_EMPLOYMENT_TYPES = {
  EOR: 'eor',
  DIRECT: 'direct',
  CONTRACTOR: 'contractor',
  GLOBAL_PAYROLL: 'global_payroll',
} as const;

export type UserProfileEmploymentType = ValueOf<typeof USER_PROFILE_EMPLOYMENT_TYPES>;

export const USER_PROFILE_TYPES = {
  REMOTE_ADMIN: 'Remote Admin',
  COMPANY_MANAGER: 'Company Manager',
  CONTRACTOR: 'Contractor',
  FREELANCER: 'Freelancer',
  EMPLOYEE: 'Employee',
  SERVICE_PROVIDER: 'Service Provider',
  CANDIDATE: 'Candidate',
} as const;

export type UserProfileType = ValueOf<typeof USER_PROFILE_TYPES>;
