import type { UseMutationOptions, UseMutationResult } from 'react-query';
// eslint-disable-next-line  remote/prefer-using-the-data-layer
import { useMutation } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import type { PatchRequest, PatchResponse, PatchPath } from '../PATCH.types';

export type UsePatchOptions<KPath extends PatchPath, TError = unknown, TContext = unknown> = Omit<
  UseMutationOptions<PatchResponse<KPath>, TError, PatchRequest<KPath>, TContext>,
  'mutationFn'
>;

const METHOD = 'PATCH';

export function usePatch<KPath extends PatchPath, TError = unknown, TContext = unknown>(
  path: KPath,
  options: UsePatchOptions<KPath, TError, TContext> = {}
): UseMutationResult<PatchResponse<KPath>, TError, PatchRequest<KPath>, unknown> {
  const { createService } = useApiService();
  const service = createService(path, METHOD);

  return useMutation((params) => service(params), options);
}
