import { createGlobalStyle } from 'styled-components';

export const CookieScriptsStyleOverrides = createGlobalStyle`
  #ccc #ccc-module,
  #ccc #ccc-notify {
    .ccc-link {
      font-weight: 600;

      span {
        color: var(--colors-irisBlue) !important;
        fill: var(--colors-irisBlue) !important;
      }
    }

    #ccc-necessary-title,
    .optional-cookie-header {
      color: #000 !important;
    }

    #ccc-recommended-settings,
    #ccc-reject-settings,
    #ccc--button {
      font-weight: 500;
      border-radius: 20px;
    }

    #ccc-recommended-settings,
    #ccc-notify-accept {
      background-color: var(--colors-irisBlue) !important;
      border-color: var(--colors-irisBlue) !important;

      > span {
        color: #fff !important;
      }
    }
    #ccc-reject-settings,
    #ccc-notify-reject {
      border-color: var(--colors-irisBlue) !important;
    }
  }

  #ccc {
    #ccc-icon {
      margin: 0.5em;
      bottom: ${(props) => (props.$hasCaptcha ? '75px' : '0')};

      &[aria-expanded="true"] {
        visibility: hidden;
      }
    }
  }`;
