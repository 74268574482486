/* eslint-disable remote/disallow-data-layer-endpoint-assignment */
module.exports = {
  '/api/v1/account': { serviceOptions: { asFormData: false } },
  '/api/v1/account/contracts/[contractSlug]/employment-eligibility': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/account/email': { serviceOptions: { asFormData: false } },
  '/api/v1/account/nium/beneficiaries/[slug]': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/career-development/journal/notes/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contract-documents/[contractDocumentSlug]/sign': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employee/expenses/[slug]': {
    serviceOptions: { asFormData: true, transformParamsOptions: { filesAsArray: true } },
  },
  '/api/v1/employee/notifications/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/remote-work-request/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/time-preferences/[timePreferenceSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/timesheets/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/1099-tax-forms/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/benefit-renewal-requests/[slug]/benefit-renewal-response': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/employer/benefits/employment/[slug]/benefit-offers': {
    serviceOptions: {
      asFormData: false,
      deepTransformResponse: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/close': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances/[snapshotInstanceSlug]':
    { serviceOptions: { asFormData: false } },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/publish': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/checklists/[checklistSlug]/tasks/[taskSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/checklists/[checklistSlug]/tasks/[taskSlug]/order': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/company/sso': { serviceOptions: { asFormData: true } },
  '/api/v1/employer/company/team-members/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/compliance-profile': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contract-documents/[contractDocumentSlug]/form': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-documents/[slug]': {
    serviceOptions: { asFormData: false, transformParamsOptions: { exclude: ['editor_state'] } },
  },
  '/api/v1/employer/contract-documents/[slug]/signatory': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-documents/bulk-update-company-signatories': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-templates/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contractor-invoices': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/custom-field-definitions/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/custom-field-values': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/custom-field-values/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/contract-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/direct-offboardings/[offboardingSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/emergency-contact': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/legal-entity': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/import-jobs/[slug]/rows/[rowNumber]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[id]/key': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/field-mappings': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/employer/leave-policies/[policySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/archive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/auto-pay-configs/[purpose]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/unarchive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/mobility/remote-work-request/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/mobility/travel-letter-request/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/onboarding-case': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/nium-kyb/onboarding-case/kyc': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/nium-kyb/rfis/[rfiSlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/notifications/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/onboarding/company': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/outstanding-payments/[outstandingPaymentSlug]/mark-collecting': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/validate': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/payroll-runs/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/projects/[projectSlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/scheduled-contractor-invoices/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/timesheets/[timesheetSlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/reset-password': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/annual-plans/[planSlug]': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/benefit-costs/benefits/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-details/[countrySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-groups/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/benefit-plans/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefit-tiers/[slug]': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['errors.benefit_tier_plans'] },
    },
  },
  '/api/v1/rivendell/benefit-tiers/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefits/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/benefits/batch': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/benefits/package-manager/benefit-groups/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/carriers/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/companies/[companySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers/[companyProductTierSlug]/discounts':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/companies/[companySlug]/notes/[noteSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/severance-policy': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[slug]/bank-account': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-details/benefits/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/contract-documents/[slug]': {
    serviceOptions: { asFormData: false, transformParamsOptions: { exclude: ['input'] } },
  },
  '/api/v1/rivendell/contract-templates/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contracts/[contractSlug]/contract-eligibility': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/countries/[countrySlug]/automation-contract-template': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/countries/[countrySlug]/severance-policy': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/credit-note-pdf/[creditNoteSlug]/reprint': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/custom-field-definitions/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/data-exports/templates/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/default-benefit-offers/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/employee-data-elements/[dataElementSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-data-elements/batch': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-pay-elements/batch': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/legal-entity': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/product': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/expenses/[expenseSlug]': {
    serviceOptions: { asFormData: true, transformParamsOptions: { filesAsArray: true } },
  },
  '/api/v1/rivendell/expiring-documents/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/files/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/global-payroll-offboardings/[offboardingSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/holidays/[holidaySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/implementation-fees/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/import-jobs/[slug]/rows/[rowNumber]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/inter-company-invoices/[slug]': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/invoice-reports/[slug]/finalize': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/invoice-reports/finalize': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/knowledge-database/attendance-policies/[policySlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/core-benefits/[benefitSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/country-salary-configurations/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/employer-contributions/[contributionSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/regions/[regionSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/timeoff-policies/[policySlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/administrative-details': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        // Legal entity admin details for IND include `pt_form5_sa` (PT Form5 Signing Authority) field.
        exclude: ['pt_form5_sa'],
      },
    },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/archive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/bank-account': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/collections-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/net-pay-variance-threshold': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/notes/[noteSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/onboarding/[onboardingSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/unarchive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-element-templates/[payElementSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-elements/[payElementSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/mobility/[contractEligibilitySlug]/mobility-assistance-status/[mobilityAssistanceSlug]':
    { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/offboardings/[offboardingSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/outstanding-refunds/mark_applied_off_platform/[refundSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-calendars/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-integrations/generate-employee-psp-id': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-outputs/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-outputs/batch-update': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-payroll-overviews/[slug]/review': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/gross-to-net-files/[fileSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/reconciliation/legal-entity-pay-elements/mute': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/billing/memos/[memoSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/fx-rates': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payslips/batch-apply-all': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payslips/batch-update-all': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payslips/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payslips/batch-update': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payslips/parsing-rules': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/persisted-config-settings/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/pricing/pricing-plans/[pricingPlanSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/pricing/team-plan-fees/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/remote-invoices/[invoiceSlug]/reprint': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/remote-paid-services/[serviceCostSlug]': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/remote-work-request/[requestSlug]': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/risk-reserves/[slug]/proof-of-payment': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/tax-notes/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/team-plans/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/team-plans/[slug]/rolloff-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/team-plans/[teamPlanSlug]/seats/[seatSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/travel-letter-request/[requestSlug]': { serviceOptions: { asFormData: true } },
  '/api/v1/rivendell/users/[userSlug]/notes/[noteSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/vat-groups/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/table-views/[tableViewSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/unlock-account': { serviceOptions: { asFormData: false } },
  '/api/v1/updates': { serviceOptions: { asFormData: false } },
  '/api/v1/updates/notifications': { serviceOptions: { asFormData: false } },
  '/api/v1/updates/notifications/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v2/rivendell/knowledge-database/facts/[factIdentifier]': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        deep: false,
        exclude: ['metaschema'],
      },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[factIdentifier]/fact-values/[valueSlug]/version/[versionSlug]':
    { serviceOptions: { asFormData: false } },
  '/boba/api/v1/admin/companies/[companySlug]/profiles': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/admin/companies/[companySlug]/profiles/logos': {
    serviceOptions: { asFormData: true, useAuthentication: true },
  },
  '/boba/api/v1/admin/jobs/[jobSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/company-profiles': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[jobSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
};
