import { Component } from 'react';

import Error5XXIllustration from '@/public/images/error/500.svg';
import { ErrorPage } from '@/src/components/Ui/Error';
import { captureException } from '@/src/helpers/captureException';

export class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.clearError = this.clearError.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    captureException(error);
  }

  clearError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          siteTitle="Error"
          image={<Error5XXIllustration />}
          onBackButtonClick={this.clearError}
          trackNavigation={['dashboard']}
        />
      );
    }

    return this.props.children;
  }
}
