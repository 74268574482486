// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneUser = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M7.25 6a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z" /><path d="M8.95 13.25a5.7 5.7 0 0 0-5.7 5.7c0 2.1 1.7 3.8 3.8 3.8h9.9c2.1 0 3.8-1.7 3.8-3.8a5.7 5.7 0 0 0-5.7-5.7h-6.1Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneUser);
export { ForwardRef as IconV2DuotoneUser };