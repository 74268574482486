import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import getConfig from 'next/config';

import type { EngineeringTeamName } from '../domains/governance/teams';
import { createOwnershipContextForTeams } from '../domains/governance/utils';

// Ignored to avoid false positives with auth, non existing end points or wrong user data
const IGNORED_STATUSES = [401, 404];

const isRelevantError = (exception: unknown) => {
  const responseStatus = (exception as AxiosError)?.response?.status;

  // we might get strings or errors that weren't caused by an HTTP request
  if (responseStatus === undefined) {
    return true;
  }

  return !IGNORED_STATUSES.includes(responseStatus);
};

type CaptureExceptionOptions = {
  ownership?: EngineeringTeamName[];
};

/**
 * Adds an error to the RUM error tracking with an optional ownership context.
 * Note: DD RUM docs consider exception as type unknown, probably because error types can't be inferred most of the time.
 * We'll need to support multiple error types and use guard clauses to check for them.
 * @param exception - The error to add to the RUM error tracking (it can be a string or an AxiosError).
 * @param options - The options for the error capture.
 */
export const captureException = (exception: unknown, options: CaptureExceptionOptions = {}) => {
  const {
    publicRuntimeConfig: { DATADOG_RUM_ENABLED },
  } = getConfig() || {};

  if (DATADOG_RUM_ENABLED) {
    let error = exception;
    const context: Record<string, unknown> = {};

    if (typeof exception === 'string') {
      error = new Error(exception);
    } else if (exception instanceof AxiosError) {
      error = exception;

      // If it's an AxiosError, we add the response status code to the context.
      if (exception.response?.status) {
        context.responseStatusCode = exception.response.status;
      }
    }

    // If an ownership is provided, we build the ownership property and add it to the error context.
    if (options.ownership) {
      const ownershipContext = createOwnershipContextForTeams(options.ownership);
      if (ownershipContext) {
        context.ownership = ownershipContext;
      }
    }

    datadogRum.addError(error, context);
  }
};

/**
 * Adds an HTTP error to the RUM error tracking (if the status code is considered relevant).
 * Note: DD RUM docs consider exception as type unknown, probably because error types can't be inferred. We'll
 * need to support multiple types of errors and use some guard clauses to check for them.
 * @param {unknown} exception - The error to add to the RUM error tracking (it can be a string or an AxiosError).
 */
export const captureHTTPException = (exception: unknown) => {
  if (isRelevantError(exception)) {
    captureException(exception);
  }
};
