import { fonts } from '../fonts';
import { shadows } from '../shadows';

import { colors } from './colors';
import { typography } from './typography';

export const theme = {
  colors,
  typography,
  shadows,
  fonts,
  version: 'v1',
};
