import { datadogRum } from '@datadog/browser-rum';
import { WebSocketProvider as WebSocketProviderDataLayer } from '@remote-com/data-layer';
import getConfig from 'next/config';
import type { PropsWithChildren } from 'react';

import type { UserAccountResponse } from '@/src/components/UserProvider';
import { isDev } from '@/src/helpers/general';
import { useSignOut } from '@/src/hooks/useSignOut';

const {
  publicRuntimeConfig: { WS_BASE_URL },
} = getConfig();

type Props = PropsWithChildren<{
  user: UserAccountResponse;
}>;

type ExceptionType = Error & {
  reason?: string;
  message?: string;
};

const options = {
  // Default time used by phoenix
  longPollFallbackMs: isDev() ? null : 2500,
};

export const WebSocketProvider = ({ user, children }: Props) => {
  const signOut = useSignOut();

  const handleWebSocketError = (exception: ExceptionType) => {
    if (exception?.reason === 'unauthorized') {
      signOut();
    } else {
      datadogRum.addError(exception);
    }
  };

  return (
    <WebSocketProviderDataLayer
      isUserAuthenticated={!!user}
      onError={handleWebSocketError}
      options={options}
      wsBaseUrl={WS_BASE_URL}
    >
      {children}
    </WebSocketProviderDataLayer>
  );
};
