import { useRouter } from 'next/router';
import { useEffect } from 'react';

// Pentester identified potential issue with ?returnPath parameter https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/8913
// This is a global listener that removes returnPath from query if it's not a relative path
export const ReturnPathQueryHandler = () => {
  const router = useRouter();

  useEffect(() => {
    // use router.query to get any dynamic route params
    // not in URL
    const { returnPath, ...rest } = router.query;
    if (returnPath && !returnPath.startsWith('/')) {
      router.replace(
        {
          pathname: router.pathname,
          query: rest,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  return null;
};
