import {
  blueLight,
  brand,
  cyan,
  green,
  greenLight,
  grey,
  orange,
  pink,
  purple,
  red,
  yellow,
} from './palettes';

const punctualCompatibilities = {
  /**
   * Deprecated colors (removed)
   * Don't "fix" the color values. We've intentionally set up incorrect
   * colors to discourage use of these v1 color names.
   */
  turquoise: grey[900], // cyan[500]
  royalPurple: grey[900], // purple[900]
  pigeon: grey[900], // grey[300]
  mischka: grey[900], // grey[200]
  iceberg: grey[900], // cyan[200]
  forget: grey[900], // orange[200]
  feta: grey[900], // greenLight[100]
  ginFizz: grey[900], // yellow[100]
  cosmos: grey[900], // red[200]
  selago: grey[900], // brand[50]
  geyser: grey[900], // grey[300]
  athensGray: grey[900], // grey[200]
  alabaster: grey[900], // grey[50]
  governor: grey[900], // brand[900]
  moonRaker: grey[900], // brand[200]
  foam: grey[900], // cyan[200]
  spindle: grey[900], // brand[300]
  pattensBlue: grey[900], // brand[100]
  purpleMinsk: grey[900], // brand[900]
  wispPink: grey[900], // pink[200]
  zircon: grey[900], // background.base
  catskillWhite: grey[900], // grey[100]
  linkWater: grey[900], // background.base
  heather: grey[900], // grey[300],
  heather_15: grey[900], // grey[100],
  cabaret: grey[900], // fuchsia[800],
  cabaret_15: grey[900], // fuchsia[200],
};

// Deprecated color (removed from codebase)
const chambrayCompatibility = {
  chambray: grey[900], // brand[900] v1 occurrences removed from codebase
  chambray_15: grey[900], // brand[50] v1 occurrences removed from codebase
};

const darkBlueCompatibility = {
  darkBlue: grey[900],
  darkBlue_15: grey[200],
  darkBlue_30: grey[300],
  darkBlue_45: grey[400],
  darkBlue_60: grey[500],
  darkBlue_75: grey[600],
  darkBlue_90: grey[800],
};

const darkTurquoiseCompatibility = {
  darkTurquoise: cyan[700],
  darkTurquoise_15: cyan[100],
};

const irisBlueCompatibility = {
  irisBlue: brand[600],
  irisBlue_15: brand[50],
  irisBlue_30: brand[100],
  irisBlue_45: brand[200],
  irisBlue_60: brand[300],
  irisBlue_75: brand[400],
  irisBlue_90: brand[500],
};

const redPinkCompatibility = {
  redPink: red[600],
  redPink_15: red[200],
  redPink_30: red[300],
  redPink_75: red[400],
};

const salmonCompatibility = {
  salmon: orange[400],
  salmon_15: orange[100],
  salmon_30: orange[200],
  salmon_75: orange[300],
};

const violetCompatibility = {
  violet: purple[600],
  violet_15: purple[100],
  violet_30: purple[200],
};

const candlelightCompatibility = {
  candlelight: yellow[400],
  candlelight_15: yellow[100],
  candlelight_30: yellow[200],
  candlelight_75: yellow[300],
};

const mustardCompatibility = {
  mustard: yellow[400],
  mustard_15: yellow[100],
  mustard_30: yellow[200],
  mustard_75: yellow[300],
};

const radianceCompatibility = {
  radiance: brand[500],
  radiance_15: brand[100],
  radiance_30: brand[200],
  radiance_75: brand[400],
};

const ceruleanCompatibility = {
  cerulean: blueLight[500],
  cerulean_30: blueLight[300],
  cerulean_15: blueLight[200],
};

const ceriseCompatibility = {
  cerise: pink[600],
  cerise_15: pink[200],
};

const deYorkCompatibility = {
  deYork: green[500],
  deYork_15: green[100],
  deYork_30: green[200],
};

const coniferCompatibility = {
  conifer: greenLight[500],
  conifer_15: greenLight[100],
  conifer_30: greenLight[200],
  conifer_75: greenLight[400],
};

const bayouxCompatibility = {
  bayoux: grey[600],
  bayoux_15: grey[200],
  bayoux_30: grey[300],
  bayoux_45: grey[400],
};

const lynchCompatibility = {
  lynch: grey[500],
  lynch_15: grey[50],
  lynch_30: grey[100],
  lynch_45: grey[200],
  lynch_60: grey[300],
  lynch_75: grey[400],
};

const formColorsCompatibility = {
  inputValue: grey[900],
  inputPlaceholder: grey[600],
  inputLabel: grey[600],
  inputLabelDisabled: grey[300],
  inputDescription: grey[500],
  inputBorder: grey[300],
  inputBorderHover: grey[500],
  inputBorderFocus: brand[600],
  inputBorderDisabled: grey[300],
  inputGroupLabel: grey[900],
  inputGroupDescription: grey[500],
  error: red[700],
};

export const v1ColorCompatibilities = {
  ...chambrayCompatibility,
  ...darkBlueCompatibility,
  ...darkTurquoiseCompatibility,
  ...punctualCompatibilities,
  ...irisBlueCompatibility,
  ...redPinkCompatibility,
  ...salmonCompatibility,
  ...violetCompatibility,
  ...candlelightCompatibility,
  ...mustardCompatibility,
  ...radianceCompatibility,
  ...ceruleanCompatibility,
  ...ceriseCompatibility,
  ...deYorkCompatibility,
  ...coniferCompatibility,
  ...bayouxCompatibility,
  ...lynchCompatibility,
  ...formColorsCompatibility,
};
