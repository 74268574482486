import {
  FILE_SPREADSHEET_EXTENSIONS as SPREADSHEET_EXTENSIONS,
  FILE_WORD_DOCUMENTS_EXTENSIONS as WORD_DOCUMENTS_EXTENSIONS,
  FILE_MAX_SIZE_BYTES as MAX_FILE_SIZE_BYTES,
  FILE_MAX_SIZE_MB as MAX_MB_FILE_SIZE,
  FILE_WORD_DOCUMENTS as WORD_DOCUMENTS,
  FILE_PDF_ONLY_FORMAT as PDF_ONLY_FORMAT,
  FILE_DOCUMENT_SUPPORTED_FORMATS as DOCUMENT_SUPPORTED_FORMATS,
} from '@remote-com/norma';

import { convertBytesToMb } from '@/src/domains/files/helpers';
import { joinArray } from '@/src/helpers/general';

export {
  SPREADSHEET_EXTENSIONS,
  WORD_DOCUMENTS_EXTENSIONS,
  MAX_MB_FILE_SIZE,
  MAX_FILE_SIZE_BYTES,
  WORD_DOCUMENTS,
  PDF_ONLY_FORMAT,
  DOCUMENT_SUPPORTED_FORMATS,
};

export const CSV_ONLY_FORMAT = 'text/csv';

export const IMAGES = ['image/jpg', 'image/jpeg', 'image/png'];

export const LEGACY_SPREADSHEET_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const SPREADSHEETS = [
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const SPREADSHEETS_MIME_TYPES = Object.keys(SPREADSHEET_EXTENSIONS);

export const WORD_DOCUMENTS_MIME_TYPES = Object.keys(WORD_DOCUMENTS_EXTENSIONS);

export const CERTIFICATES = ['.pem', '.crt', '.ca-bundle', '.txt', '.cert'];

export const HTML_DOCUMENTS = ['.html', 'text/html'];

export const PAYROLL_OUTPUT_SUPPORTED_FORMATS = [CSV_ONLY_FORMAT];

export const DEFAULT_SUPPORTED_FORMATS = [PDF_ONLY_FORMAT, ...IMAGES];

export const DOC_SUPPORTED_FORMATS = [...WORD_DOCUMENTS, ...SPREADSHEETS];

export const PAYSLIP_SUPPORTED_FORMATS = [PDF_ONLY_FORMAT];

export const INVOICE_SUPPORTED_FORMATS = [PDF_ONLY_FORMAT];

export const UPLOAD_DOCUMENT_SUPPORTED_FORMATS = [
  PDF_ONLY_FORMAT,
  ...WORD_DOCUMENTS,
  ...SPREADSHEETS,
  ...IMAGES,
];

export const DEFAULT_FILE_UPLOAD_DESCRIPTION = `PDF, JPG or PNG file. Maximum file size of ${MAX_MB_FILE_SIZE} MB.`;

export const BULK_UPLOAD_SUPPORTED_FORMATS = [CSV_ONLY_FORMAT];
/**
 *
 * @param {Array} supportedFiles array of strings with supported file extesions. E.g ['.pdf', '.doc', '.xls']
 * @returns {String} file upload description. Eg ".pdf, .doc or .xls file. Maximum file size of 20 MB."
 */
export const buildFileUploadDescription = ({
  extensions,
  max_size: maxSize,
  displayMaxSize = true,
} = {}) => {
  if (!Array.isArray(extensions)) {
    return DEFAULT_FILE_UPLOAD_DESCRIPTION;
  }

  const extensionsBeautified = extensions.map((name) => name.replace('.', '').toUpperCase());
  const firstSentence = `${joinArray(extensionsBeautified, { lastDelimiter: 'or' })} file`;

  return `${firstSentence}.${
    displayMaxSize ? ` Maximum file size of ${convertBytesToMb(maxSize)} MB.` : ''
  }`;
};
