import camelcaseKeys from 'camelcase-keys';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import { flushSync } from 'react-dom';
import { object } from 'yup';

import { friendlyKeyToLabel } from '@/src/helpers/general';
// Can't import because of dependency cycle
// import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';

export function getFieldsForCountry({ countryCode = null, fieldsByCountry }) {
  if (!fieldsByCountry) {
    throw new Error('No fieldsByCountry object passed.');
  }

  if (!fieldsByCountry.DEFAULT) {
    throw new Error('The fieldsByCountry object does not have a DEFAULT property.');
  }

  return fieldsByCountry[countryCode] || fieldsByCountry.DEFAULT;
}

export function getSchema(fields = []) {
  const newSchema = {};

  fields.forEach((field) => {
    if (field.schema) {
      if (field.name) {
        newSchema[field.name] = field.schema;
      } else {
        Object.assign(newSchema, getSchema(field.fields));
      }
    }
  });

  return newSchema;
}

// noSortEdges is the second parameter of shape() and ignores the order of the specified field names
// so that the field order does not matter whem a field relies on another one via when()
// Docs https://github.com/jquense/yup#objectshapefields-object-nosortedges-arraystring-string-schema
// Explanation https://gitmemory.com/issue/jquense/yup/720/564591045
export function getNoSortEdges(fields = []) {
  return fields.reduce((list, field) => {
    if (field.noSortEdges) {
      list.push(field.name);
    }
    return list;
  }, []);
}

export function getValidationSchema({ fields = [] }) {
  return object().shape(getSchema(fields), getNoSortEdges(fields));
}

export function getFieldsFromFiles(arrayOfFiles) {
  return arrayOfFiles.reduce((output, file) => {
    // Ignore null subType
    if (!file.subType) {
      return output;
    }

    const subType = camelCase(file.subType);
    const existingFiles = output[subType] || [];
    existingFiles.push(file);
    output[subType] = existingFiles;
    return output;
  }, {});
}

export function getValidFileFields(fields) {
  // If the value is a string or boolean, its not valid
  // If is an array or undefined, its valid

  return Object.entries(fields).reduce((validFields, [key, value]) => {
    if (typeof value !== 'string' && typeof value !== 'boolean') {
      validFields[key] = value;
    }

    return validFields;
  }, {});
}

export function getFieldsWithoutApiFiles(fields) {
  return Object.entries(fields).reduce((output, [key, files = []]) => {
    const filesWithoutSlug = files.filter((file) => !file.slug);
    if (filesWithoutSlug.length > 0) {
      output[key] = filesWithoutSlug;
    }
    return output;
  }, {});
}

export function getFileStatuses(fields) {
  // If the value is a non empty array, it's marked as `file-uploaded`
  // If is an empty array, it's marked as `file-missing`
  // Any other values are ignored

  return Object.entries(fields).reduce((validFields, [key, value]) => {
    if (Array.isArray(value)) {
      validFields[camelCase(key)] = value.length ? 'file-uploaded' : 'file-missing';
    }

    return validFields;
  }, {});
}

export function getOksAndErrorsFromResponse(response) {
  const data = camelcaseKeys(response?.data, { deep: true });

  const keys = Object.keys(data);
  const result = {
    oks: [],
    errors: [],
  };

  keys.forEach((key) => {
    const processedField = {
      fieldName: key,
      ...data[key],
    };
    const target = data[key].status === 'error' ? 'errors' : 'oks';

    result[target].push(processedField);
  });

  return result;
}

export function getFieldsToUpload(fields, alreadyUploadedFields) {
  const keys = Object.keys(fields);
  const result = {};

  keys.forEach((key) => {
    if (!alreadyUploadedFields.includes(camelCase(key))) {
      result[key] = fields[key];
    }
  });

  return result;
}

export function getOptionsFromResponseList(list) {
  return list?.map(({ slug, name }) => ({ value: slug, label: name }));
}

/**
 * @param {Array<{value: string, label: React.Node}> | undefined} list - The list of options.
 * @param {string | undefined | null}  matchSlug - The value to match against the list.
 */
export function getLabelFromOptionsList(list, matchSlug) {
  return list?.find(({ value }) => value === matchSlug)?.label;
}

export function getLabelFromUserList(list, matchSlug) {
  return list?.find(({ slug }) => slug === matchSlug)?.name;
}

export function generateSelectOptionsFromSlugName(list) {
  if (!list || list.length === 0) return [];

  return list.map(({ slug, name }) => ({
    value: slug,
    label: name,
  }));
}

/**
 * @param {Record<string, string> | { [k: string]: JSX.Element; }} list
 * @param {boolean=} alphabeticalOrder
 * @returns {{value: string; label: React.ReactNode}[]}
 */
export function generateSelectOptions(list, alphabeticalOrder) {
  const options = Object.entries(list).map(([value, label]) => ({
    value,
    label,
  }));

  if (alphabeticalOrder) {
    return sortBy(options, (op) => op.label);
  }
  return options;
}

/**
 * @param {Record<string, string> | { [k: string]: JSX.Element; }} list
 * @param {boolean=} alphabeticalOrder
 * @returns {{const: string; title: React.ReactNode}[]}
 */
export function generateSelectOptionsAsJson(list, alphabeticalOrder) {
  const options = Object.entries(list).map(([value, label]) => ({
    const: value,
    title: label,
  }));

  if (alphabeticalOrder) {
    return sortBy(options, (op) => op.title);
  }
  return options;
}

/**
 * Generates an array of values and labels for a given list of key/values based
 * on a pre-defined order.
 * @param {Object[]} list
 * @param {String[]} keyOrder
 * @return {Object[]}
 */
export function generateSelectOptionsWithOrder(list, keyOrder) {
  const unorderedOptions = generateSelectOptions(list);
  let orderedOptions = [];

  keyOrder.forEach((key) => {
    const index = unorderedOptions.findIndex((option) => option.value === key);

    if (index >= 0) {
      orderedOptions = [...orderedOptions, ...unorderedOptions.splice(index, 1)];
    }
  });

  return [...orderedOptions, ...unorderedOptions];
}

export function generateSelectOptionsFromValues(valuesToInclude, labels) {
  if (!valuesToInclude || !labels) {
    throw new Error('Missing "valuesToInclude" or "labels" params');
  }
  const result = [];

  valuesToInclude.forEach((value) => {
    if (labels[value]) {
      result.push({
        value,
        label: labels[value],
      });
    }
  });

  return result;
}

export function generateSelectOptionsFromRoles({ roles } = { roles: [] }) {
  return roles.map(({ name: label, slug: value }) => ({ label, value }));
}

/**
 * Do not use type="number", it has A11Y and UX issues.
  Prefer using inputmode attribute
  https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
  https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
  */
export const inputModeAttrs = {
  type: 'text',
  inputMode: 'decimal',
  pattern: '^[0-9.]*$', // accepts numbers and dots (eg 10, 15.50)
};

/**
 * Shamelessly stolen from https://stackoverflow.com/questions/47062922/how-to-get-all-keys-with-values-from-nested-objects/47063174
 * It takes an array / object and outputs an array of strings representing all paths.
 * Excludes strings that are duplicated from snake_case.
 * @example
 * {a: 'some-value', b: [{c: 'another-value'}], d: {e: 'yet-another'} } -> ['a', 'b[0].c', 'd.e']
 * {hasFoo: 1, has_foo: 1 } -> ['hasFoo']
 *
 * @param {Object | Array} obj - object being inspected
 * @param {String} prefix - prefix added to the key
 * @returns {Array} list of object paths
 */
function getObjectPaths(obj, prefix = '') {
  const list = Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      const arrayValues = getObjectPaths(
        obj[el].reduce(
          (acc, curr, idx) =>
            curr
              ? {
                  ...acc,
                  [`${el}[${idx}]`]: curr,
                }
              : acc,
          {}
        )
      );
      return [...res, ...arrayValues];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...getObjectPaths(obj[el], `${prefix + el}.`)];
    }
    return [...res, prefix + el];
  }, []);

  // Remove the duplicated snake_case keys
  return list.filter((word) => {
    const hasDuplicate = list.find((word2) => word2 !== word && snakeCase(word2) === word);
    return !hasDuplicate;
  });
}

/**
 * Builds list of names for inputs with an error
 *
 * @param {Object} errors - object provided by Formik (https://formik.org/docs/api/formik#errors--field-string-string-)
 * @returns {Array} list of error fields input names
 */
export function getErrorFieldsNames(errors) {
  const errorKeys = getObjectPaths(errors);

  return errorKeys;
}

/**
 * Use this in forms that are not built with DynamicForm
 * Based on the forms values, get a list of all the fields.
 * @param {Object} values eg { age: 26 }
 * @returns {Array} fields [{ name: 'age', value: '26' }]
 */
export function getFieldsShapeFromValues(values) {
  // TODO: It does not support nested or array values.
  return Object.keys(values).map((key) => {
    const label = friendlyKeyToLabel(key);
    return { name: key, label };
  });
}

/**
 * Clears colon character and escapes all indexing ([index]) and nesting (.) characters to obtain a valid ID selector
 * @param {String} value
 * @returns {String} escaped value
 */

export function escapeIndexingAndNestingChars(value) {
  return value.replaceAll(':', '').replace(new RegExp('([\\[,\\],\\.])', 'g'), '\\$1');
}

export function flushSyncFunction(callback) {
  return function flush(...params) {
    /**
     * Formik has some issues with automatic batching introduced in React 18
     * State updates done by Formik callbacks need to be flushed synchronically
     */
    flushSync(() => {
      callback(...params);
    });
  };
}
