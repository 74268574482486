import { useInfiniteQuery } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import { createQueryKey } from '../utils';

const METHOD = 'GET';

export const useGetInfinite = (path, config = { params: {}, options: {} }) => {
  const queryNameOptions = {
    ...config.params,
    queryKeyOptions: {
      isInfinite: true,
      ...config.params?.queryKeyOptions,
    },
  };

  const { createService } = useApiService();
  const service = createService(path, METHOD);
  const queryKey = createQueryKey(path, queryNameOptions);

  return useInfiniteQuery(
    queryKey,
    ({ pageParam, signal }) => {
      const fnArgs = {
        signal: config?.params?.signal || signal,
        queryParams: { ...config.params?.queryParams, page: pageParam ?? 1 },
        pathParams: config.params?.pathParams,
      };

      return service(fnArgs);
    },
    config.options
  );
};
