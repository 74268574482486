import { monthlyPlanTypeIdentifiers } from '@/src/domains/pricingPlans/common/constants';
import { generateSelectOptions } from '@/src/helpers/forms';

export const productEmploymentTypes = /** @type {const} */ ({
  EOR: 'eor',
  CONTRACTOR: 'contractor',
  DEPOSIT_SUBSTITUTE: 'deposit_substitute',
  GLOBAL_PAYROLL: 'global_payroll',
  EOR_TEAM_PLAN: 'eor_team_plan',
  DIRECT: 'direct',
  EOR_ANNUAL_PLAN: 'eor_annual_plan',
});

/** Set of accessors not to be included in additional columns or column
 * configuration list because they're unnecessary and only add noise.
 * */

export const pricingCountryColumnAccessorExclusionSet = new Set(['country.slug', 'country.code']);

export const pricingProductsColumnAccessorExclusionSet = new Set(['slug']);

export const pricingProductPricesAccessorExclusionSet = new Set([
  'product.employmentType',
  'product.frequency',
  'product.slug',
  'slug',
]);

export const pricingDiscountsAccessorExclusionSet = new Set(['slug']);

// Exhaustive list of product employment types that generate an employment fee.
export const productsGeneratingEmploymentFees = new Set([
  productEmploymentTypes.CONTRACTOR,
  productEmploymentTypes.EOR,
  productEmploymentTypes.EOR_TEAM_PLAN,
  productEmploymentTypes.GLOBAL_PAYROLL,
  productEmploymentTypes.EOR_ANNUAL_PLAN,
  productEmploymentTypes.DIRECT,
  productEmploymentTypes.DEPOSIT_SUBSTITUTE,
]);

export const PRODUCT_PRICING_FREQUENCY_EOR = {
  ANNUAL: 'EOR Annual',
  MONTHLY: 'EOR Monthly',
};

export const PRODUCT_PRICING_FREQUENCY = /** @type {const} */ ({
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
});

export const PRODUCT_PRICING_FREQUENCY_LABELS = {
  [PRODUCT_PRICING_FREQUENCY.ANNUAL]: 'Annual',
  [PRODUCT_PRICING_FREQUENCY.MONTHLY]: 'Monthly',
};

export const PRODUCT_PRICING_FREQUENCY_OPTIONS = generateSelectOptions(
  PRODUCT_PRICING_FREQUENCY_LABELS
);

export const ANNUAL_PLAN_SAVINGS = '14%';

/**
 * During the work for CO-86, we encountered a challenge with the import of variables from src/domains/countries/constants.js.
 * Specifically, we found a circular dependency problem with the import of the variables canadaCountryCode,irelandCountryCode, and ukCountryCode. We used the country code strings for the aforementioned countries to resolve this.
 * https://linear.app/remote/issue/CO-86/[pricing]-combine-pricing-constants
 */
export const EOR_LEGACY_PRICING_COUNTRIES = ['CAN', 'IRL', 'GBR'];

/**
 * Product tier enum.
 */
export const PRODUCT_TIERS = /** @type {const} */ ({
  STANDARD: 'standard',
  PREMIUM: 'premium',
  PLUS: 'plus',
});

export const PRODUCT_TIER_LABELS = {
  [PRODUCT_TIERS.STANDARD]: 'Standard',
  [PRODUCT_TIERS.PREMIUM]: 'Premium',
  [PRODUCT_TIERS.PLUS]: 'Plus',
};

export const PRODUCT_TIER_STATUS = {
  ARCHIVED: 'archived',
  ACCEPTED: 'accepted',
  CREATED: 'created',
  SHARED: 'shared',
  CANCELLED: 'cancelled',
};

export const PRODUCT_TIER_OPTIONS = generateSelectOptions(PRODUCT_TIER_LABELS);

export const PRODUCT_TIER_DRAWER_STEPS = {
  FORM_STEP: 'form_step',
  SUMMARY_STEP: 'summary_step',
};

/**
 * This is used to determine the tiers activity status
 * since relying on the status alone is not enough because
 * "canceled" plans are considered active if their end date
 * is in the future.
 */
export const COMPANY_PRODUCT_TIER_VARIANT = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const CONTRACTOR_SUBSCRIPTION_DISCOUNT_REGEXP = /contractor/i;

export const PREMIUM_PLAN_DISCOUNT_REGEXP = /premium plan/i;

export const CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE = {
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
};

export const monthlyPlanOptions = [
  {
    label: 'Flat pricing',
    value: monthlyPlanTypeIdentifiers.FLAT,
  },
  {
    label: 'Tier pricing',
    value: monthlyPlanTypeIdentifiers.TIERED,
  },
];
