import { useQueryClient } from 'react-query';

import { createQueryKey } from '../utils';

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = (path, config = { params: {}, filters: {}, options: {} }) => {
    const queryKey = createQueryKey(path, config.params);
    return queryClient.invalidateQueries(queryKey, config.filters, config.options);
  };

  return {
    invalidateQuery,
  };
};
