import { forwardRef } from 'react';

import type { AsPropType } from '../../types';

import type { BoxProps } from './Box';
import { Box } from './Box';

type BaseType = Omit<BoxProps, 'display'>;

export interface StackProps extends BaseType {
  direction?: BaseType['flexDirection'];
  forwardedAs?: AsPropType;
  as?: AsPropType;
  inline?: boolean;
}

export const Stack = forwardRef<HTMLElement | null, StackProps>(
  ({ children, direction = 'column', inline, ...props }, ref) => (
    <Box ref={ref} flexDirection={direction} display={inline ? 'inline-flex' : 'flex'} {...props}>
      {children}
    </Box>
  )
);
