import type { CustomColors } from '../../utils/types';

const colorShadesMap: Record<number, number> = {
  900: 500,
  800: 500,
  700: 400,
  600: 400,
  500: 300,
  400: 300,
  300: 200,
  200: 100,
  100: 50,
  50: 50,
};

/**
 * semanticColorsMap: a map of the semantic colors from Norma's Design System.
 */
type SemanticColorValue =
  | 'negative'
  | 'positive'
  | 'attention'
  | 'informative'
  | 'neutral'
  | 'primary'
  | 'blank';

type SemanticColorProperty =
  | {
      foreground: keyof CustomColors;
      background: keyof CustomColors;
      border: keyof CustomColors;
    }
  | keyof CustomColors;

type SemanticColorMap = {
  [key in SemanticColorValue]: SemanticColorProperty;
};

/**
 * a string that represents a semantic color in the format of "category.property" or "key" only.
 * */
type SemanticColorKey =
  | `${SemanticColorValue}.${keyof SemanticColorProperty}`
  | `${SemanticColorValue}`
  | string;

const semanticColorsMap: SemanticColorMap = {
  negative: {
    foreground: 'red.700',
    background: 'red.50',
    border: 'red.400',
  },
  positive: {
    foreground: 'green.800',
    background: 'green.50',
    border: 'green.400',
  },
  attention: {
    foreground: 'yellow.800',
    background: 'yellow.50',
    border: 'yellow.400',
  },
  informative: {
    foreground: 'brand.700',
    background: 'blueLight.50',
    border: 'brand.300',
  },
  neutral: {
    foreground: 'grey.600',
    background: 'grey.50',
    border: 'grey.400',
  },
  primary: 'brand.600',
  blank: 'blank',
};

/**
 * getSemanticColorValue: looks up a semantic color value from a map object based on a given key and returns the value of the semantic color.
 */
const getSemanticColorValue = (key: SemanticColorKey) => {
  const [category, property] = key.split('.') as [SemanticColorValue, SemanticColorProperty];
  if (property) {
    const value = semanticColorsMap[category]?.[property as keyof SemanticColorProperty];
    return value;
  }
  return semanticColorsMap[category];
};

/**
 * containsShadeScale: checks if a string contains a dot followed by a number.
 */
const containsShadeScale = (colorShadeName: string) => /.\d+$/.test(colorShadeName);

/**
 * getLighterColorShade: gets a lighter shade of a given color based on the color's shade number.
 */
export const getLighterColorShade = (colorName: SemanticColorKey) => {
  // If the input color string has a numerical format, get the matching lighter color shade based on the colorShadesMap.
  if (containsShadeScale(colorName)) {
    const [baseColorName, shadeNumber] = colorName?.split('.') || [];
    const numericShadeNumber = parseInt(shadeNumber, 10);
    const lighterShadeNumber = colorShadesMap[numericShadeNumber];
    return `${baseColorName}.${lighterShadeNumber}`;
  }
  // If the input color string has a semantic format (no numbers), get the matching lighter color shade based on the semanticColorsMap.
  const semanticColorValue = getSemanticColorValue(colorName);

  if (typeof semanticColorValue === 'string') {
    const [semanticColorName, semanticColorShadeNumber] = semanticColorValue?.split('.') || [];
    if (semanticColorShadeNumber !== undefined) {
      const numericSemanticShadeNumber = parseInt(semanticColorShadeNumber, 10);
      const lighterSemanticShadeNumber = colorShadesMap[numericSemanticShadeNumber];
      return `${semanticColorName}.${lighterSemanticShadeNumber}`;
    }
    return semanticColorName;
  }
  return '';
};
