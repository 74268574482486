/* eslint-disable import/no-extraneous-dependencies */
import { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

function useComponentDidUpdate(callback, deps) {
  const didMountRef = useRef(false);

  useDeepCompareEffect(() => {
    const asyncCallback = async () => {
      await callback();
    };
    if (didMountRef.current) {
      asyncCallback();
    } else {
      didMountRef.current = true;
    }
  }, deps);
}

export default useComponentDidUpdate;
