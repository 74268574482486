// qs is a well tested small 3kb lib that supports parsing to/from nested objects, arrays and other features
import qs from 'qs';

import { navigationEvents, toggleEvents } from './events';
import { trackEvent } from './methods';

export function handleNavigationTracking(eventData, href) {
  if (!eventData) return;

  const [locationOfAction, elementName, otherOptions = {}] = eventData;
  const { elementData = {} } = otherOptions;

  trackEvent(navigationEvents.SECONDARY, {
    locationOfAction,
    elementName,
    ...otherOptions,
    ...(href && { elementType: 'link', elementData: { url: href, ...elementData } }),
  });
}

export function handleToggleTracking(eventData, toggleState) {
  if (!eventData) return;

  const [locationOfAction, elementName, domain] = eventData;

  trackEvent(toggleEvents, {
    locationOfAction,
    elementName,
    ...(domain && { domain }),
    ...(toggleState && { elementData: { toggleState } }),
  });
}

/**
 * Generate a proper LocationOfAction value used for tracking
 *
 * @param {String} pathname
 * @return {String}
 */
export function pathnameToLocationOfAction(pathname) {
  if (pathname === '/rivendell' || pathname === '/dashboard') {
    return 'homepage';
  }

  // Get rid of the leading `/` character
  const patterStart = /(^\/)/;
  // Remove the `rivendell/` or `dashboard/` part
  const patterMiddle = /(rivendell|dashboard)\//;
  // Also, remove any substring that is between [] and starts with '/' eg /[slug] or /[countrySlug]
  const patterEnd = /(\/\[.*\])/;

  const combinedPattern = new RegExp(
    `${patterStart.source}|${patterMiddle.source}|${patterEnd.source}`,
    'g'
  );

  const locationOfAction = pathname.replace(combinedPattern, '');

  // All of the '/' should be replaced with '-'
  return locationOfAction.replace('/', '-');
}

/**
 * Parses the query string to a query object.
 * @param {string} url
 */
export function parseQueryFromUrl(url = '', options = {}) {
  return qs.parse(url.split('?')[1], options);
}

export function selectedTabHasChanged(prevUrl, newUrl) {
  if (!prevUrl || !newUrl) return false;

  try {
    const prevTab = parseQueryFromUrl(prevUrl).selectedTab;
    const newTab = parseQueryFromUrl(newUrl).selectedTab;

    if (!prevTab || !newTab) return false;
    if (prevTab !== newTab) return true;
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function trackAppcuesPageEvent() {
  // Page events are submitted automatically via the Rudderstack integration.
  // This manual tracking is only needed for cases where we want to fire page views
  // in Appcues that are not technically a page view with rudderstack, such as
  // Tab changes from within a page.
  if (window.Appcues) {
    window.Appcues.page();
  }
}
