import { Box } from '@remote-com/norma';

import { Button } from '@/src/components/Button';
import { DASHBOARD_ROUTE } from '@/src/constants/routes';
import type { SideIllustrationLayoutDescriptionProps } from '@/src/layouts/SideIllustrationLayout';
import { SideIllustrationLayout } from '@/src/layouts/SideIllustrationLayout';

const brandIllustration = '/images/illustrations/pattern_5.svg';

type ErrorPageProps = {
  backButtonTitle?: string;
  backButtonHref?: string;
  onBackButtonClick?: () => void;
  title?: string;
  trackNavigation?: (string | Record<string, unknown>)[];
} & SideIllustrationLayoutDescriptionProps;

export const ErrorPage = ({
  metaDescription,
  onBackButtonClick,
  trackNavigation,
  title = "We're having issues",
  description = "We're looking into it right now. Please try again later. Your data is safe.",
  backButtonTitle = 'Back to Remote',
  backButtonHref = DASHBOARD_ROUTE,
}: ErrorPageProps) => {
  return (
    // We are using the same type constraint as the original component, but TS is not recognizing it
    // @ts-expect-error
    <SideIllustrationLayout
      brandIllustration={brandIllustration}
      metaDescription={metaDescription}
      description={description}
      title={title}
    >
      <Box display="flex" justifyContent="center">
        <Button
          href={backButtonHref}
          size="lg"
          onClick={onBackButtonClick}
          tone="primary"
          trackNavigation={trackNavigation}
        >
          {backButtonTitle}
        </Button>
      </Box>
    </SideIllustrationLayout>
  );
};
