import { getUtm } from '../helpers';

/* Ignore internal emails as they can be used when testing in production. */
function isExternalEmail(email) {
  return !(email || '').toLowerCase().includes('@remote.com');
}

export function createPartnerStackSignUp({ email, formName, id }) {
  if (window.growsumo && id && isExternalEmail(email)) {
    return new Promise((resolve) => {
      window.growsumo.data.name = formName;
      window.growsumo.data.email = `${id}@remote.com`;
      window.growsumo.data.customer_key = id;

      return window.growsumo.createSignup(resolve);
    });
  }

  return new Promise((resolve) => {
    resolve();
  });
}

export function getPartnerStackInfo(utm = getUtm()) {
  let pspartnerkey = '';

  if (window?.growsumo?.data) {
    pspartnerkey = window.growsumo.data.partner_key || '';
  }

  const utmUrlParams = new URLSearchParams(utm);
  // eslint-disable-next-line camelcase
  const pspartner_group = utmUrlParams.get('utm_campaign') || '';

  return {
    pspartnerkey,
    pspartner_group,
  };
}
