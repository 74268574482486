// Leave `process.env.NODE_ENV` without destructuring as this way
// it is going to be replaced with a static string in browser builds.
// eslint-disable-next-line prefer-destructuring
const NODE_ENV = process.env.NODE_ENV;

export function isDev() {
  return NODE_ENV === 'development';
}

/**
 * console.log only in development
 */
function logIfDev() {
  // eslint-disable-next-line no-unused-vars
  let log = function log(message) {};

  if (console && isDev()) {
    /* eslint-disable no-console */
    log = console.log.bind(console);
    /* eslint-enable no-console */
  }

  return log;
}

export const debug = logIfDev();
