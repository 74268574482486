import type { UseMutationOptions, UseMutationResult } from 'react-query';
// eslint-disable-next-line
import { useMutation } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import type { PostRequest, PostResponse, PostPath } from '../POST.types';

export type UsePostOptions<KPath extends PostPath, TError = unknown, TContext = unknown> = Omit<
  UseMutationOptions<PostResponse<KPath>, TError, PostRequest<KPath>, TContext>,
  'mutationFn'
>;

const METHOD = 'POST';

export function usePost<KPath extends PostPath, TError = unknown, TContext = unknown>(
  path: KPath,
  options: UsePostOptions<KPath, TError, TContext> = {}
): UseMutationResult<PostResponse<KPath>, TError, PostRequest<KPath>, unknown> {
  const { createService } = useApiService();
  const service = createService(path, METHOD);

  return useMutation((params) => service(params), options);
}
