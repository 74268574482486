import { css, keyframes } from 'styled-components';

const slideInFromBottomAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

function getNthChildAnimationDelay(maxItems: number) {
  function nthChildTemplate(i: number) {
    return `
        &:nth-child(${i + 1}) {
          animation-delay: calc(0.15s + calc(${i}s / 8));
        }
      `;
  }
  let str = '';
  for (let index = 0; index < maxItems; index += 1) {
    str += nthChildTemplate(index);
  }
  return str;
}

const slideInFromBottomBaseStyles = css`
  @media screen and (prefers-reduced-motion: no-preference) {
    animation-duration: 0.35s;
    animation-name: ${slideInFromBottomAnimation};
    animation-timing-function: ease-out;
    animation-fill-mode: backwards;
  }
`;

export const slideInFromBottom = (animate: boolean, maxItems: number) =>
  animate &&
  css`
    ${slideInFromBottomBaseStyles};
    ${getNthChildAnimationDelay(maxItems)};
  `;

export function sharedTransition(properties: string) {
  return css`
    transition: 0.3s ease;
    transition-property: ${properties};
  `;
}
