import type { Property } from 'csstype';
import { forwardRef } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import type { ResponsiveValue, SpaceProps, TypographyProps } from 'styled-system';
import { color, compose, space, typography, variant } from 'styled-system';

import { textVariants } from '../../foundations/typography';
import type { AsPropType, BaseHTMLPropsWithRef } from '../../types';
import type { ColorPropsWithCustomPalette } from '../../utils/types';

type BaseStyledTextProps = BaseHTMLPropsWithRef &
  SpaceProps<DefaultTheme, number | 'auto'> &
  ColorPropsWithCustomPalette &
  TypographyProps;

export interface TextProps extends BaseStyledTextProps {
  textTransform?: Property.TextTransform;
  as?: AsPropType;
  tag?: AsPropType;
  variant?: ResponsiveValue<keyof DefaultTheme['typography'], DefaultTheme>;
}

// Is a span to ensure it's valid at any context by default
const TextBase = styled('span')
  .withConfig<TextProps>({
    // props that are not part of typography and color are not passed down to underlying components
    shouldForwardProp: (prop, defaultValidatorFn) =>
      ![...(typography.propNames || []), ...(color.propNames || [])].includes(prop) &&
      defaultValidatorFn(prop),
  })
  .attrs<TextProps>((props) => ({
    as: props.tag || props.as, // easier than writing "forwardedAs" word.
  }))`

  /* retro-compatibility with previous default <p> tag, and because most places need block.
   I'm sorry, you'll need to pass as="span" to make it inline. Read issue!10033 */
  ${(props) =>
    !props.as &&
    css`
      display: block;
    `}

  ${({ textTransform }) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `}

  ${compose(color, typography, space)}
  ${variant(textVariants)}
`;

// To reset margins and in order for the consumer to override them we need to create a wrapper around Text
export const Text = forwardRef<HTMLElement, TextProps>(({ children, ...props }, ref) => (
  <TextBase ref={ref} m={0} {...props}>
    {children}
  </TextBase>
)) as typeof TextBase;
