import { createMakeRequest } from '@remote-com/data-layer';
import type {
  DeletePath,
  DeleteRequest,
  DeleteResponse,
  GetPath,
  GetRequest,
  GetResponse,
  PatchPath,
  PatchRequest,
  PatchResponse,
  PostPath,
  PostRequest,
  PostResponse,
  PutPath,
  PutRequest,
  PutResponse,
} from '@remote-com/data-layer';

// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';

const { makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest, makePutRequest } =
  createMakeRequest(makeApiService);

export const makeGet = <KPath extends GetPath>(
  url: KPath,
  params?: GetRequest<KPath>
): Promise<GetResponse<KPath>> => makeGetRequest(url, params);

export const makeDelete = <KPath extends DeletePath>(
  url: KPath,
  params?: DeleteRequest<KPath>
): Promise<DeleteResponse<KPath>> => makeDeleteRequest(url, params);

export const makePost = <KPath extends PostPath>(
  url: KPath,
  params?: PostRequest<KPath>
): Promise<PostResponse<KPath>> => makePostRequest(url, params);

export const makePatch = <KPath extends PatchPath>(
  url: KPath,
  params?: PatchRequest<KPath>
): Promise<PatchResponse<KPath>> => makePatchRequest(url, params);

export const makePut = <KPath extends PutPath>(
  url: KPath,
  params?: PutRequest<KPath>
): Promise<PutResponse<KPath>> => makePutRequest(url, params);
