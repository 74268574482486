import snakeCase from 'lodash/snakeCase';
import Router from 'next/router';

import { FILE_PREVIEW_ROUTE } from '@/src/constants/routes';
import { fetchFile } from '@/src/domains/files/services';
import { captureHTTPException } from '@/src/helpers/captureException';

/**
 * @deprecated Use directly from Norma
 */
export {
  convertFileBytesToMb as convertBytesToMb,
  convertFileMbToBytes as convertMbToBytes,
  getFileExtension,
  getFileNameWithoutExtension,
} from '@remote-com/norma';

export const getPreviewPageUrl = ({ fileSlug }) => `${FILE_PREVIEW_ROUTE}?fileSlug=${fileSlug}`;

/**
 * @deprecated Please consider getPreviewPageUrl in a link for better A11Y. If not possible, you can ignore and disable this warning.
 */
export const goToFilePreview = ({ fileSlug }) => Router.push(getPreviewPageUrl({ fileSlug }));

export function validateFileSize(size, maxSize) {
  return size <= maxSize;
}

/**
 * Reads and returns base64 file content
 * @param {File} file
 */
export function getFileContent(file) {
  const reader = new FileReader();
  return new Promise((done) => {
    reader.onload = (event) => {
      done(event.target.result);
    };
    reader.readAsDataURL(file);
  });
}

/**
 * Reads and returns file contents as array buffer
 * @param {File} file
 */
export function getFileContentAsArray(file) {
  const reader = new FileReader();
  return new Promise((done) => {
    reader.onload = (event) => {
      done(event.target.result);
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Parses a text file as a JS object
 * @param {File} file
 * @returns {Promise}
 */
export const getFileContentAsObject = async (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      let parsed;
      try {
        parsed = JSON.parse(event.target.result);
        resolve(parsed);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
};

/**
 * Generates API-compatible file body for upload request
 * @param {File} file
 */
export async function getUploadFileBody(file, subType) {
  const { name } = file;
  return {
    content: await getFileContent(file),
    name,
    subType,
  };
}

/**
 * Generates an element to allow downloading the file
 * @param {Object} file
 */
export function generateAndDownloadDocument(file) {
  const downloadLink = document.createElement('a');
  // 1. Recent versions of Firefox ignore the "download" attribute and always open pdf file in
  // browser. The only way to force a download is to mark it as a binary MIME attachment.
  // - Ref: https://bugzilla.mozilla.org/show_bug.cgi?id=1756980#c5
  //
  // 2. After downloading the files, Firefox will try to recognise their actual formats and
  // will open pdf files again. This means the files will be downloaded AND opened in new tabs.
  // This is expected and cannot be fixed.
  // - Ref: https://bugzilla.mozilla.org/show_bug.cgi?id=1756980#c24
  //
  // 3. Optional chaining because in our codebase "file.content" usually come from a Blob and
  // Blob is not implemented yet in jsdom so the content is expectedly undefined in test
  // - Ref: https://github.com/jsdom/jsdom/issues/2555
  downloadLink.href =
    file.content?.replace('data:application/pdf', 'data:application/octet-stream') ?? '';
  // This won't have any effect if the browser downloads the files. However if for some reason the
  // browser opens the files instead (e.g. due to a user preference or an extension), this acts as
  // a safe guard to at least open the files in new tab, avoid losing our user's state in the
  // current tab
  downloadLink.target = '_blank';
  downloadLink.download = file.description ? `${file.description}-${file.name}` : file.name;
  downloadLink.rel = 'noopener';
  downloadLink.click();
}

export async function handleDownloadFile(slug) {
  try {
    const { data: file } = await fetchFile({ pathParams: { slug } });
    generateAndDownloadDocument(file);
  } catch (exception) {
    captureHTTPException(exception);
  }
}

export function getContentTypeFromBase64(base64String) {
  return base64String?.substring(base64String.indexOf(':') + 1, base64String.indexOf(';base64'));
}

/**
 *
 * @param {object} file
 */
function base64ToBlob(file) {
  const contentType = getContentTypeFromBase64(file?.content);
  // this was totally adapted from here https://stackoverflow.com/a/54466127
  const base64ImageData = file.content;

  const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

/**
 *
 * When using this function, don't forget to call URL.revokeObjectURL(blobUrl) to avoid memory leaks.
 */
export function createObjectUrlFromBlob(blob) {
  return URL.createObjectURL(blob);
}

/**
 *
 * @param {Blob} blob
 * @param {string} fileName
 * @returns {File}
 */
function createFileFromBlob(blob, fileName) {
  return new File([blob], fileName, {
    type: blob.type,
  });
}

/**
 * When using this function, don't forget to call URL.revokeObjectURL(blobUrl) to avoid memory leaks.
 */
export function createObjectUrlFromBase64File(file) {
  return createObjectUrlFromBlob(base64ToBlob(file));
}

/**
 *
 * @param {object} file
 * @param {string} file.content
 * @param {string} file.name
 * @returns {File}
 */
export function createFileObjectFromBase64File(file) {
  return createFileFromBlob(base64ToBlob(file), file.name);
}

function addSubTypeToFile(file, subType) {
  return {
    ...file,
    subType: snakeCase(subType),
  };
}

function addTypeToFile(file, fieldInput) {
  const customType = fieldInput?.documentType || null;

  return customType
    ? {
        ...file,
        type: customType,
      }
    : file;
}

function addLabelAsDescriptionToFile(file, field) {
  const description = typeof field?.label === 'string' ? field?.label : null;

  return description
    ? {
        ...file,
        description,
      }
    : file;
}

/**
 * Returns the file content with the extra attributes: type, subtype and description.
 * @param {File} file
 * @param {Object} fieldInput
 * @param {string} fieldName
 * @param {Object} config
 */
export function addAttributesToFile({ file, fieldInput, fieldName, config }) {
  const defaultConfig = {
    includeSubType: true,
    includeDescription: true,
    includeType: true,
  };

  const attrConfig = { ...defaultConfig, ...(config || {}) };

  let processedFile = {
    file,
  };

  if (attrConfig.includeType) {
    processedFile = addTypeToFile(processedFile, fieldInput);
  }

  if (attrConfig.includeSubType) {
    processedFile = addSubTypeToFile(processedFile, fieldName);
  }

  if (attrConfig.includeDescription) {
    processedFile = addLabelAsDescriptionToFile(processedFile, fieldInput);
  }

  return processedFile;
}

/**
 * Returns the list of files content with the extra attributes: type, subtype and description.
 * The fields is what's returned by JSON schema or the getFieldsForCountry and getFieldsByCountry helpers
 * @param {Object} fieldsToUpload
 * @param {Array} fields
 * @param {config} configuration to specify which properties should be included
 */
export function getFieldsWithFullData({ fieldsToUpload, fields = [], config }) {
  const fieldsByName = {};
  fields.forEach((field) => {
    fieldsByName[field.name] = field;
  });

  const result = Object.entries(fieldsToUpload).map(([fieldName, fieldContents]) => {
    const processedFiles = fieldContents.map((file) =>
      addAttributesToFile({ file, fieldInput: fieldsByName[fieldName], fieldName, config })
    );
    return processedFiles.length === 1 ? processedFiles[0] : processedFiles;
  });

  return result;
}

export function sortFiles(expenses) {
  return expenses.sort((a, b) => (a.insertedAt > b.insertedAt ? 1 : -1));
}

/**
 *
 * @param {Object} param
 * @param {string} param.prefix - either 'TR' (for contractor invoices) or Country Code
 * @param {integer} param.invoiceNumber -
 * @returns string | null
 */
export function formatInvoiceNumberWithPrefix({ prefix, invoiceNumber }) {
  if (!invoiceNumber) {
    return null;
  }
  const invoiceNumberString = String(invoiceNumber);

  const fixedLength = 10;
  return `${prefix}${invoiceNumberString.padStart(fixedLength, '0')}`;
}
/**
 *
 * @param {{value: integer, countryCode: string}} number
 * @param {boolean} isContractorTransactionReceipt
 * @returns string
 */
export function formatInvoiceNumberObject(number = {}, isContractorTransactionReceipt) {
  const { countryCode, value } = number;
  if (!countryCode && !value) return '';

  const prefix = isContractorTransactionReceipt ? 'TR' : `${countryCode}`;
  return formatInvoiceNumberWithPrefix({ prefix, invoiceNumber: value });
}

/**
 * Rename file
 * @param {File} file
 */
export function renameFile(file, filename) {
  // This approach do not support IE11
  return new File([file], filename, {
    type: file.type,
  });
}

export function createFileBlobAndDownload(fileBlob, fileName) {
  const fileURL = createObjectUrlFromBlob(fileBlob);
  const anchor = document.createElement('a');
  anchor.href = fileURL;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
