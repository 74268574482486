/**
 * LazyURLModal is a component that defers the load of all our modals and drawers to the client side.
 *
 * This component is extremely important to keep our dev mode fast.
 *
 * The import syntax is important to preserve Webpacks lazyCompilation feature.
 *
 * Read this: https://webpack.js.org/configuration/experiments/#experimentslazycompilation
 *
 * our useEffect acts as the action that triggers the compilation. This is why we need it as a workaround.
 * And the urlModalImports is important for Webpack to don't load this on the server.
 * Don't attempt to refactor this using next/dynamic.
 *
 * The way this works is by signaling Webpack when to compile this.
 * Webpack dev server compiles every import all the time, doesn't matter if you place it inside next/dynamic.
 * Except if you wrap the import inside a function, and call it inside a callback.
 *
 * Talk to #dev-team-web-and-development-experience if you want to touch this file.
 */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { URLModal } from 'react-url-modal';

import { ModalErrorBoundary } from '@/src/components/Modal/ErrorBoundary';

const urlModalImports = {
  drawers: () => import('@/src/components/Drawer/urlDrawers'),
  modals: () => import('@/src/components/Modal/urlModals'),
};

export const LazyURLModal = () => {
  const { query } = useRouter();
  const [modals, setModals] = useState({});
  const isEmpty = !Object.keys(modals).length;
  useEffect(() => {
    const loadModals = async () => {
      const [loadedDrawers, loadedModals] = await Promise.all(
        Object.keys(urlModalImports).map(async (key) => {
          const loadedModule = await urlModalImports[key]();
          return loadedModule[key];
        })
      );
      setModals({ ...loadedDrawers, ...loadedModals });
    };

    if (isEmpty && query?.modal) {
      loadModals();
    }
  }, [isEmpty, query]);

  return <URLModal modals={modals} Wrapper={ModalErrorBoundary} adapter="nextjs" replace />;
};
