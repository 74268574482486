import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Box } from '../../layout';

import type { BadgeProps } from './Badge';
import { getTypeColors } from './helpers';

type SignalProps = {
  $size: BadgeProps['size'];
  $type: BadgeProps['type'];
};

export const BadgeSignal = styled(Box)<SignalProps>`
  --badgeSignalSize: ${({ $size }) => ($size === 'lg' ? '16px' : '12px')};

  background: ${({ theme, $type }) => css`
    linear-gradient(
      to right,
      ${getTypeColors(theme)[$type]?.from} 50%,
      ${getTypeColors(theme)[$type]?.to} 50%
    )
  `};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: inline-block;
  flex-shrink: 0;
  height: var(--badgeSignalSize);
  position: relative;
  text-align: center;
  width: var(--badgeSignalSize);
`;

type LabelProps = {
  $color: BadgeProps['labelColor'];
};

export const BadgeLabel = styled(Box).attrs<LabelProps>(({ $color }) => ({
  color: $color,
}))<LabelProps>`
  font-size: ${rem('14px')};
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
