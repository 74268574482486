import snakecaseKeys from 'snakecase-keys';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import type { $TSFixMe } from '@/types';

export const getPreferredName = (user: UserAccount | undefined) =>
  user?.personalDetails?.preferredName || user?.name;

export function transformPermissions(response: $TSFixMe) {
  if (response.data?.permissions) {
    return {
      ...response,
      data: {
        ...response.data,
        permissions: snakecaseKeys(response.data.permissions),
      },
    };
  }

  return response;
}

export const AUTH_ERRORS = {
  LOCKED_ACCOUNT: 'account_locked',
  TWO_FACTOR_AUTHENTICATION_INVALID: 'two_factor_authentication_invalid',
  TOTP_CODE_VERIFICATION_FAILED: 'totp_code_verification_failed',
  BACKUP_CODE_VERIFICATION_FAILED: 'backup_code_verification_failed',
  TWO_FACTOR_AUTHENTICATION_REQUIRED: 'two_factor_authentication_required',
} as const;

export function getTOTPErrorMessage(
  errorMessage: (typeof AUTH_ERRORS)[keyof typeof AUTH_ERRORS] | string
) {
  switch (errorMessage) {
    case 'two_factor_authentication_invalid':
    case 'totp_code_verification_failed':
      return 'Wrong authenticator code. Verify again.';
    case 'backup_code_verification_failed':
      return 'Wrong backup code. Verify again.';
    case 'two_factor_authentication_required':
      return 'Two-factor authentication (2FA) set up is required';
    case 'account_locked':
      return 'Account locked due to failed login attempts. To recover your account, check your email.';
    default:
      return 'Something went wrong on our end. Please try again';
  }
}
