import { rem } from 'polished';

import { fonts } from '../fonts';

const getUnitlessLineHeight = (fontSize: number, lineHeight: number) => lineHeight / fontSize;

const baseVariants = {
  '5xl': {
    fontSize: rem(42),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(42, 46),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  '4xl': {
    fontSize: rem(32),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(32, 38),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  '3xl': {
    fontSize: rem(28),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(28, 36),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  '2xl': {
    fontSize: rem(24),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(24, 32),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  xl: {
    fontSize: rem(20),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(20, 28),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  lg: {
    fontSize: rem(18),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(18, 28),
    fontFamily: fonts.brand,
    fontWeight: 400,
  },
  base: {
    fontSize: rem(16),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(16, 24),
    fontWeight: 400,
  },
  sm: {
    fontSize: rem(14),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(14, 22),
    fontWeight: 400,
  },
  xs: {
    fontSize: rem(12),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(12, 18),
    fontWeight: 400,
  },
  '2xs': {
    fontSize: rem(10),
    letterSpacing: '0',
    lineHeight: getUnitlessLineHeight(10, 16),
    fontWeight: 400,
  },
};

const textVariants = {
  variants: {
    '5xl': baseVariants['5xl'],
    '5xlMedium': { ...baseVariants['5xl'], fontWeight: 500 },
    '5xlSemiBold': { ...baseVariants['5xl'], fontWeight: 600 },
    '4xl': baseVariants['4xl'],
    '4xlMedium': { ...baseVariants['4xl'], fontWeight: 500 },
    '4xlSemiBold': { ...baseVariants['4xl'], fontWeight: 600 },
    '3xl': baseVariants['3xl'],
    '3xlMedium': { ...baseVariants['3xl'], fontWeight: 500 },
    '3xSemiBold': { ...baseVariants['3xl'], fontWeight: 600 },
    '2xl': baseVariants['2xl'],
    '2xlMedium': { ...baseVariants['2xl'], fontWeight: 500 },
    '2xlSemiBold': { ...baseVariants['2xl'], fontWeight: 600 },
    xl: baseVariants.xl,
    xlMedium: { ...baseVariants.xl, fontWeight: 500 },
    xlSemiBold: { ...baseVariants.xl, fontWeight: 600 },
    lg: baseVariants.lg,
    lgMedium: { ...baseVariants.lg, fontWeight: 500 },
    lgSemiBold: { ...baseVariants.lg, fontWeight: 600 },
    base: baseVariants.base,
    baseMedium: { ...baseVariants.base, fontWeight: 500 },
    baseSemiBold: { ...baseVariants.base, fontWeight: 600 },
    sm: baseVariants.sm,
    smMedium: { ...baseVariants.sm, fontWeight: 500 },
    smSemiBold: { ...baseVariants.sm, fontWeight: 600 },
    xs: baseVariants.xs,
    xsMedium: { ...baseVariants.xs, fontWeight: 500 },
    xsSemiBold: { ...baseVariants.xs, fontWeight: 600 },
    '2xs': baseVariants['2xs'],
    '2xsMedium': { ...baseVariants['2xs'], fontWeight: 500 },
    '2xsSemiBold': { ...baseVariants['2xs'], fontWeight: 600 },
  },
};

export { textVariants };
