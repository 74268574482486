import type { GetResponse } from '@remote-com/data-layer';
import { EMPLOYMENT_TYPE } from '@remote-com/talent/domains/jobs/constants';
import omit from 'lodash/omit';
import type { ValueOf } from 'type-fest';

import type { EmploymentType, ProductEmploymentType } from '@/src/api/config/api.types';
import type { CreditRiskScore } from '@/src/api/config/employ/complianceProfile.types';
import { creditRiskStatuses } from '@/src/domains/compliance/creditRisk/constants';
import { productTypes } from '@/src/domains/payroll/constants';
import { generateSelectOptions, generateSelectOptionsFromValues } from '@/src/helpers/forms';
import type { $TSFixMe } from '@/types';

type EmployeesResponse =
  GetResponse<'/api/v1/employer/dashboard/employees'>['data']['employments'][number];

export const contractType = {
  FULL_TIME: 'full_time',
  CONTRACTOR: 'contractor',
  DIRECT: 'direct',
} as const;

export const contractTypeLabels = {
  [contractType.FULL_TIME]: 'Employee',
  [contractType.CONTRACTOR]: 'Contractor',
  [contractType.DIRECT]: 'Direct',
};

// - - - - - - - - -

export const employmentType: Record<string, EmploymentType> = {
  FULL_TIME: 'full_time',
  CONTRACTOR: 'contractor',
  DIRECT: 'direct',
} as const;

export const employmentTypeForRoute = {
  [employmentType.FULL_TIME]: 'full_time',
  [employmentType.CONTRACTOR]: 'contractor',
  [employmentType.DIRECT]: 'direct',
} as const;

export const employmentTypeKeys = {
  full_time: employmentType.FULL_TIME,
  contractor: employmentType.CONTRACTOR,
} as const;

export const employmentTypeLabels = {
  [employmentType.FULL_TIME]: 'Employee',
  [employmentType.CONTRACTOR]: 'Contractor',
  [employmentType.DIRECT]: 'Direct employee',
};

// used on user profile header for Remote Admins
export const employmentProfileHeaderLabels = {
  [employmentType.FULL_TIME]: 'EOR employee',
  [employmentType.CONTRACTOR]: 'Contractor',
  [employmentType.DIRECT]: 'Direct employee',
  global_payroll: 'Global payroll employee',
};

export const employmentTypeOptions = [
  { value: employmentType.FULL_TIME, label: 'Employee' },
  { value: employmentType.CONTRACTOR, label: 'Contractor' },
  { value: employmentType.DIRECT, label: 'Direct employee' },
];

export const entityType = {
  REMOTE_ENTITY: 'remote_entity',
  COMPANY_ENTITY: 'company_entity',
};

export const payrollType = {
  REMOTE_PAYROLL: 'global_payroll',
  COMPANY_PAYROLL: 'company_payroll',
};

// - - - - - - - - -

export const preHireAssessmentControls = {
  DISCLAIMER: 'disclaimer',
  MANDATED_PRE_HIRE: 'mandated_pre_hire',
};

export const employmentEligibilityStatus = {
  CITIZEN: 'citizen',
  PERMANENT_RESIDENT: 'permanent_resident',
  TEMPORARY_RESIDENT: 'temporary_resident',
  NO: 'no',
  // I added an extra space ' ' at the end since we trim the values when sending to the API.
  // This needs to be "unique" for react-select to understand it is a different option
  // TODO: Remove once https://gitlab.com/groups/remote-com/employ-starbase/-/epics/224 is done
  TEMPORARY_REMOTE_HELPING: 'temporary_resident ',
  DONT_KNOW: 'temporary_resident  ',
  WILL_BECOME_CITIZEN: 'citizen ',
};

export const employmentEligibilityLabels = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They are/will be a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They are/will be a temporary resident',
  [employmentEligibilityStatus.NO]: 'They’re ineligible right now',
  [employmentEligibilityStatus.TEMPORARY_REMOTE_HELPING]:
    'Remote is assisting with obtaining the right to work',
};

export const employeeEligibleLabels = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’re a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’re a temporary resident',
  [employmentEligibilityStatus.DONT_KNOW]: 'I don’t know, I want Remote to help to confirm',
};

export const employeeWantRemoteHelpLabels = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’re a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’re a temporary resident',
  [employmentEligibilityStatus.NO]: 'They don’t have the right to work in this country yet',
  [employmentEligibilityStatus.DONT_KNOW]: 'I don’t know',
};

export const employeeObtainingTheirPermitLabels = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.WILL_BECOME_CITIZEN]: 'They will be a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’re a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’re a temporary resident',
};

export const employeeRemoteHasAdvisedLabels = {
  [employmentEligibilityStatus.CITIZEN]: 'They’ll be a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’ll be a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’ll be a temporary resident',
};

export const employeeCanBeAssessedLater = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’re a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’re a temporary resident',
  [employmentEligibilityStatus.DONT_KNOW]: 'I don’t know',
};

export const employeeWantRemoteToAssess = {
  [employmentEligibilityStatus.CITIZEN]: 'They’re a citizen',
  [employmentEligibilityStatus.PERMANENT_RESIDENT]: 'They’re a permanent resident',
  [employmentEligibilityStatus.TEMPORARY_RESIDENT]: 'They’re a temporary resident',
  [employmentEligibilityStatus.DONT_KNOW]: 'I don’t know',
};

export const employmentEligibilityEmployerLabels = {
  ...employmentEligibilityLabels,
  [employmentEligibilityStatus.NO]: 'Not sure, I’d like a pre-hire assessment',
};

export const employmentEligibilityWantRemoteHelpLabels = {
  [employmentEligibilityStatus.TEMPORARY_REMOTE_HELPING]:
    'Remote is already helping to confirm this hire’s eligibility',
  [employmentEligibilityStatus.DONT_KNOW]: 'I don’t know, I want Remote to help to confirm',
  [employmentEligibilityStatus.NO]: 'They don’t have the right to work in this country yet',
};

const EMPLOYMENT_ELIGIBILITY_EMPLOYER_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
  employmentEligibilityStatus.NO,
  // TODO: Remove once https://gitlab.com/groups/remote-com/employ-starbase/-/epics/224 is done
  employmentEligibilityStatus.TEMPORARY_REMOTE_HELPING,
];

const EMPLOYEE_ELIGIBLE_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
  employmentEligibilityStatus.DONT_KNOW,
];

const EMPLOYEE_WANT_REMOTE_HELP_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
  employmentEligibilityStatus.NO,
  employmentEligibilityStatus.DONT_KNOW,
];

const EMPLOYEE_OBTAINING_PERMIT_INDEPENDENTLY_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.WILL_BECOME_CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
];

const EMPLOYEE_REMOTE_HAS_ADVISED_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
];

const EMPLOYEE_CAN_BE_ASSESSED_LATER_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
  employmentEligibilityStatus.DONT_KNOW,
];

export const employmentEligibilityEmployerOptions = generateSelectOptionsFromValues(
  EMPLOYMENT_ELIGIBILITY_EMPLOYER_STATUSES,
  employmentEligibilityEmployerLabels
);

export const employeeEligibleOptions = generateSelectOptionsFromValues(
  EMPLOYEE_ELIGIBLE_STATUSES,
  employeeEligibleLabels
);

export const employeeWantRemoteHelpOptions = generateSelectOptionsFromValues(
  EMPLOYEE_WANT_REMOTE_HELP_STATUSES,
  employeeWantRemoteHelpLabels
);

export const employeeObtainingTheirPermitOptions = generateSelectOptionsFromValues(
  EMPLOYEE_OBTAINING_PERMIT_INDEPENDENTLY_STATUSES,
  employeeObtainingTheirPermitLabels
);

export const employeeRemoteHasAdvisedOptions = generateSelectOptionsFromValues(
  EMPLOYEE_REMOTE_HAS_ADVISED_STATUSES,
  employeeRemoteHasAdvisedLabels
);

export const employeeCanBeAssessedLaterOptions = generateSelectOptionsFromValues(
  EMPLOYEE_CAN_BE_ASSESSED_LATER_STATUSES,
  employeeCanBeAssessedLater
);

export const EMPLOYMENT_ELIGIBILITY_INTERNAL_STATUSES = [
  employmentEligibilityStatus.CITIZEN,
  employmentEligibilityStatus.PERMANENT_RESIDENT,
  employmentEligibilityStatus.TEMPORARY_RESIDENT,
  employmentEligibilityStatus.NO,
];

export const employmentEligibilityInternalOptions = generateSelectOptionsFromValues(
  EMPLOYMENT_ELIGIBILITY_INTERNAL_STATUSES,
  employmentEligibilityLabels
);

export const employeeEligibilityValues = {
  HIRE_IS_ELIGIBLE: 'hire_is_eligible',
  WANT_REMOTE_HELP: 'want_remote_help',
  HIRE_REQUIRES_HELP: 'hire_requires_help',
  HIRE_OBTAINING_PERMIT_INDEPENDENTLY: 'hire_obtaining_permit_independently',
};

export const employeeEligibilityHelpValues = {
  REMOTE_HAS_ADVISED: 'remote_has_advised',
  CAN_BE_ASSESSED_AFTER_INVITE: 'can_be_assessed_after_invite',
  WANT_REMOTE_TO_ASSESS: 'want_remote_to_assess',
};

const proofOfCitizenshipValues = {
  VALID_PASSPORT: 'valid_passport',
  BIRTH_CERTIFICATE: 'birth_certificate',
  CERTIFICATE_OF_CITIZENSHIP: 'certificate_of_citizenship',
  CITIZENSHIP_CARD: 'citizenship_card',
};

export const proofOfCitizenshipLabels = {
  [proofOfCitizenshipValues.VALID_PASSPORT]: 'Valid passport',
  [proofOfCitizenshipValues.BIRTH_CERTIFICATE]: 'Birth certificate',
  [proofOfCitizenshipValues.CERTIFICATE_OF_CITIZENSHIP]: 'Certificate of citizenship',
  [proofOfCitizenshipValues.CITIZENSHIP_CARD]: 'Citizenship card',
};

export const proofOfCitizenshipOptions = generateSelectOptions(proofOfCitizenshipLabels);

export const employeeViewEmploymentEligibilitySchemaFields = [
  'eligibleToWorkInResidingCountry',
  'proofOfCitizenship',
];

// - - - - - - - - -

export const employmentStatus = /** @type {const} */ {
  PRE_HIRE: 'pre_hire',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  CREATED: 'created',
  CREATED_AWAITING_RESERVE: 'created_awaiting_reserve',
  CREATED_RESERVE_PAID: 'created_reserve_paid',
  INITIATED: 'initiated',
  INVITED: 'invited',
  PENDING: 'pending',
  REVIEW: 'review',
  DELETED: 'deleted',
  // Used to filter employees by status. It returns people that match one of the status:
  // created, initiated, invited, pending, or review.
  INCOMPLETE: 'incomplete',
  // Used to filter employees by status. It returns people that match any status that isn't
  // `archived` or `deleted`
  EMPLOYED: 'employed',
};

export const offboardedEmploymentStatuses = new Set([
  employmentStatus.ARCHIVED,
  employmentStatus.DELETED,
]);

export const onboardingStatuses = [
  employmentStatus.INVITED,
  employmentStatus.INITIATED,
  employmentStatus.REVIEW,
  employmentStatus.PENDING,
  employmentStatus.PRE_HIRE,
  employmentStatus.CREATED,
  employmentStatus.CREATED_AWAITING_RESERVE,
  employmentStatus.CREATED_RESERVE_PAID,
];

export const allowedStatusesForSalaryAndStartDateEdits = [
  employmentStatus.INVITED,
  employmentStatus.INITIATED,
];

export const employmentStatusLabels = {
  [employmentStatus.PRE_HIRE]: 'Pre-hire assessment',
  [employmentStatus.ACTIVE]: 'Active',
  [employmentStatus.ARCHIVED]: 'Archived',
  [employmentStatus.CREATED]: 'Created',
  [employmentStatus.CREATED_AWAITING_RESERVE]: 'Created - awaiting reserve',
  [employmentStatus.CREATED_RESERVE_PAID]: 'Created - reserve paid',
  [employmentStatus.INITIATED]: 'Initiated',
  [employmentStatus.INVITED]: 'Invited',
  [employmentStatus.PENDING]: 'Pending',
  [employmentStatus.REVIEW]: 'Review',
  [employmentStatus.DELETED]: 'Deleted',
  [employmentStatus.INCOMPLETE]: 'Incomplete (in onboarding)',
  [employmentStatus.EMPLOYED]: 'Employed (not offboarded)',
};

export const employmentStatusDetails = {
  [employmentStatus.CREATED]: {
    description: 'You need to complete new hire details and invite them to Remote',
  },
  [employmentStatus.CREATED_AWAITING_RESERVE]: {
    description: 'Remote is awaiting reserve payment',
  },
  [employmentStatus.CREATED_RESERVE_PAID]: {
    description: 'Remote has received your reserve payment',
  },
  [employmentStatus.INVITED]: {
    description: 'New hire has been invited to complete self-enrollment',
  },
  [employmentStatus.INITIATED]: {
    description: 'New hire is completing self-enrollment',
  },
  [employmentStatus.PENDING]: {
    description: 'Information needed. Remote is collecting this from the Hire',
  },
  [employmentStatus.REVIEW]: {
    description: 'Remote is creating and reviewing employment documentation with new hire',
  },
};

export const employmentStatusBadges = {
  [employmentStatus.PRE_HIRE]: 'pending',
  [employmentStatus.ACTIVE]: 'active',
  [employmentStatus.INCOMPLETE]: 'pending',
  [employmentStatus.ARCHIVED]: 'inactive',
  [employmentStatus.CREATED]: 'pending',
  [employmentStatus.CREATED_AWAITING_RESERVE]: 'pending',
  [employmentStatus.CREATED_RESERVE_PAID]: 'pending',
  [employmentStatus.INITIATED]: 'pending',
  [employmentStatus.INVITED]: 'pending',
  [employmentStatus.PENDING]: 'pending',
  [employmentStatus.REVIEW]: 'pending',
  [employmentStatus.DELETED]: 'inactive',
};

export const employmentStatusOptions = [
  {
    value: employmentStatus.ACTIVE,
    label: employmentStatusLabels[employmentStatus.ACTIVE],
  },
  {
    value: employmentStatus.CREATED,
    label: employmentStatusLabels[employmentStatus.CREATED],
  },
  {
    value: employmentStatus.CREATED_AWAITING_RESERVE,
    label: employmentStatusLabels[employmentStatus.CREATED_AWAITING_RESERVE],
  },
  {
    value: employmentStatus.CREATED_RESERVE_PAID,
    label: employmentStatusLabels[employmentStatus.CREATED_RESERVE_PAID],
  },
  {
    value: employmentStatus.INVITED,
    label: employmentStatusLabels[employmentStatus.INVITED],
  },
  {
    value: employmentStatus.INITIATED,
    label: employmentStatusLabels[employmentStatus.INITIATED],
  },
  {
    value: employmentStatus.PENDING,
    label: employmentStatusLabels[employmentStatus.PENDING],
  },
  {
    value: employmentStatus.REVIEW,
    label: employmentStatusLabels[employmentStatus.REVIEW],
  },
  {
    value: employmentStatus.ARCHIVED,
    label: employmentStatusLabels[employmentStatus.ARCHIVED],
  },
  {
    value: employmentStatus.DELETED,
    label: employmentStatusLabels[employmentStatus.DELETED],
  },
  {
    value: employmentStatus.PRE_HIRE,
    label: employmentStatusLabels[employmentStatus.PRE_HIRE],
  },
];

export const employmentActions = /** @type {const} */ {
  VIEW_PROGRESS: 'VIEW_PROGRESS',
  REMOVE_HIRE: 'REMOVE_HIRE',
  SEND_INVITE: 'SEND_INVITE',
  RESEND_INVITE: 'RESEND_INVITE',
  CREATE_GLOBAL_OFFER_LETTER: 'CREATE_GLOBAL_OFFER_LETTER',
  VIEW_PRE_HIRE_PROGRESS: 'VIEW_PRE_HIRE_PROGRESS',
  CREATE_CONTRACTOR_AGREEMENT: 'CREATE_CONTRACTOR_AGREEMENT',
};

// - - - - - - - - -

export const employmentIncompleteStatusSorted = [
  employmentStatus.CREATED,
  employmentStatus.INVITED,
  employmentStatus.INITIATED,
  employmentStatus.PENDING,
  employmentStatus.REVIEW,
];

export const contractStatus = /** @type {const} */ {
  BOTH_PARTIES_SIGNED: 'both_parties_signed',
  REDLINING_EMPLOYEE: 'redlining_employee',
  REDLINING_EMPLOYER: 'redlining_employer',
  DECLINED: 'declined',
  EMPLOYEE_SIGNED: 'employee_signed',
  EMPLOYER_SIGNED: 'employer_signed',
  ISSUED: 'issued',
  PENDING_EMPLOYEE_SIGNATURE: 'pending_employee_signature',
  PENDING_EMPLOYER_SIGNATURE: 'pending_employer_signature',
  TERMINATED: 'terminated',
  DELETED: 'deleted',
  PENDING_ADMIN_APPROVAL: 'pending_admin_approval',
};

export const contractSignatoryStatus = {
  PENDING: 'pending',
  SIGNED: 'signed',
};

export const contractSignatoryType = {
  COMPANY: 'company',
  EMPLOYEE: 'employee',
};

export const contractStatusLabels = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'Fully executed',
  [contractStatus.DECLINED]: 'Declined',
  [contractStatus.EMPLOYEE_SIGNED]: 'Signed by employee',
  [contractStatus.EMPLOYER_SIGNED]: 'Signed by customer',
  [contractStatus.ISSUED]: 'Issued',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'Waiting for employee to sign',
  [contractStatus.PENDING_EMPLOYER_SIGNATURE]: 'Waiting for customer to sign',
  [contractStatus.TERMINATED]: 'Terminated',
  [contractStatus.DELETED]: 'Deleted',
  [contractStatus.PENDING_ADMIN_APPROVAL]: 'Pending admin approval',
  [contractStatus.REDLINING_EMPLOYEE]: 'Redlining by employee',
  [contractStatus.REDLINING_EMPLOYER]: 'Redlining by customer',
};

export const contractStatusBadges = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'active',
  [contractStatus.DECLINED]: 'inactive',
  [contractStatus.EMPLOYEE_SIGNED]: 'pending',
  [contractStatus.EMPLOYER_SIGNED]: 'pending',
  [contractStatus.ISSUED]: 'pending',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'pending',
  [contractStatus.PENDING_EMPLOYER_SIGNATURE]: 'pending',
  [contractStatus.TERMINATED]: 'inactive',
  [contractStatus.DELETED]: 'inactive',
  [contractStatus.PENDING_ADMIN_APPROVAL]: 'pending',
  [contractStatus.REDLINING_EMPLOYEE]: 'pending',
  [contractStatus.REDLINING_EMPLOYER]: 'pending',
};

export const contractStatusOptions = Object.values(contractStatus)
  .map((status) => ({
    label: contractStatusLabels[status],
    value: status,
  }))
  .filter((s) => s.value !== contractStatus.DELETED);

export const initialContractStatusOptions = contractStatusOptions.filter(
  (s) => s.value !== contractStatus.PENDING_ADMIN_APPROVAL
);

const customerContractStatusLabels = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'Fully executed',
  [contractStatus.DECLINED]: 'Declined',
  [contractStatus.EMPLOYEE_SIGNED]: 'Processing',
  [contractStatus.EMPLOYER_SIGNED]: 'Processing',
  [contractStatus.ISSUED]: 'Processing',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'Waiting for employee to sign',
  [contractStatus.PENDING_EMPLOYER_SIGNATURE]: 'Processing',
  [contractStatus.TERMINATED]: 'Terminated',
  [contractStatus.PENDING_ADMIN_APPROVAL]: 'Submitted',
  [contractStatus.REDLINING_EMPLOYEE]: 'Processing',
  [contractStatus.REDLINING_EMPLOYER]: 'Processing',
};

export const getCustomerContractStatusLabel = (
  status: ValueOf<typeof contractStatus>,
  type?: EmploymentType
) => {
  if (status === contractStatus.PENDING_EMPLOYEE_SIGNATURE && type === EMPLOYMENT_TYPE.CONTRACTOR) {
    return 'Waiting for contractor to sign';
  }

  return customerContractStatusLabels[status];
};

export const customerContractStatusBadges = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'active',
  [contractStatus.DECLINED]: 'inactive',
  [contractStatus.EMPLOYEE_SIGNED]: 'info',
  [contractStatus.EMPLOYER_SIGNED]: 'info',
  [contractStatus.ISSUED]: 'info',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'pending',
  [contractStatus.PENDING_EMPLOYER_SIGNATURE]: 'info',
  [contractStatus.TERMINATED]: 'inactive',
  [contractStatus.PENDING_ADMIN_APPROVAL]: 'pending',
  [contractStatus.REDLINING_EMPLOYEE]: 'info',
  [contractStatus.REDLINING_EMPLOYER]: 'info',
};

export const amendmentStatusDisplay = {
  PENDING: 'pending',
  ACTIVE: 'active',
  IN_PROGRESS: 'in_progress',
};

export const pendingRequestStatus = [
  contractStatus.EMPLOYEE_SIGNED,
  contractStatus.EMPLOYER_SIGNED,
  contractStatus.ISSUED,
  contractStatus.PENDING_EMPLOYEE_SIGNATURE,
  contractStatus.PENDING_EMPLOYER_SIGNATURE,
  contractStatus.PENDING_ADMIN_APPROVAL,
  contractStatus.REDLINING_EMPLOYEE,
  contractStatus.REDLINING_EMPLOYER,
];

// Employees wont see declined, issued, deleted, pending_employer_signature or pending_admin_approval contracts
export const employeeContractStatusLabels = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'Fully executed',
  [contractStatus.EMPLOYEE_SIGNED]: 'Processing',
  [contractStatus.EMPLOYER_SIGNED]: 'Processing',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'Awaiting your signature',
  [contractStatus.REDLINING_EMPLOYEE]: 'Processing',
  [contractStatus.REDLINING_EMPLOYER]: 'Processing',
  [contractStatus.TERMINATED]: 'Terminated',
};

export const employeeContractStatusBadges = {
  [contractStatus.BOTH_PARTIES_SIGNED]: 'active',
  [contractStatus.EMPLOYEE_SIGNED]: 'info',
  [contractStatus.EMPLOYER_SIGNED]: 'info',
  [contractStatus.PENDING_EMPLOYEE_SIGNATURE]: 'pending',
  [contractStatus.PENDING_EMPLOYER_SIGNATURE]: 'info',
  [contractStatus.REDLINING_EMPLOYEE]: 'info',
  [contractStatus.REDLINING_EMPLOYER]: 'info',
};

export const contractReasonsForChange = {
  annualPayAdjustment: 'annual_pay_adjustment',
  countryRuleChange: 'country_rule_change',
  errorCorrection: 'error_correction',
  jobChangeReevaluation: 'job_change_reevaluation',
  promotion: 'promotion',
  other: 'other',
};

export const amendmentRequestErrorTypes = {
  AMENDED_CONTRACT_NOT_ACTIVE: 'amended_contract_not_active',
  AMENDED_CONTRACT_NOT_FOUND: 'amended_contract_not_found',
} as const;

export const amendmentRequestErrorTypesDescription = {
  [amendmentRequestErrorTypes.AMENDED_CONTRACT_NOT_ACTIVE]:
    'The contract you were amending is no longer the current contract.',
  [amendmentRequestErrorTypes.AMENDED_CONTRACT_NOT_FOUND]:
    'The contract you were amending could not be found.',
};

export const editContractDetailsErrorTypesDescription = {
  [amendmentRequestErrorTypes.AMENDED_CONTRACT_NOT_ACTIVE]:
    'The contract details you were editing are no longer the current details.',
  [amendmentRequestErrorTypes.AMENDED_CONTRACT_NOT_FOUND]:
    'The contract details you were editing could not be found.',
};

export const contractReasonForChangeLabels = {
  [contractReasonsForChange.annualPayAdjustment]: 'Annual pay adjustment',
  [contractReasonsForChange.countryRuleChange]: 'Country rule change',
  [contractReasonsForChange.errorCorrection]: 'Error correction',
  [contractReasonsForChange.jobChangeReevaluation]: 'Job change/reevaluation',
  [contractReasonsForChange.promotion]: 'Promotion',
  [contractReasonsForChange.other]: 'Other',
};

export const adminContractChangeLabels = generateSelectOptions(contractReasonForChangeLabels);
export const customerContractReasonsForChangeOptions = generateSelectOptions(
  omit(contractReasonForChangeLabels, contractReasonsForChange.countryRuleChange)
);

export const metadataContractFieldNames = {
  effectiveDate: 'contract.effectiveDate',
  status: 'contract.status',
  activatedAt: 'contract.activatedAt',
  juroContractId: 'contract.juroContractId',
  juroSlaOrSoId: 'contract.juroSlaOrSoId',
  country: 'contract.country',
  legalEntitySlug: 'contract.legalEntitySlug',
  jobTitle: 'contract.jobTitle',
  compensation: 'contract.compensation',
  grossRecurringSalary: 'contract.grossRecurringSalary',
  provisionalStartDate: 'contract.provisionalStartDate',
  manualProbationPeriodEndDate: 'contract.manualProbationPeriodEndDate',
  reasonForChange: 'contract.reasonForChange',
  reasonForChangeDescription: 'contract.reasonForChangeDescription',
  overtimeEligible: 'contract.overtimeEligible',
  wageType: 'contract.wageType',
  grossRegularHourlyRate: 'contract.grossRegularHourlyRate',
  payrollCycle: 'contract.payrollCycle',
} as const;

export const metadataContractFieldLabels = {
  [metadataContractFieldNames.effectiveDate]: 'Effective date',
  [metadataContractFieldNames.status]: 'Status',
  [metadataContractFieldNames.activatedAt]: 'Execution date',
  [metadataContractFieldNames.juroContractId]: 'Juro EA ID',
  [metadataContractFieldNames.juroSlaOrSoId]: 'Juro SLA/SO ID',
  [metadataContractFieldNames.country]: 'Country',
  [metadataContractFieldNames.legalEntitySlug]: 'Our entity (optional)',
  [metadataContractFieldNames.jobTitle]: 'Job title',
  [metadataContractFieldNames.compensation]: 'Gross annual compensation',
  [metadataContractFieldNames.grossRecurringSalary]: 'Gross recurring compensation',
  [metadataContractFieldNames.provisionalStartDate]: 'Employee provisional starting date',
  [metadataContractFieldNames.manualProbationPeriodEndDate]: 'Probation period end date override',
  [metadataContractFieldNames.reasonForChange]: 'Reason for change',
  [metadataContractFieldNames.reasonForChangeDescription]:
    'Please specify the reasons for this change',
  [metadataContractFieldNames.overtimeEligible]: 'Overtime eligible',
  [metadataContractFieldNames.wageType]: 'Wage type',
  [metadataContractFieldNames.grossRegularHourlyRate]: 'Gross regular hourly rate',
  [metadataContractFieldNames.payrollCycle]: 'Payroll cycle',
} as const satisfies Record<ValueOf<typeof metadataContractFieldNames>, string>;

// By default only user statuses !== deleted are shown, this gives
// the option to show offboarded employees
// a false value for this is the same as not including at all
export const offboardedOptions = [{ value: true, label: 'Offboarded' }];

export const compensationStatus = {
  ACTIVE: 'active',
};

export const compensationPeriodicity = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
};

export const noSalarySetLabel = 'Salary not set';

export const IR35_STATUSES = {
  INSIDE: { label: 'Inside IR35 (deemed employee)', value: 'inside' },
  OUTSIDE: { label: 'Outside IR35', value: 'outside' },
  EXEMPT: { label: 'Exempt from IR35', value: 'exempt' },
};

export const IR35_FILE_SUBTYPE = 'ir_35';

export const CONTRACTOR_NATIONALITY_STATUSES = {
  NATIONAL: 'national',
  NON_NATIONAL: 'non_national',
};

export const EMPLOYMENT_INVITATION_ERRORS = {
  LIMIT_REACHED: 'invited_employments_limit_reached',
  VERIFICATION_REQUIRED: [
    // @TODO: Legacy error message. This is not stable and will be removed.
    'Forbidden inviting employee since additional verifications are required',
    // New stable error code
    'forbidden_verification_required',
  ],
  GLOBAL_PAYROLL_EMPLOYEE_NOT_READY_FOR_INVITE: 'The employee is not ready for the invite.',
};

export const newOnboardingStatuses = /** @type {const} */ {
  PRE_HIRE_ASSESSMENT: 'preHireAssessment',
  GETTING_STARTED: 'gettingStarted',
  SLA_OR_SO: 'slaOrSo',
  SELF_ONBOARDING: 'selfOnboarding',
  EA: 'ea',
  CONFIRMING_NEW_HIRE: 'confirmingNewHire',
  RIGHT_TO_WORK: 'rightToWork',
  POST_ENROLLMENT: 'postEnrollment',
};

export const newOnboardingStatusesLabels = ({
  employmentType: currentEmploymentType = employmentType.FULL_TIME,
  productType = null,
}: {
  employmentType?: ValueOf<typeof employmentType>;
  productType?: ProductEmploymentType | null;
}) => ({
  [newOnboardingStatuses.PRE_HIRE_ASSESSMENT]: 'Pre-hire eligibility check',
  [newOnboardingStatuses.GETTING_STARTED]: 'Adding and inviting new hire',
  [newOnboardingStatuses.SELF_ONBOARDING]:
    currentEmploymentType === employmentType.FULL_TIME &&
    productType === productTypes.GLOBAL_PAYROLL
      ? 'Employee enrollment'
      : 'Self-enrollment',
  [newOnboardingStatuses.SLA_OR_SO]: 'Service Level Agreement / Service Order',
  [newOnboardingStatuses.EA]: 'Employment Agreement',
  [newOnboardingStatuses.CONFIRMING_NEW_HIRE]: 'Activating new hire',
  [newOnboardingStatuses.RIGHT_TO_WORK]: 'Right to work check',
  [newOnboardingStatuses.POST_ENROLLMENT]: 'Post-enrollment',
});

export const newOnboardingStatusesDescriptions = {
  [newOnboardingStatuses.SELF_ONBOARDING]: 'Self-enrollment in progress',
  [newOnboardingStatuses.RIGHT_TO_WORK]: 'Right to work check in progress',
};

export const onBoardingTasksStatuses = {
  NOT_STARTED: 'not-started',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  UN_CLEAR: 'unclear',
  REJECTED: 'rejected',
};

// can't create a reserve invoice without a salary
// these employment statuses all have a salary for the employment
export const employmentStatusesEligibleForReserveInvoice = [
  employmentStatus.ACTIVE,
  employmentStatus.CREATED_AWAITING_RESERVE,
  employmentStatus.CREATED_RESERVE_PAID,
  employmentStatus.INITIATED,
  employmentStatus.INVITED,
  employmentStatus.PENDING,
  employmentStatus.REVIEW,
];

export const PILL_TAGS = {
  ACTION_NEEDED: 'Action needed',
  APPROVED: 'Approved',
  AWAITING_APPROVAL: 'Awaiting approval',
  AWAITING_COMPLETION: 'Awaiting completion',
  AWAITING_DECISION: 'Awaiting decision',
  AWAITING_DETAILS: 'Awaiting details',
  AWAITING_MISSING_DETAILS: 'Awaiting missing details',
  AWAITING_RIGHT_TO_WORK: 'Awaiting right to work',
  AWAITING_SIGNING: 'Awaiting signing',
  AWAITING_SIGNUP: 'Awaiting sign-up',
  CHANGES_REQUESTED: 'Changes requested',
  COMPLIANCE_REVIEW: 'Compliance review',
  DECLINED: 'Declined',
  DRAFT: 'Draft',
  NOT_APPROVED: 'Not approved',
  NOT_SIGNED_YET: 'Not signed yet',
  IN_REVIEW: 'In review',
  IN_PROGRESS: 'In-progress',
  REMINDER_SENT: 'Reminder sent',
  REVIEW_REQUESTED: 'Review requested',
};

export const PILL_TAGS_TO_TONES = {
  [PILL_TAGS.NOT_APPROVED]: 'error',
  [PILL_TAGS.NOT_SIGNED_YET]: 'error',
  [PILL_TAGS.DECLINED]: 'error',
  [PILL_TAGS.APPROVED]: 'success',
  [PILL_TAGS.ACTION_NEEDED]: 'error',
  [PILL_TAGS.AWAITING_APPROVAL]: 'error',
  [PILL_TAGS.DRAFT]: 'neutralLight',
  [PILL_TAGS.AWAITING_SIGNING]: 'error',
  [PILL_TAGS.AWAITING_DECISION]: 'error',
  [PILL_TAGS.COMPLIANCE_REVIEW]: 'error',
  [PILL_TAGS.AWAITING_SIGNUP]: 'error',
  [PILL_TAGS.REMINDER_SENT]: 'error',
  [PILL_TAGS.AWAITING_COMPLETION]: 'error',
  [PILL_TAGS.AWAITING_DETAILS]: 'error',
  [PILL_TAGS.CHANGES_REQUESTED]: 'error',
  [PILL_TAGS.AWAITING_RIGHT_TO_WORK]: 'warning',
};

export const HIRE_CREATES_AN_ACCOUNT_SUBTASK = 'New hire creates a Remote account';
export const REMOTE_PROVIDES_STATE_TAXES_DETAILS = 'Remote provides state taxes details';

const COMPANY_NAME_LABEL_DEFAULT = 'Customer';

export function getCompanyNameLabel(companyName?: string, isLowerCase?: boolean) {
  const defaultLabel = isLowerCase
    ? COMPANY_NAME_LABEL_DEFAULT.toLowerCase()
    : COMPANY_NAME_LABEL_DEFAULT;

  return companyName || defaultLabel;
}

const onBoardingSubTasks = ({
  companyName,
  riskScore,
  employmentType: currentEmploymentType,
  productType,
}: {
  companyName?: string;
  riskScore?: CreditRiskScore;
  employmentType: ValueOf<typeof employmentType>;
  productType: ProductEmploymentType | null;
}) => {
  return {
    [newOnboardingStatuses.GETTING_STARTED]: [
      {
        name: `${getCompanyNameLabel(companyName)} adds employment details for new hire`,
        completedKey: 'created',
      },
      ...(riskScore === creditRiskStatuses.DEPOSIT_REQUIRED
        ? [
            {
              name: 'Remote prepares reserve invoice',
              completedKey: 'createdAwaitingReserve',
              tag: PILL_TAGS.DRAFT,
            },
            {
              name: `${getCompanyNameLabel(companyName)} pays reserve invoice`,
              completedKey: 'createdReservePaid',
              tag: PILL_TAGS.DRAFT,
            },
          ]
        : []),
      {
        name: `${getCompanyNameLabel(companyName)} invites new hire to Remote`,
        description: 'Not invited yet',
        tag: PILL_TAGS.DRAFT,
        completedKey: 'invited',
      },
    ],
    [newOnboardingStatuses.SLA_OR_SO]: [
      {
        name: `Remote sends SLA / SO to ${getCompanyNameLabel(companyName, true)}`,
        description: 'Remote to send SLA/SO',
        completedKey: 'pendingEmployerSignature',
      },
      {
        name: `${getCompanyNameLabel(companyName)} signs SLA / SO`,
        description: 'SLA/SO not signed yet',
        tag: PILL_TAGS.AWAITING_SIGNING,
        completedKey: 'employerSigned',
      },
    ],
    [newOnboardingStatuses.SELF_ONBOARDING]:
      currentEmploymentType === employmentType.FULL_TIME &&
      productType === productTypes.GLOBAL_PAYROLL
        ? [
            {
              name: 'New hire provides personal details',
              completedKey: 'userDetails',
            },
            { name: 'New hire provides home address', completedKey: 'homeAddress' },
            {
              name: 'New hire provides bank details',
              completedKey: 'paymentDetails',
            },
            {
              name: 'New hire provides administrative details',
              completedKey: 'administrativeDetails',
            },
          ]
        : [
            {
              name: HIRE_CREATES_AN_ACCOUNT_SUBTASK,
              description: 'New hire to start self-enrollment',
              completedKey: 'initiated',
            },
            {
              name: 'New hire provides personal details',
              completedKey: 'userDetails',
            },
            {
              name: 'New hire provides employment eligibility',
              completedKey: 'employmentEligibility',
            },
            { name: 'New hire provides home address', completedKey: 'homeAddress' },
            {
              name: 'New hire provides emergency contact',
              completedKey: 'emergencyContact',
            },
            {
              name: 'New hire provides bank details',
              completedKey: 'paymentDetails',
            },
            {
              name: 'New hire provides administrative details',
              completedKey: 'administrativeDetails',
            },
            {
              name: 'New hire verifies identity',
              completedKey: 'identityVerification',
            },
            {
              name: 'New hire provides additional documentation',
              completedKey: 'additionalDocuments',
            },
          ],
    [newOnboardingStatuses.POST_ENROLLMENT]: [
      {
        name: 'New hire provides federal taxes details',
        completedKey: 'federalTaxes',
      },
      {
        name: 'New hire provides state taxes details',
        completedKey: 'employeeStateTaxes',
      },
      {
        name: REMOTE_PROVIDES_STATE_TAXES_DETAILS,
        completedKey: 'remoteStateTaxes',
      },
      {
        name: 'New hire signs W-4 form',
        completedKey: 'w4Form',
      },
      {
        name: 'New hire sets W-2 preference',
        completedKey: 'w2Form',
      },
      {
        name: 'New hire completes I-9 form',
        completedKey: 'i9Form',
      },
    ],
    [newOnboardingStatuses.RIGHT_TO_WORK]:
      currentEmploymentType === employmentType.DIRECT
        ? [
            {
              name: 'Company performs right to work check',
              completedKey: 'approved',
            },
          ]
        : [
            {
              name: 'Remote begins the right to work check',
              completedKey: 'started',
            },
            {
              name: 'Remote begins mobility assistance',
              completedKey: 'assistanceStarted',
              optional: true,
              dependentSteps: [
                {
                  name: 'Remote completes mobility assistance',
                  completedKey: 'assistanceCompleted',
                  optional: true,
                },
              ],
            },
            {
              name: 'Remote completes the right to work check',
              completedKey: 'approved',
            },
          ],
    [newOnboardingStatuses.EA]: [
      {
        name: 'Remote sends Employment Agreement to new hire',
        completedKey: 'pendingEmployeeSignature',
      },
      {
        name: 'New hire signs Employment Agreement documentation',
        completedKey: 'bothPartiesSigned',
      },
    ],
    [newOnboardingStatuses.CONFIRMING_NEW_HIRE]: [
      {
        name: 'Remote registers and activates new hire 🎉',
        completedKey: 'active',
      },
    ],
  };
};

export const sortedOnboardingStatuses = ({
  companyName,
  riskScore,
  productType = null,
}: {
  companyName?: string;
  riskScore: CreditRiskScore;
  productType: ProductEmploymentType | null;
}) => ({
  [employmentType.DIRECT]: [
    {
      name: newOnboardingStatuses.GETTING_STARTED,
      label: newOnboardingStatusesLabels({ employmentType: employmentType.DIRECT, productType })[
        newOnboardingStatuses.GETTING_STARTED
      ],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.DIRECT,
        productType,
      })[newOnboardingStatuses.GETTING_STARTED],
    },
    {
      name: newOnboardingStatuses.SELF_ONBOARDING,
      label: newOnboardingStatusesLabels({ employmentType: employmentType.DIRECT, productType })[
        newOnboardingStatuses.SELF_ONBOARDING
      ],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.DIRECT,
        productType,
      })[newOnboardingStatuses.SELF_ONBOARDING],
    },
    {
      name: newOnboardingStatuses.RIGHT_TO_WORK,
      label: newOnboardingStatusesLabels({
        employmentType: employmentType.FULL_TIME,
        productType,
      })[newOnboardingStatuses.RIGHT_TO_WORK],
      description: newOnboardingStatusesDescriptions[newOnboardingStatuses.RIGHT_TO_WORK],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.DIRECT,
        productType,
      })[newOnboardingStatuses.RIGHT_TO_WORK],
    },
    {
      name: newOnboardingStatuses.CONFIRMING_NEW_HIRE,
      label: newOnboardingStatusesLabels({ employmentType: employmentType.DIRECT, productType })[
        newOnboardingStatuses.CONFIRMING_NEW_HIRE
      ],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.DIRECT,
        productType,
      })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
    },
  ],
  [employmentType.FULL_TIME]:
    productType === productTypes.GLOBAL_PAYROLL
      ? [
          {
            name: newOnboardingStatuses.GETTING_STARTED,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.GETTING_STARTED],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.GETTING_STARTED],
          },
          {
            name: newOnboardingStatuses.SELF_ONBOARDING,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SELF_ONBOARDING],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SELF_ONBOARDING],
          },
          {
            name: newOnboardingStatuses.POST_ENROLLMENT,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.POST_ENROLLMENT],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.POST_ENROLLMENT],
          },
          {
            name: newOnboardingStatuses.CONFIRMING_NEW_HIRE,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
          },
        ]
      : [
          {
            name: newOnboardingStatuses.GETTING_STARTED,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.GETTING_STARTED],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
              riskScore,
            })[newOnboardingStatuses.GETTING_STARTED],
          },
          {
            name: newOnboardingStatuses.SLA_OR_SO,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SLA_OR_SO],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SLA_OR_SO],
          },
          {
            name: newOnboardingStatuses.SELF_ONBOARDING,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SELF_ONBOARDING],
            description: newOnboardingStatusesDescriptions[newOnboardingStatuses.SELF_ONBOARDING],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.SELF_ONBOARDING],
          },
          {
            name: newOnboardingStatuses.POST_ENROLLMENT,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.POST_ENROLLMENT],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.POST_ENROLLMENT],
          },
          {
            name: newOnboardingStatuses.RIGHT_TO_WORK,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.RIGHT_TO_WORK],
            description: newOnboardingStatusesDescriptions[newOnboardingStatuses.RIGHT_TO_WORK],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.RIGHT_TO_WORK],
          },
          {
            name: newOnboardingStatuses.EA,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.EA],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.EA],
          },
          {
            name: newOnboardingStatuses.CONFIRMING_NEW_HIRE,
            label: newOnboardingStatusesLabels({
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
            subTasks: onBoardingSubTasks({
              companyName,
              employmentType: employmentType.FULL_TIME,
              productType,
            })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
          },
        ],
  [employmentType.CONTRACTOR]: [
    {
      name: newOnboardingStatuses.GETTING_STARTED,
      label: newOnboardingStatusesLabels({
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.GETTING_STARTED],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.GETTING_STARTED],
    },
    {
      name: newOnboardingStatuses.SELF_ONBOARDING,
      label: newOnboardingStatusesLabels({
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.SELF_ONBOARDING],
      description: newOnboardingStatusesDescriptions[newOnboardingStatuses.SELF_ONBOARDING],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.SELF_ONBOARDING],
    },
    {
      name: newOnboardingStatuses.CONFIRMING_NEW_HIRE,
      label: newOnboardingStatusesLabels({
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
      subTasks: onBoardingSubTasks({
        companyName,
        employmentType: employmentType.CONTRACTOR,
        productType,
      })[newOnboardingStatuses.CONFIRMING_NEW_HIRE],
    },
  ],
});

/**
 * used to override SLA/SO tasks that have missing Juro Id
 * see (https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/7666) for more info and context
 */
export const unclearSlaOrSoTasks = [
  {
    name: "Remote's Sales team reaches out to take care of the  SLA/SO for this hire. If you haven't received an email yet, you'll get one soon.",
  },
];

/**
 * this date is used to patch the UI for Juro Id's
 * see https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/7666
 */
export const dateToCheckForMissingJuroIds = new Date('2022-02-23');

export const ACTION_NEEDED = 'action_needed';

export const RIGHT_TO_WORK_REJECTED_COMPLETION_KEY = 'notApproved';

export const isSLALabel = (currentTaskLabel: ValueOf<typeof newOnboardingStatuses>) =>
  currentTaskLabel ===
  newOnboardingStatusesLabels({ productType: null })[newOnboardingStatuses.SLA_OR_SO];

export const isRightToWorkRejected = (
  statuses: Record<ValueOf<typeof newOnboardingStatuses>, $TSFixMe>
) =>
  statuses[newOnboardingStatuses.RIGHT_TO_WORK]?.[RIGHT_TO_WORK_REJECTED_COMPLETION_KEY]?.completed;

export const disabledStatuses = [429, 403, 500];

export const isEmploymentInPreHireAssessment = (employment: EmployeesResponse) =>
  employment?.status === employmentStatus.PRE_HIRE;

// at risk reasons used for redlining employment onboardings
const EA_NOT_SENT = 'ea_not_sent';
const HOLD_REQUESTED = 'hold_requested';
const RTW_HOLD = 'rtw_hold';
const EMPLOYMENT_NOT_ACTIVE = 'employment_not_active';

export const atRiskReasonsLabels = {
  [EA_NOT_SENT]: 'EA not sent',
  [HOLD_REQUESTED]: 'Hold requested',
  [RTW_HOLD]: 'Right to work hold',
  [EMPLOYMENT_NOT_ACTIVE]: 'EE not marked as Active',
};

export const AT_RISK_FILTER = { id: 'at_risk', value: true };

export const UNASSIGNED_FILTER = { id: 'missing_onboarding_assignment', value: true };

export const HIRE_TYPE_LABELS = {
  EMPLOYEE: 'Employee',
  CONTRACTOR: 'Contractor',
};

export const roleType = {
  ADMIN: 'admin',
  HIRING_MANAGER: 'hiring_manager',
  ONBOARDING_MANAGER: 'onboarding_manager',
  OWNER: 'owner',
  PEOPLE_MANAGER: 'people_manager',
  UNASSIGNED: 'unassigned',
  VIEWER: 'viewer',
};

export const roleTypeLabels = {
  [roleType.ADMIN]: 'Admin',
  [roleType.HIRING_MANAGER]: 'Hiring Manager',
  [roleType.ONBOARDING_MANAGER]: 'Onboarding Manager',
  [roleType.OWNER]: 'Owner',
  [roleType.PEOPLE_MANAGER]: 'People Manager',
  [roleType.UNASSIGNED]: 'Unassigned',
  [roleType.VIEWER]: 'Viewer',
};

export const customerDataScopeTypes = {
  DIRECT_REPORTS: 'direct_reports',
  DIRECT_AND_INDIRECT_REPORTS: 'direct_and_indirect_reports',
  ENTIRE_COMPANY: 'all',
  EMPLOYMENT_COUNTRY: 'employment_countries',
} as const;

export const customerDataScopeLabels = {
  [customerDataScopeTypes.DIRECT_REPORTS]: 'Direct reports only',
  [customerDataScopeTypes.DIRECT_AND_INDIRECT_REPORTS]: 'Direct and indirect reports',
  [customerDataScopeTypes.ENTIRE_COMPANY]: 'Entire company',
  [customerDataScopeTypes.EMPLOYMENT_COUNTRY]: 'Employment country level',
};

export const jobCategory = {
  FINANCE: 'finance',
  ENGINEERING_IT: 'engineering_it',
  LEGAL: 'legal',
  GROWTH_MARKETING: 'growth_marketing',
  SALES: 'sales',
  CUSTOMER_EXPERIENCE_SUPPORT: 'customer_experience_support',
  PEOPLE_MOBILITY: 'people_mobility',
  TECHOPS_SUPPLY_CHAIN: 'techops_supply_chain',
  BUSINESS_PROCESS_IMPROVEMENT_PRODUCT_MANAGEMENT:
    'business_process_improvement_product_management',
  OPERATIONS: 'operations',
  RESEARCH_DEVELOPMENT: 'research_development',
} as const;

type JobCategory = ValueOf<typeof jobCategory>;

export const jobCategoryLabels: Record<JobCategory, string> = {
  [jobCategory.FINANCE]: 'Finance',
  [jobCategory.ENGINEERING_IT]: 'Engineering/IT',
  [jobCategory.LEGAL]: 'Legal/Paralegal',
  [jobCategory.GROWTH_MARKETING]: 'Growth & Marketing',
  [jobCategory.SALES]: 'Sales',
  [jobCategory.CUSTOMER_EXPERIENCE_SUPPORT]: 'Customer Experience/Support',
  [jobCategory.PEOPLE_MOBILITY]: 'People and mobility',
  [jobCategory.TECHOPS_SUPPLY_CHAIN]: 'Techops/Supply Chain',
  [jobCategory.BUSINESS_PROCESS_IMPROVEMENT_PRODUCT_MANAGEMENT]:
    'Business Process Improvement/Product Management',
  [jobCategory.OPERATIONS]: 'Operations',
  [jobCategory.RESEARCH_DEVELOPMENT]: 'Research & Development',
};

export const jobCategoryOptions = generateSelectOptions(jobCategoryLabels);
