import type { UserAccountResponse } from '@/src/components/UserProvider';
import { useUser } from '@/src/components/UserProvider/context';
import type { PermissionName, PermissionOperation } from '@/src/domains/registration/auth/helpers';
import { userCan } from '@/src/domains/registration/auth/helpers';

const useUserCan = () => {
  const { user: contextUser } = useUser();

  return {
    userCan: <Resource extends PermissionName>(
      operation: PermissionOperation<Resource>,
      resource: Resource,
      user?: UserAccountResponse
    ) => userCan(operation, resource, user || contextUser),
  };
};

export default useUserCan;
