// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneUsers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M3.25 6a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M15 1.25a.75.75 0 0 0 0 1.5 3.25 3.25 0 0 1 0 6.5.75.75 0 0 0 0 1.5 4.75 4.75 0 1 0 0-9.5Zm2 12a.75.75 0 0 0 0 1.5h1.2a4.05 4.05 0 0 1 4.05 4.05c0 1.35-1.1 2.45-2.45 2.45H17a.75.75 0 0 0 0 1.5h2.8a3.95 3.95 0 0 0 3.95-3.95 5.55 5.55 0 0 0-5.55-5.55H17ZM.25 18.95a5.7 5.7 0 0 1 5.7-5.7h4.1a5.7 5.7 0 0 1 5.7 5.7c0 2.1-1.7 3.8-3.8 3.8h-7.9a3.8 3.8 0 0 1-3.8-3.8Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneUsers);
export { ForwardRef as IconV2DuotoneUsers };