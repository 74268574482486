import { QueryClient, MutationCache } from 'react-query';

import { createQueryRetryCondition } from '@/src/helpers/api';
import { captureHTTPException } from '@/src/helpers/captureException';

export const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: createQueryRetryCondition(),
  },
};

export const mutationCache = new MutationCache({
  onError: (err) => {
    captureHTTPException(err, {
      extra: { ...err?.response?.data },
    });
  },
});

export const queryClient = new QueryClient({
  defaultOptions,
  mutationCache,
});
