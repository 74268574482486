import type { ElementType, FC } from 'react';
import { forwardRef } from 'react';

import { SROnly } from '../text';

import { ButtonInlineExternalIcon, ButtonInline as ButtonInlineStyled } from './Button.styled';
import type { PolymorphicComponent, PolymorphicComponentProps } from './types';

export interface ButtonInlineProps {
  /** whether the button is disabled */
  disabled?: boolean;
  /** whether to hide the external icon */
  hideExternalIcon?: boolean;
  /** Defines the button color scheme */
  tone?: 'primary' | 'secondary' | 'destructive';
  /**
   * Defines the button overall layout
   * - default - Highlighted color and underline on hover
   * - underline - shows underline by default
   */
  variant?: 'default' | 'underline';
}

const ButtonInline: PolymorphicComponent<'button', ButtonInlineProps> = forwardRef(
  <C extends ElementType = 'button'>(
    {
      children,
      variant = 'default',
      tone = 'primary',
      as,
      disabled,
      ...props
    }: PolymorphicComponentProps<C, ButtonInlineProps>,
    ref?: PolymorphicComponentProps<C, ButtonInlineProps>['ref']
  ) => {
    const elAttrs = props.href
      ? // We force cast `as` to any to avoid Typescript errors due to `styled-components` trying to infer the rest of the HTML attributes.
        { href: disabled ? undefined : props.href, as: (as || 'a') as any }
      : { type: 'button', as, disabled };
    // Note: `disabled` is not allowed for links (i.e., when `href` is provided).
    // If `disabled` is true, the `href` will be set to `undefined` to prevent navigation,
    // but consider not using `disabled` with links to avoid confusion.

    const isExternal = props.href && !props.hideExternalIcon ? props.target === '_blank' : false;

    return (
      <ButtonInlineStyled ref={ref} $variant={variant} $tone={tone} {...elAttrs} {...props}>
        {children}
        {isExternal && (
          <>
            {' '}
            <SROnly>(opens in a new tab)</SROnly>
            <ButtonInlineExternalIcon aria-hidden />
          </>
        )}
      </ButtonInlineStyled>
    );
  }
);

export const ButtonInlineForStory = ButtonInline as FC<ButtonInlineProps>;

export default ButtonInline;
